/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import "react-svg-radar-chart/build/css/index.css";

const Container = styled.div`
  display: inline-block;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
`;

function Unauthorized() {
  return (
    <>
      <Container>
        <h2>User has insufficient permissions to view this page.</h2>
      </Container>
    </>
  );
}

export default Unauthorized;
