/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import { useTheme } from "@emotion/react";
import { theme } from "Theme";
import { PrimaryButton } from "Theme";
import PercentageTitle from "components/dashboard/PercentageTitle";
import { Link } from "react-router-dom";
import { routes } from "../../services/BenchmarkService";

SwiperCore.use([Pagination]);

const Content = styled.div`
  max-width: ${theme.containerSmallWidth};
  background-color: ${theme.colors.benchBaseBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 20px 15px 30px 15px;

  width: 100%;
  margin: 20px auto;
  height: 560px;
  h1 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.white};
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.colors.yellow};
    margin-top: 45px;
  }
  button {
    width: 75%;
    max-width: 420px;
  }

  .slider-container {
    width: 100%;
    margin: 0 auto 20px;
  }

  .swiper-slide {
    text-align: center;
  }

  .slide-image {
    width: 100px;
    height: 100px;
    margin: 0 auto 26px;
    position: relative;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.darkestblue};
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      object-position: center center;
      opacity: 1;
    }
  }

  .slide-title {
    font-weight: 400;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.yellow};
  }
  .slide-text-wrapper {
    padding: 0 10px;
  }
  .slide-text {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    width: 70%;
    margin: auto;
  }

  a {
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
    text-align: center;
    &hover {
      color: ${({ theme }) => theme.colors.white};
      text-decoration: none;
      display: block;
    }
  }

  .percentage-container {
    margin-bottom: 20px;
  }

  ${({ theme }) => `
  ${theme.mediaQueries.md} {
    h2 {
      font-size: 27px;
      margin-bottom: 40px;
    }

    .slider-container {
      margin-bottom: 60px;
    }
  }
`}
`;

const slidesData = [
  {
    image: "/img/intro/whatisit.png",
    title: "What is it?",
    text: "This module will enable your business to understand its Digital Commerce strengths and opportunities at an organizational level. This granularity is crucial to laying down the right strategic roadmap for your business.",
  },
  {
    image: "/img/intro/howdoesitwork.png",
    title: "How does it work?",
    text: "Assess your business's Digital Commerce competencies against each of twenty four elements. Score each element one for low and five for high, for both current competency level and your view on how important that competency is to the success of your business.",
  },
  {
    image: "/img/intro/whathappensnext_sm.png",
    title: "What happens next?",
    text: "d-BUG will amalgamate the scores from all the respondents in your company and either make intra company comparisons or compare your business to a CPG benchmark.",
  },
];

export const ContainerSmall = styled.div`
  margin: 0 15px 20px 15px;
`;
function Intro({ switchTo, showPercentageTitle }) {
  const theme = useTheme();
  return (
    <>
      <ContainerSmall>
        <Content>
          {showPercentageTitle && (
            <div className="percentage-container">
              <PercentageTitle
                theme={theme}
                title="Organisation Benchmarker"
                percentage={null}
                image="/img/dBUG_logo.png"
              ></PercentageTitle>
            </div>
          )}
          <div className="slider-container">
            <Swiper spaceBetween={0} slidesPerView={1} navigation>
              {slidesData.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="slide-image">
                    <img src={slide.image} alt={slide.title} />
                  </div>
                  <h2 className="slide-title">{slide.title}</h2>
                  <div className="slide-text-wrapper">
                    <p className="slide-text">{slide.text}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <Link to={routes.organizationalAssessment}>
            <PrimaryButton>Complete Survey</PrimaryButton>
          </Link>
        </Content>
      </ContainerSmall>
    </>
  );
}

export default Intro;
