const addAnswer = async (answer, quizType) => {
  let url = settings.baseUrl + apiRoutes.userResponse;
  let method = "POST";
  let urlParam = answer.id ? url + "/1" : url;
  answer.quizType = quizType;

  if (settings.debug) {
    console.log(
      `[SERVER REQUEST] - ${method} - ${urlParam}`,
      answer,
      JSON.stringify(answer)
    );
  }

  const res = await fetch(urlParam, {
    method: method,
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem(
        settings.keyAccessToken
      )}`,
    },
    body: JSON.stringify(answer),
  });

  if (res && res.ok && res.status === 200) {
    const data = await res.json();
    if (settings.debug) console.log(data);
  } else {
    throw new Error(
      `Server response failed: Status ${res.status}, Ok: ${res.ok}, StatusText: ${res.statusText}`
    );
  }
};

const settings = {
  debug: false,
  baseUrlLocal: "http://localhost:5000/",
  baseUrl: "https://mw1brx5oi8.execute-api.eu-west-1.amazonaws.com/prod/",
  totalQuestions: 24,
  questionsPerCompetency: 3,
  startQuestionIndex: 1,
  competencyList: "competencyList",
  answers: "answers",

  auth0Domain: "dev-akt8qfnj.eu.auth0.com",
  auth0ClientId: "gEz8jB92G8b9wplvTubJSnl3icFjiEEt",
  auth0scope:
    "openid profile email read:current_user update:current_user_metadata",
  auth0audience: "https://dev-akt8qfnj.eu.auth0.com/api/v2/",

  allowedRoutesForUsers: [
    "/organizational-assessment",
    "/productivity-assessment",
    "/assessment",
    "/logout",
    "/profile",
    "/",
  ],
  adminRole: "Administrator",
  keyUserData: "userData",
  keyUser: "user",
  keyAccessToken: "accessToken",
  keyProfilePopulated: "profilePopulated",
  keyAssessment: "d-bug-assessment",
  superAdminList: ["marc@daedal.uk", "bkamenov90@gmail.com"], //"bkamenov90@gmail.com", "marc@daedal.uk"
};

const apiRoutes = {
  userResponse: "user-response",
  userProfile: "user-profile",
  skillsAssessor: "user-response-skills-assessor",
  downloadData: "download-data",
  listAllUsers: "list-all-users",
  setQuestionsVisibility: "set-questions-visibility",
  quizVisibilityUsers: "quiz-visibility-users",
  users: "users",
  user: "user",
};

const specialWhiteList = [
  "silas.moestrup.pedersen@lego.com",
  "nikki.akers@loreal.com",
  "karen@thequanticgroup.com",
  "colinalewis@gmail.com",
];

const routes = {
  unauthorized: "/unauthorized",
  assessment: "/assessment",
  benchmarking: "/benchmarking",
  profile: "/profile",
  myLearning: "/my-learning",
  learningLibrary: "/learning-library",
  latestNews: "/latest-news",
  experts: "/experts",
  corporateModules: "/corporate-modules",
  singleCourseDetails: "/single-course-details",
  organizationalAssessment: "/organizational-assessment",
  productivityAssessment: "/productivity-assessment",
  dashboard: "/",
  logout: "/logout",
  login: "/login",
  privacyPolicy: "/privacy-policy",
  download: "/download",
  configureUsers: "/configure-users",
};

export { addAnswer, settings, routes, apiRoutes, specialWhiteList };
