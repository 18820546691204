import { settings } from "./BenchmarkService";

export async function apiFetch(request) {
  let result = {};
  try {
    let token = sessionStorage.getItem(settings.keyAccessToken);
    let fetchData = {
      method: request.method || "GET",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    if (request.data) {
      fetchData.body = JSON.stringify(request.data);
    }

    let baseUrl = request.baseUrl || settings.baseUrl;
    const response = await fetch(baseUrl + request.url, fetchData);

    if (response.ok && response.status === 200) {
      const json = await response.json();
      result.data = json;
      result.isSuccessful = true;
    } else {
      throw response;
    }
  } catch (e) {
    result.isSuccessful = false;
    result.error = e;
  } finally {
    result.loading = false;
  }

  return result;
}
