/**
 * Updates users after user interaction in the UI (check or uncheck)
 * @param {*} users array with all users
 * @param {*} updatedUser user which needs to be updated
 * @returns array with all users updated based on user interaction in the UI
 */
export function updateUsers(users, updatedUser) {
  return users.map((user) => {
    if (user.id === updatedUser.id) {
      return updatedUser;
    } else {
      return user;
    }
  });
}

/**
 *
 * @param {*} users array with all users
 * @param {*} questionId id of the question which will be added to user's array with questions ids
 * @param {*} key array key which may be one of the following:
 * 1. 0 - for benchmark questions
 * 2. 1 - for skills questions
 * 3. 2 - for productivity questions
 * @returns array with all users updated
 */

export function addQuestionIdsToUsers(users, questionId, key) {
  return users.map((user) => {
    if (user.checked) {
      if (!user[key].includes(questionId)) {
        user[key].push(questionId);
      }
    }
    return user;
  });
}

/**
 *
 * @param {*} users array with all users
 * @param {*} questionId id of the question which will be remove from user's array with questions ids
 * @param {*} key array key which may be one of the following:
 * 1. 0 - for benchmark questions
 * 2. 1 - for skills questions
 * 3. 2 - for productivity questions
 * @returns array with all users updated
 */
export function removeQuestionIdsFromUsers(users, questionId, key) {
  return users.map((user) => {
    if (user.checked) {
      if (user[key].includes(questionId)) {
        const index = user[key].indexOf(questionId);
        user[key].splice(index, 1);
      }
    }

    return user;
  });
}
