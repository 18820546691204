/** @jsxImportSource @emotion/react */

function UnderstandScoring() {
  return (
    <>
      <ul>
        <li>
          Score 5: The right hand statement fully describes my position in our
          business.
        </li>

        <li>
          Score 4: The right hand statement mostly describes my position in our
          business.
        </li>

        <li>
          Score 3: Neither the left nor the right hand statement mostly describe
          my position in our business.
        </li>

        <li>
          Score 2: The left hand statement mostly describes my position in our
          business.
        </li>

        <li>
          Score 1: The left hand statement fully describes my position in our
          business.
        </li>
      </ul>
    </>
  );
}

export default UnderstandScoring;
