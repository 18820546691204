/** @jsxImportSource @emotion/react */
import { BsTriangleFill } from "react-icons/bs";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

import { PrimaryRoundButton, theme } from "Theme";
import { Modal, ModalHead, ModalBody } from "components/Modal";

const BodyContainer = styled.div`
    padding: 0;
    margin: 0;
    list-style-type: none;
    color: ${theme.colors.white};
    li: {
      margin-bottom: 15;
    },
`;
function BaseModal({ isOpen, handleClose, backButtonText, children }) {
  const theme = useTheme();
  return (
    <>
      <Modal isOpen={isOpen} css={{ color: theme.colors.white }}>
        <ModalHead>
          <div css={{ display: "flex", alignItems: "center" }}>
            <PrimaryRoundButton onClick={handleClose}>
              <span>
                <BsTriangleFill
                  css={{
                    transform:
                      "rotate(-90deg) translateY(-2px) translateX(2px)",
                  }}
                />
              </span>
            </PrimaryRoundButton>

            <p
              css={{
                marginBottom: 0,
                marginLeft: 20,
                fontSize: 18,
              }}
            >
              {backButtonText}
            </p>
          </div>
        </ModalHead>
        <ModalBody>
          <BodyContainer>{children}</BodyContainer>
        </ModalBody>
      </Modal>
    </>
  );
}
export default BaseModal;
