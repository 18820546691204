/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import OrganizationalProductivity from "./OrganizationalProductivity";
import { ProductivityProvider } from "./state/ProductivityContext";
import { Spinner } from "components/Spinner";
import ContextHeader from "./header/ContextHeader";
import { apiRoutes } from "services/BenchmarkService";
import useFetchWithEffect from "../../services/useFetchWithEffect";
import { QUESTION_TYPES_ENUM } from "components/configure-users/utils/constants";
import { apiFetch } from "services/apiFetch";

// import productivity list with questions from resources.json
import { productivityList } from "../../resources.json";

const ProductivityOrientation = styled.div`
  @media screen and (max-width: 767px) and (orientation: portrait) {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

function ProductivityBase({ switchTo }) {
  const { data, loading, error } = useFetchWithEffect(
    `${apiRoutes.userResponse}?quizType=${QUESTION_TYPES_ENUM.productivity}`
  );

  const [productivityQuestions, setProductivityQuestions] = useState();
  const [productivityResponses, setProductivityResponses] = useState();

  useEffect(() => {
    apiFetch({
      url: apiRoutes.userProfile,
      method: "GET",
    }).then((userProfileResponse) => {
      if (userProfileResponse.isSuccessful) {
        const productivity = userProfileResponse.data.response.quizzes.find(
          (quiz) => quiz.quizType === QUESTION_TYPES_ENUM.productivity
        );

        if (productivity) {
          let questions = JSON.parse(JSON.stringify(productivityList)).map(
            (category) => {
              const categoryQuestions = [];

              category.questionList.forEach((question) => {
                if (productivity.questions.includes(question.id)) {
                  categoryQuestions.push(question);
                }
              });

              category.questionList = categoryQuestions;
              return category;
            }
          );

          questions = questions.filter(
            (category) => category.questionList.length > 0
          );
          setProductivityQuestions(questions);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (data && data.responses && productivityQuestions) {
      const responses = [];

      data.responses.forEach((response) => {
        productivityQuestions.forEach((category) => {
          category.questionList.forEach((question) => {
            if (question.id === response.question_index) {
              responses.push(response);
            }
          });
        });
      });
      setProductivityResponses(responses);
    }
  }, [productivityQuestions, data]);

  if (error) throw error;
  let stillLoading =
    loading ||
    !productivityQuestions ||
    !productivityResponses ||
    !data ||
    !data.responses;
  if (stillLoading) return <Spinner></Spinner>;

  return (
    <ProductivityProvider>
      <ProductivityOrientation>
        <ContextHeader
          productivityList={productivityQuestions}
          productivityId={productivityQuestions[0].id}
          answer={productivityQuestions[0].questionList[0].id}
        />
        <OrganizationalProductivity
          fetchedAnswers={productivityResponses}
          productivityList={productivityQuestions}
          switchTo={switchTo}
          currentQuestionIndex={productivityQuestions[0].questionList[0].id}
        ></OrganizationalProductivity>
      </ProductivityOrientation>
    </ProductivityProvider>
  );
}

export default ProductivityBase;
