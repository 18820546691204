/** @jsxImportSource @emotion/react */
import { useEffect, useState, useRef } from "react";
import ReactPaginate from "react-paginate";

import ListUsers from "./ListUsers";
import RowsPerPageDropdown from "./RowsPerPageDropdown";

function PaginatedList({
  itemsPerPage,
  foundUsers,
  handleUpdateUser,
  rowsPerPageOptions,
  handleRowsPerPageSelect,
  deleteUser,
}) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentFoundUsers = foundUsers.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(foundUsers.length / itemsPerPage);
  const ref = useRef();

  // when user click to request another page
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % foundUsers.length;
    setItemOffset(newOffset);
  };

  // reset pagination to first page (index 0) when items per page is changed
  useEffect(() => {
    handlePageClick({ selected: 0 });
  }, [itemsPerPage]);

  return (
    <>
      <ListUsers
        currentFoundUsers={currentFoundUsers}
        handleUpdateUser={handleUpdateUser}
        deleteUser={deleteUser}
      />

      <div
        css={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <RowsPerPageDropdown
          rowsPerPageOptions={rowsPerPageOptions}
          handleRowsPerPageSelect={handleRowsPerPageSelect}
        />

        {itemsPerPage < foundUsers.length && (
          <ReactPaginate
            ref={ref}
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={false}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          />
        )}
      </div>
    </>
  );
}

export default PaginatedList;
