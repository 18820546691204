/** @jsxImportSource @emotion/react */

import Competency from 'components/learning-library/Competency';
import Header from 'components/Header';
import Results from 'components/learning-library/Results';
import Types from 'components/learning-library/Types';
import { useState } from 'react';

// import { Container } from 'Theme';

// Components

// import EditorialContent from 'components/learning-library/EditorialContent';
// import CategoryFilters from 'components/learning-library/CategoryFilters';

// import Filter from 'components/learning-library/Filter';

// import Pills from 'components/learning-library/Pills';

// const carousel = [
//   {
//     id: 1,
//     title:
//       'Carousel with editorial control to push content to surface i.e. new, most relevant etc.',
//     link: '/',
//   },
//   {
//     id: 2,
//     title:
//       'Carousel with editorial control to push content to surface i.e. new, most relevant etc. 2',
//     link: '/',
//   },
//   {
//     id: 3,
//     title:
//       'Carousel with editorial control to push content to surface i.e. new, most relevant etc. 3',
//     link: '/',
//   },
// ];

// const categories = [
//   {
//     title: 'Performance',
//     filters: [
//       {
//         image: '',
//         title: 'Knowing the landscape',
//       },
//       {
//         image: '',
//         title: 'Category understanding',
//       },
//       {
//         image: '',
//         title: 'Driving for results',
//       },
//     ],
//   },
//   {
//     title: 'Activation',
//     filters: [
//       {
//         image: '',
//         title: 'Comfortable with channel tools',
//       },
//       {
//         image: '',
//         title: 'Developing great activation planning',
//       },
//     ],
//   },
//   {
//     title: 'Customer',
//     filters: [
//       {
//         image: '',
//         title: 'Customer excellence',
//       },
//       {
//         image: '',
//         title: 'Planning and investment',
//       },
//     ],
//   },
//   {
//     title: 'Execution',
//     filters: [
//       {
//         image: '',
//         title: 'The Digital Shelf',
//       },
//       {
//         image: '',
//         title: 'Data Analysis and Application',
//       },
//       {
//         image: '',
//         title: 'Best Practice Testing and Sharing',
//       },
//     ],
//   },
// ];

const filters = {
  competency: [
    { title: 'Knowing The Landscape', link: '#' },
    { title: 'Category Understanding', link: '#' },
    { title: 'Drive For Results', link: '#' },
    { title: 'Comfortable With Channel Tools', link: '#' },
    { title: 'Develops A Great Activation Plan', link: '#' },
    { title: 'Customer Excellence', link: '#' },
    { title: 'Planning And Investment', link: '#' },
    { title: 'The Digital Shelf', link: '#' },
    { title: 'Data Analysis And Application', link: '#' },
    { title: 'Testing And Sharing', link: '#' },
  ],
  typeOfLearning: [
    'Skill pills',
    'd-IMPACT Workshops / Specialist coaching',
    'Webinars',
    'eLearning',
    'Other resources',
  ],
};

const resources = {
  'Skill pills': [
    {
      title: 'SMART Objectives',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint:
        'What are the right Digital Commerce KPIs for your business to focus on and why?',
    },
    {
      title: 'Mentoring success',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint:
        'What are the key principles for making mentoring relationships a success?',
    },
    {
      title: 'Building the best customer relationships',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint:
        'What do you need to do to get the most out of retailer partnerships?',
    },
    {
      title: 'Amazon Matters',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'Why do you need an Amazon strategy in your business?',
    },
    {
      title: 'The wonderful world of Digital Commerce',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint:
        'How does the landscape look from the perspective of the brand, retailer and shopper?',
    },
    {
      title: 'A Test and Learn mindset',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint:
        'Why is having a healthy approach to test and learn a fundamental for success?',
    },
    {
      title: 'Telling interesting stories',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'Why is telling stories a good idea in the digital world?',
    },
    {
      title: 'Fixing the Basics',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'What should you do first to deliver most commercial benefit?',
    },
    {
      title: 'Category matters',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint:
        'Why will a category first approach help you win with the retailers?',
    },
    {
      title: 'First Agile Steps',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'Why are leaner, smarter working practices a good idea?',
    },
    {
      title: 'Meet the Digital Shelf',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'What are the online point of purchase fundamentals?',
    },
    {
      title: 'Channel definition 101',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'What are the different Digital Commerce subchannels?',
    },
    {
      title: 'Omnichannel thinking',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'How do offline and online shopping interact?',
    },
    {
      title: 'Shoppers versus consumers',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'Why does a shopper first mindset matter online?',
    },
    {
      title: 'Shopper triggers and barriers',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint:
        'What are the basic challenges to overcome for your online shoppers?',
    },
    {
      title: 'Using retail media',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'What is the best way to think about retail media planning?',
    },
    {
      title: 'The power of data',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'Why is data really important in Digital Commerce?',
    },
    {
      title: 'KTL Best Practice Guide',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: "What does 'Know the Landscape' Best Practice look like?",
    },
    {
      title: 'CU Best Practice Guide',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: "What does 'Category Understanding' Best Practice look like?",
    },
    {
      title: 'DFR Best Practice Guide',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: "What does 'Drive For Results' Best Practice look like?",
    },
    {
      title: 'CWCT Best Practice Guide',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint:
        "What does 'Comfortable With Channel Tools' Best Practice look like?",
    },
    {
      title: 'DAGAP Best Practice Guide',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint:
        "What does 'Develops A Great Activation Plan' Best Practice look like?",
    },
    {
      title: 'CE Best Practice Guide',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: "What does 'Customer Excellence' Best Practice look like?",
    },
    {
      title: 'PAI Best Practice Guide',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: "What does 'Planning and Investment' Best Practice look like?",
    },
    {
      title: 'TDS Best Practice Guide',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: "What does 'The Digital Shelf' Best Practice look like?",
    },
    {
      title: 'DAAA Best Practice Guide',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint:
        "What does 'Data Analysis And Application' Best Practice look like?",
    },
    {
      title: 'TAS Best Practice Guide',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: "What does 'Testing and Sharing' Best Practice look like?",
    },
    {
      title: 'Social Commerce at a glance',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/skill_pills.jpg',
      hint: 'What is Social Commerce and how big is the opportunity?',
    },
  ],
  'd-IMPACT Workshops / Specialist coaching': [
    {
      title: 'Raise the Floor',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/dIMPACT.jpg',
      hint: 'Digital Commerce 101 workshop for large groups',
    },
    {
      title: 'Raise the Ceiling',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/dIMPACT.jpg',
      hint: 'Planning for success with the retailers with smaller teams',
    },
    {
      title: 'Raise the Roof / Unlocking Amazon',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/dIMPACT.jpg',
      hint: 'Specialist coaching for a small group',
    },
    {
      title: 'Raise the Roof / Marketplace strategy',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/dIMPACT.jpg',
      hint: 'Specialist coaching for a small group',
    },
    {
      title: 'Raise the Roof / Mastering the Digital Shelf',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/dIMPACT.jpg',
      hint: 'Specialist coaching for a small group',
    },
    {
      title: 'Raise the Roof / Evaluating D2C',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/dIMPACT.jpg',
      hint: 'Specialist coaching for a small group',
    },
    {
      title: 'Raise the Roof / Senior Leader Upskill',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/dIMPACT.jpg',
      hint: 'Specialist coaching for a small group',
    },
  ],
  Webinars: [
    {
      title: 'Identify The Opportunity',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/webinar.jpg',
      hint: 'Key d-IMPACT Identify the Opportunity principles',
    },
    {
      title: 'Measuring Success',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/webinar.jpg',
      hint: 'Key d-IMPACT Measuring Success principles',
    },
    {
      title: 'Path to Purchase',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/webinar.jpg',
      hint: 'Key d-IMPACT Path to Purchase principles',
    },
    {
      title: 'Activation Plan',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/webinar.jpg',
      hint: 'Key d-IMPACT Activation Plan principles',
    },
    {
      title: 'Cost and Payback',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/webinar.jpg',
      hint: 'Key d-IMPACT Cost and Payback principles',
    },
    {
      title: 'Track and Learn',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/webinar.jpg',
      hint: 'Key d-IMPACT Track and Learn principles',
    },
  ],
  eLearning: [
    {
      title: 'Policy and compliance',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'Mandatories concerning use of data in Digital Commerce',
    },
    {
      title: 'Digital Marketing for Digital Commerce folk',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint:
        'Difference between Digital Commerce and Digital Marketing; headlines of key marketing approaches',
    },
    {
      title: 'Technical Set up of eCommerce architecture',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint:
        'Technical deep dive of all the back end architecture that makes eCommerce work',
    },
    {
      title: 'Winning Search',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'How to win at search; internal R&Rs',
    },
    {
      title: 'Creating winning content',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'How to create the best content; internal R&Rs',
    },
    {
      title: 'Activation ROI',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint:
        'Considerations for making smart investment decisions; different ways of thinking about ROI',
    },
    {
      title: 'Category Essentials',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'Basic Catman principles; brand first v category first',
    },
    {
      title: 'Online Shopper Essentials',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint:
        'What matters to the online shopper and how to influence them; typologies and needs',
    },
    {
      title: 'Amazon Building Blocks',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'Understanding the nuances of Amazon compared to other retailers',
    },
    {
      title: 'Amazon media toolkit',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'Best ways to invest on the Amazon platform',
    },
    {
      title: 'Category Advanced',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'Advanced Catman principles; Category Strategy',
    },
    {
      title: 'Using Data effectively',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'Solving problems with data; considerations for a data strategy',
    },
    {
      title: 'Advanced Analytics',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint:
        "Data tools and techniques to consider when you've mastered the basics",
    },
    {
      title: 'PLANR strategy builder',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'Key elements to consider to build a Digital Commerce Strategy',
    },
    {
      title: 'SCANR digital shelf assessment',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'Basic assessment of the digital shelf',
    },
    {
      title: 'Social Commerce for beginners',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/eLearning.jpg',
      hint: 'How to integrate Social Commerce into your Digital Commerce mix',
    },
  ],
  'Other resources': [
    {
      title: 'Toolkits / Online Category Vision',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/otherresources.jpg',
      hint: 'Link to existing or create',
    },
    {
      title: 'Toolkits/ Digital Commerce Playbook',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/otherresources.jpg',
      hint: 'Link to existing or create',
    },
    {
      title: 'Toolkits / The Perfect Digital Shelf',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/otherresources.jpg',
      hint: 'Link to existing or create',
    },
    {
      title: 'Podcasts',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/otherresources.jpg',
      hint: 'Weekly podcast uploaded to the platform',
    },
    {
      title: 'Articles',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/otherresources.jpg',
      hint: '3 curated articles of interest published each week',
    },
    {
      title: 'Virtual Business Games',
      category: '',
      link: '/',
      image: '/img/learninglibrary/resources/otherresources.jpg',
      hint: 'Set and deliver virtual group challenges around business problems',
    },
  ],
};

function LearningLibrary() {
  const [selectedFilters, setSelectedFilters] = useState({
    types: [],
    categories: [],
  });

  return (
    <div
      css={{
        flex: 1,
        backgroundImage: 'url(/img/main.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        paddingBottom: 60,
      }}>
      <Header />

      {/* <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      /> */}

      {/* <EditorialContent data={carousel} /> */}

      {selectedFilters.types.length || selectedFilters.categories.length ? (
        <>
          {/* <Pills
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          /> */}

          <Results
            resources={resources}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </>
      ) : (
        <>
          {/* <Container>
            <h2
              css={{
                margin: '20px 0',
                textDecoration: 'underline',
              }}>
              Competencies
            </h2>
          </Container> */}

          {/* {categories.map((category) => (
            <CategoryFilters
              key={category.title}
              title={category.title}
              filters={category.filters}
              setSelectedFilters={setSelectedFilters}
            />
          ))} */}

          <Competency data={filters.competency} />

          <Types
            data={filters.typeOfLearning}
            setSelectedFilters={setSelectedFilters}
          />
        </>
      )}
    </div>
  );
}

export default LearningLibrary;
