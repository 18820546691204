/** @jsxImportSource @emotion/react */
import Collapsible from "./Collapsible";
import QuestionsSection from "./QuestionsSection";
import { QUESTION_TYPES } from "../../utils/constants";

function CollapsibleSection({
  competencyQuestions,
  competencyOpen,
  setCompetencyOpen,
  allCompetencySelected,
  expertiseQuestions,
  expertiseOpen,
  setExpertiseOpen,
  allExpertiseSelected,
  productivityQuestions,
  productivityOpen,
  setProductivityOpen,
  allProductivitySelected,
  handleQuestionSelect,
  handleCategorySelect,
  handleSelectAllByType,
  users,
}) {
  return (
    <div className="accordion" css={{ maxHeight: "528px", overflowY: "auto" }}>
      <Collapsible
        isOpen={competencyOpen}
        setIsOpen={setCompetencyOpen}
        disabled={competencyQuestions.length === 0}
        isSelected={allCompetencySelected}
        setIsSelected={() => handleSelectAllByType(QUESTION_TYPES.benchmark)}
        headerText={"Organisation Benchmark"}
      >
        <QuestionsSection
          questions={competencyQuestions}
          handleQuestionSelect={(question) => {
            handleQuestionSelect(QUESTION_TYPES.benchmark, question);
          }}
          handleCategorySelect={(question) => {
            handleCategorySelect(QUESTION_TYPES.benchmark, question);
          }}
          users={users}
          section={QUESTION_TYPES.benchmark}
        />
      </Collapsible>

      <Collapsible
        isOpen={expertiseOpen}
        setIsOpen={setExpertiseOpen}
        disabled={expertiseQuestions.length === 0}
        isSelected={allExpertiseSelected}
        setIsSelected={() => handleSelectAllByType(QUESTION_TYPES.skills)}
        headerText={"Skills Assessment"}
      >
        <QuestionsSection
          questions={expertiseQuestions}
          handleQuestionSelect={() => {
            handleQuestionSelect(QUESTION_TYPES.skills);
          }}
          users={users}
          section={QUESTION_TYPES.skills}
        />
      </Collapsible>

      <Collapsible
        isOpen={productivityOpen}
        setIsOpen={setProductivityOpen}
        disabled={productivityQuestions.length === 0}
        isSelected={allProductivitySelected}
        setIsSelected={() => handleSelectAllByType(QUESTION_TYPES.productivity)}
        headerText={"Productivity Assessor"}
      >
        <QuestionsSection
          questions={productivityQuestions}
          handleQuestionSelect={(question) => {
            handleQuestionSelect(QUESTION_TYPES.productivity, question);
          }}
          handleCategorySelect={(question) => {
            handleCategorySelect(QUESTION_TYPES.productivity, question);
          }}
          users={users}
          section={QUESTION_TYPES.productivity}
        />
      </Collapsible>
    </div>
  );
}

export default CollapsibleSection;
