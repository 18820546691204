/** @jsxImportSource @emotion/react */
import ReactTooltip from "react-tooltip";
import { FaCheck } from "react-icons/fa";
import { BsQuestionCircleFill } from "react-icons/bs";
import styled from "@emotion/styled";

import { theme } from "Theme";
import { useProductivity } from "../state/ProductivityContext";

function DescriptionTooltip({ definition }) {
  return (
    <div
      data-tip={definition}
      css={{
        cursor: "pointer",
        display: "inline",
        color: theme.colors.lightblue,
        ".tooltip": {
          textAlign: "center",
          maxWidth: 250,
          padding: 8,
          color: theme.colors.white,
          backgroundColor: theme.colors.lightblue,
          [theme.mediaQueries.md]: {
            maxWidth: 350,
            padding: 10,
          },
        },
      }}
    >
      <BsQuestionCircleFill />

      <ReactTooltip className="tooltip" delayShow={300} multiline={true} />
    </div>
  );
}

function getProductivityLabel(label, labelState, definition) {
  let productivityLabel;
  if (labelState === "done") {
    productivityLabel = (
      <div className="productivity-label productivity-label-done">
        {label} <DescriptionTooltip definition={definition} />
      </div>
    );
  } else if (labelState === "active") {
    productivityLabel = (
      <div className="productivity-label productivity-label-active">
        {label} <DescriptionTooltip definition={definition} />
      </div>
    );
  } else if (labelState === "edit") {
    productivityLabel = (
      <div className="productivity-label productivity-label-edit">
        {label} <DescriptionTooltip definition={definition} />
      </div>
    );
  } else {
    productivityLabel = (
      <div className="productivity-label">
        {label} <DescriptionTooltip definition={definition} />
      </div>
    );
  }
  return productivityLabel;
}

const Content = styled.div`
  .productivity-done {
    color: ${theme.colors.benchDoneGray};
    margin: "5px 5px 5px 30px";
  }
  .productivity-active,
  .productivity-edit {
    color: ${theme.colors.white};
  }

  .productivity-label {
    color: ${theme.colors.benchFadeWhite};
    margin-left: 20px;
    font-family: Cabin;
    font-size: 17px;
    font-weight: normal;
    display: inline-block;
  }

  .productivity-label-done {
    margin-left: 0;
    color: ${theme.colors.benchDoneGray};
    min-width: 170px;
    width: 170px;
    vertical-align: text-top;
  }

  .productivity-label-active,
  .productivity-label-edit {
    color: ${theme.colors.white};
    font-weight: bold;
  }

  .productivity-label-edit {
    margin-left: 0;
    min-width: 170px;
    width: 170px;
    vertical-align: text-top;
  }

  .productivity-element {
    color: ${theme.colors.benchFadeWhite};
    margin: 5px 5px 5px 25px;

    font-family: Cabin;
    font-size: 17px;
    font-weight: normal;
    display: flex;
    align-items: baseline;
  }
`;

function getProductivityClass(completionState) {
  let baseClass = "productivity-element";
  if (completionState === "done") {
    baseClass += " productivity-done";
  } else if (completionState === "active") {
    baseClass += " productivity-active";
  } else if (completionState === "edit") {
    baseClass += " productivity-edit";
  }
  return baseClass;
}

function getCheck(completionState) {
  let check = "";
  if (completionState === "done") {
    check = (
      <FaCheck
        size={15}
        css={{ marginRight: "5px", color: theme.colors.benchDoneGray }}
      />
    );
  } else if (completionState === "edit") {
    check = (
      <FaCheck
        size={15}
        css={{ marginRight: "5px", color: theme.colors.white }}
      />
    );
  }

  return check;
}

function ProductivityElements({ questionList }) {
  const { getCurrentAnswer, getHeadAnswer } = useProductivity();
  let currentAnswer = getCurrentAnswer();
  let headAnswer = getHeadAnswer();

  function getState(id) {
    if (headAnswer.question_index === id && currentAnswer.question_index === id)
      return "active";
    if (currentAnswer.question_index === id) return "edit";
    if (headAnswer.question_index < id) return "";
    if (headAnswer.question_index > id) return "done";
  }
  return (
    <>
      <Content>
        {questionList.map((value) => {
          return (
            <div
              key={value.id}
              className={getProductivityClass(getState(value.id))}
            >
              {getCheck(getState(value.id))}
              {getProductivityLabel(
                value.label,
                getState(value.id),
                value.definition
              )}
            </div>
          );
        })}
      </Content>
    </>
  );
}

export default ProductivityElements;
