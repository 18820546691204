/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import { theme } from "Theme";
import { PrimaryButton, ContainerSmall, PageHeading } from "Theme";

SwiperCore.use([Pagination]);

const Content = styled.div`
  max-width: ${theme.containerSmallWidth};
  background-color: ${theme.colors.benchBaseBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 30px 15px;

  width: 100%;
  margin: 10px auto;

  h1 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.white};
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.colors.yellow};
    margin-top: 45px;
  }
  button {
    width: 75%;
    max-width: 420px;
  }

  .slider-container {
    width: 100%;
    margin: 0 auto 20px;
  }

  .swiper-slide {
    text-align: center;
  }

  .slide-image {
    width: 100px;
    height: 100px;
    margin: 0 auto 26px;
    position: relative;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.darkestblue};
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      object-position: center center;
      opacity: 1;
    }
  }

  .slide-title {
    font-weight: 400;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.yellow};
  }
  .slide-text-wrapper {
    padding: 0 10px;
  }
  .slide-text {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    width: 70%;
    margin: auto;
  }

  ${({ theme }) => `
  ${theme.mediaQueries.md} {
    h2 {
      font-size: 27px;
      margin-bottom: 40px;
    }

    .slider-container {
      margin-bottom: 60px;
    }
  }
`}
`;

const slidesData = [
  {
    image: "/img/intro/whatisit.png",
    title: "What is it?",
    text: "This is a self assessment tool where you can rate your skills against the ten core Digital Commerce individual competencies",
  },
  {
    image: "/img/intro/howdoesitwork.png",
    title: "How does it work?",
    text: "Rate your skills with one to five stars against each competency. NB this is anonymised data entry so please be honest!",
  },
  {
    image: "/img/intro/whathappensnext_sm.png",
    title: "What happens next?",
    text: "d-BUG will compare your profile against the target profile for your function and seniority level, and recommend a personalised learning plan to help close any gaps.",
  },
];

function Intro({ switchTo }) {
  return (
    <>
      <PageHeading>d-BUG Skills Profiler</PageHeading>
      <ContainerSmall>
        <Content>
          <div className="slider-container">
            <Swiper spaceBetween={0} slidesPerView={1} navigation>
              {slidesData.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="slide-image">
                    <img src={slide.image} alt={slide.title} />
                  </div>
                  <h2 className="slide-title">{slide.title}</h2>
                  <div className="slide-text-wrapper">
                    <p className="slide-text">{slide.text}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <PrimaryButton onClick={() => switchTo("Questions")}>
            Complete Survey
          </PrimaryButton>
        </Content>
      </ContainerSmall>
    </>
  );
}

export default Intro;
