/** @jsxImportSource @emotion/react */

import Header from "components/Header";
import ProductivityHeader from "./ProductivityHeader";
import { ShowOnDesktop, ShowOnMobile } from "Theme";

function ContextHeader({ productivityList, productivityId, answer }) {
  let productivity = productivityList.find((c) => c.id === productivityId);
  let question = null;

  if (productivity) {
    question = productivity.questionList.find((q) => q.id === answer);
  }

  return (
    <>
      {question && (
        <>
          (
          <ShowOnMobile>
            <ProductivityHeader
              headerTitle={"Productivity Assessor"}
              competencyTitle={productivity.label}
              competencySubTitle={question.label}
              competencyNum={productivity.id}
            />
          </ShowOnMobile>
          <ShowOnDesktop>
            <Header mainNav={true} />
          </ShowOnDesktop>
          )
        </>
      )}
    </>
  );
}

export default ContextHeader;
