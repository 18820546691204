/** @jsxImportSource @emotion/react */
import { useHistory } from "react-router-dom";
import useUserData from "./useUserData";

function ProtectedComponent({ children }) {
  const history = useHistory();
  const userData = useUserData(history.location.pathname);
  if (!userData || !userData.isAdmin) {
    return <></>;
  }
  return <>{children}</>;
}

export default ProtectedComponent;
