/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { Container } from 'Theme';

const TabsNav = styled.ul`
  margin: 0 auto;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;

  li {
    flex: 1;
    height: 100%;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      li {
        flex: 0 0 auto;
      }
    }
  `}
`;

const TabsNavButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
  padding: 15px 20px;
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  display: block;
  position: relative;
  font-size: 12px;
  margin: 0 auto;
  white-space: normal;
  line-height: 100%;
  height: 100%;
  &.active {
    color: ${({ theme }) => theme.colors.white};

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      font-size: 14px;
      margin: 0;

      &:hover {
        text-decoration: none;
        color: ${theme.colors.yellow};
      }
    }
  `}
`;

function Tabs({ navItems, children }) {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = React.useState(0);

  return (
    <div>
      <div css={{ backgroundColor: theme.colors.blue }}>
        <Container>
          <TabsNav>
            {navItems.map((tab, index) => (
              <li key={tab.label}>
                <TabsNavButton
                  className={currentTab === index ? 'active' : ''}
                  onClick={() => setCurrentTab(index)}>
                  {tab.label}
                </TabsNavButton>
              </li>
            ))}
          </TabsNav>
        </Container>
      </div>

      <Container>{children[currentTab] || children}</Container>
    </div>
  );
}

export default Tabs;
