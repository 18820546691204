/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {} from "Theme";
import { useState } from "react";
import BaseModal from "components/benchmark/BaseModal";
// Components
import SecondaryNav from "components/SecondaryNav";
import UnderstandScoring from "components/benchmark/misc/UnderstandScoring";

const BurgerButton = styled.button`
  width: 20px;
  height: 12px;
  display: block;
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  padding: 0;

  .inner,
  .inner::before,
  .inner::after {
    position: absolute;
    width: 20px;
    height: 2px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  .inner::before,
  .inner::after {
    content: "";
    display: block;
  }

  .inner {
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 0.22s;
    transition-property: transform;
    top: 50%;
    display: block;
    margin-top: -1px;
  }

  .inner::before {
    transition: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
    top: -5px;
  }

  .inner::after {
    transition: bottom 0.1s ease-in 0.25s,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    bottom: -5px;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      width: 40px;
      height: 24px;

      .inner,
      .inner::before,
      .inner::after {
        width: 40px;
        height: 4px;
        border-radius: 4px;
      }

      .inner {
        margin-top: -2px;
      }

      .inner::before {
        top: -10px;
      }

      .inner::after {
        bottom: -10px;
      }
    }
  `}

  ${({ isActive }) =>
    isActive &&
    `
    .inner {
      transition-delay: .12s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: rotate(225deg);
    }
    .inner::before {
      top: 0;
      transition: top .1s ease-out,opacity .1s ease-out .12s;
      opacity: 0;
    }
    .inner::after {
      bottom: 0;
      transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
      transform: rotate(-90deg);
    }
  `}
`;

const HeaderElement = styled.span`
  ${({ theme }) => `
  display: inline-block;
    color: ${theme.colors.white};
    margin: 0 20px;
  `}
`;

const Title = styled.div`
  ${({ theme }) => `
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
`}
`;

const SubTitle = styled.div`
  ${({ theme }) => `
    font-size: 12px;
    margin-left: 55px;
`}
`;
const ProgressPill = styled.span`
  ${({ theme }) => `
    display: inline-block;
    font-size: 12px;
    width: 45px;
    height: 20px;
    padding: 0 9px;
    margin-right: 10px;
    border-radius: 11.5px;
    background-color: ${theme.colors.benchBluePill};
    line-height: 1.6em;
`}
`;
const ProgressCurrentItem = styled.span`
  ${({ theme }) => `
  font-size: 16px;
  font-weight: bold;
`}
`;

const ScoringLink = styled.a`
  ${({ theme }) => `
    color: ${theme.colors.benchFadeWhite};
    font-family: Cabin;
    font-size: 12px;
    font-weight: normal;
    text-decoration: underline;
    display: block;
    &:hover {
      color: ${theme.colors.white};
    }
  `}
`;

function ProductivityHeader({
  headerTitle,
  competencyTitle,
  competencySubTitle,
  competencyNum,
  showQuestionDetails = true,
}) {
  const theme = useTheme();
  let [menu, setMenu] = React.useState(false);
  let [modalOpen, setModalOpen] = useState(false);
  function toggleModal(e, isOpen) {
    e.preventDefault();
    setModalOpen(isOpen);
  }

  return (
    <>
      <header
        css={{
          position: "relative",
          backgroundColor: theme.colors.darkestblue,
          padding: "2px 15px 2px 15px",
          ".header-label": {
            marginLeft: "20px",
            fontSize: "20px",
            color: theme.colors.white,
            marginBottom: 0,
            [theme.mediaQueries.lg]: {
              marginLeft: "40px",
            },
          },
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SecondaryNav isActive={menu} />

          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BurgerButton isActive={menu} onClick={() => setMenu(!menu)}>
              <span className="inner"></span>
            </BurgerButton>
            {showQuestionDetails && (
              <div>
                <HeaderElement>
                  <Title>{headerTitle}</Title>
                  <ScoringLink href="#" onClick={(e) => toggleModal(e, true)}>
                    Understand scoring
                  </ScoringLink>
                </HeaderElement>
                <HeaderElement>
                  <ProgressPill>
                    <ProgressCurrentItem>{competencyNum}</ProgressCurrentItem>
                    /8
                  </ProgressPill>
                  <Title>{competencyTitle}</Title>
                  <SubTitle>{competencySubTitle}</SubTitle>
                </HeaderElement>
              </div>
            )}
          </div>
        </div>
      </header>
      <BaseModal
        isOpen={modalOpen}
        handleClose={(e) => toggleModal(e, false)}
        backButtonText="Back to survey"
      >
        <UnderstandScoring></UnderstandScoring>
      </BaseModal>
    </>
  );
}

export default ProductivityHeader;
