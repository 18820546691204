/** @jsxImportSource @emotion/react */
import { BsTriangleFill } from "react-icons/bs";
import { useTheme } from "@emotion/react";

import { PrimaryRoundButton, PrimaryButton, SecondaryButton } from "Theme";
import CollapsibleSection from "./CollapsibleSection";
import { Modal, ModalHead, ModalBody } from "components/Modal";
import { QUESTION_TYPES_ENUM } from "components/configure-users/utils/constants";
import { apiFetch } from "services/apiFetch";
import { apiRoutes, settings } from "services/BenchmarkService";

function QuestionsModal({
  isOpen,
  setIsOpen,
  competencyQuestions,
  competencyOpen,
  setCompetencyOpen,
  allCompetencySelected,
  expertiseQuestions,
  expertiseOpen,
  setExpertiseOpen,
  allExpertiseSelected,
  productivityQuestions,
  productivityOpen,
  setProductivityOpen,
  allProductivitySelected,
  handleQuestionSelect,
  handleCategorySelect,
  handleSelectAllByType,
  users,
}) {
  const { colors } = useTheme();

  function saveChanges() {
    const data = {
      users: [],
      quizzes: [
        {
          id: QUESTION_TYPES_ENUM.benchmark,
          questions: users[0][QUESTION_TYPES_ENUM.benchmark],
        },
        {
          id: QUESTION_TYPES_ENUM.skills,
          questions: users[0][QUESTION_TYPES_ENUM.skills],
        },
        {
          id: QUESTION_TYPES_ENUM.productivity,
          questions: users[0][QUESTION_TYPES_ENUM.productivity],
        },
      ],
    };

    users.forEach((user) => {
      data.users.push(user.id);
    });

    apiFetch({
      url: apiRoutes.setQuestionsVisibility,
      data: data,
      method: "POST",
    }).then((response) => {
      if (response.isSuccessful) {
        let userProfileDatra = sessionStorage.getItem(settings.keyUser);
        if (userProfileDatra) {
          const userProfile = JSON.parse(userProfileDatra);

          if (data.users.includes(userProfile.id)) {
            userProfile.quizzes = userProfile.quizzes.map((quiz) => {
              if (quiz.quizType !== QUESTION_TYPES_ENUM.skills) {
                return quiz;
              }
              return {
                quizType: QUESTION_TYPES_ENUM.skills,
                questions: users[0][QUESTION_TYPES_ENUM.skills],
              };
            });
            sessionStorage.setItem(
              settings.keyUser,
              JSON.stringify(userProfile)
            );
          }
        }

        setIsOpen(!isOpen);
      }
    });
  }

  return (
    <Modal isOpen={isOpen} maxWidth={576} width={576}>
      <ModalHead>
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div css={{ display: "flex", alignItems: "center" }}>
            <PrimaryRoundButton onClick={() => setIsOpen(!isOpen)}>
              <span>
                <BsTriangleFill
                  css={{
                    transform:
                      "rotate(-90deg) translateY(-2px) translateX(2px)",
                  }}
                />
              </span>
            </PrimaryRoundButton>
            <p
              css={{
                marginBottom: 0,
                marginLeft: 20,
                fontSize: 18,
              }}
            >
              Back to users
            </p>
          </div>
          <div>
            <SecondaryButton
              onClick={() => setIsOpen(!isOpen)}
              css={{
                marginLeft: "8px",
              }}
            >
              Cancel
            </SecondaryButton>

            <PrimaryButton
              onClick={saveChanges}
              css={{
                marginLeft: "8px",
              }}
            >
              Save Questions
            </PrimaryButton>
          </div>
        </div>
      </ModalHead>

      <ModalBody>
        <div
          css={{
            padding: 0,
            margin: 0,
            color: colors.white,
          }}
        >
          <CollapsibleSection
            competencyQuestions={competencyQuestions}
            competencyOpen={competencyOpen}
            setCompetencyOpen={setCompetencyOpen}
            allCompetencySelected={allCompetencySelected}
            expertiseQuestions={expertiseQuestions}
            expertiseOpen={expertiseOpen}
            setExpertiseOpen={setExpertiseOpen}
            allExpertiseSelected={allExpertiseSelected}
            productivityQuestions={productivityQuestions}
            productivityOpen={productivityOpen}
            setProductivityOpen={setProductivityOpen}
            allProductivitySelected={allProductivitySelected}
            handleQuestionSelect={handleQuestionSelect}
            handleCategorySelect={handleCategorySelect}
            handleSelectAllByType={handleSelectAllByType}
            users={users}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}

export default QuestionsModal;
