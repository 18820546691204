/** @jsxImportSource @emotion/react */
import ReactTooltip from 'react-tooltip';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';

import { PrimaryButton } from 'Theme';

// Components
import Slider from 'components/Slider';

const ImageWrapper = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 75%;
  background-color: ${({ theme }) => theme.colors.darkblue};

  .swiper-button-prev {
    background-color: ${({ theme }) => theme.colors.darkblue};
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      padding-bottom: 66.66%;
    }
  `}
`;

function Result({ type, list, selectedFilters, setSelectedFilters }) {
  const theme = useTheme();
  const filteredList = selectedFilters.categories.length
    ? list.filter((item) => selectedFilters.categories.includes(item.category))
    : list;

  if (!filteredList.length) {
    return null;
  }

  const slides = filteredList.map((item) => (
    <div key={item.title}>
      <ImageWrapper to={item.link}>
        <Link to='/single-course-details'>
          <img src={item.image} alt='' />
        </Link>
      </ImageWrapper>
      <p
        css={{
          fontSize: 13,
          padding: '5px',
          margin: 0,
          color: theme.colors.white,
          [theme.mediaQueries.md]: {
            fontSize: 16,
          },
        }}>
        {item.title}

        {item.hint && (
          <span
            data-tip={item.hint}
            css={{
              cursor: 'pointer',
              display: 'inline-block',
              marginLeft: 10,
              color: theme.colors.lightblue,
            }}>
            <BsQuestionCircleFill />

            <ReactTooltip wrapper='span' delayShow={300} multiline={true} />
          </span>
        )}
      </p>
    </div>
  ));

  return (
    <div
      css={{
        marginTop: 70,
        paddingTop: 20,
        backgroundColor: theme.colors.blackblueop,
      }}>
      <PrimaryButton
        css={{ marginBottom: 30 }}
        onClick={() => setSelectedFilters({ types: [], categories: [] })}>
        <FaChevronLeft />

        <span css={{ marginLeft: 15 }}>Back to Library</span>
      </PrimaryButton>

      <h2
        css={{
          padding: '0 15px',
          marginBottom: 20,
          fontWeight: 400,
          fontSize: 20,
          color: theme.colors.white,
          [theme.mediaQueries.md]: {
            textAlign: slides.length > 3 ? 'left' : 'center',
          },
        }}>
        {type}
      </h2>

      <Slider slides={slides} />
    </div>
  );
}

export default function Results({
  resources,
  selectedFilters,
  setSelectedFilters,
}) {
  return Object.entries(resources).map(([type, list]) => {
    if (!selectedFilters.types.length) {
      return (
        <Result
          key={type}
          type={type}
          list={list}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      );
    }

    if (selectedFilters.types.includes(type)) {
      return (
        <Result
          key={type}
          type={type}
          list={list}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      );
    } else {
      return null;
    }
  });
}
