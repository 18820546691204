/** @jsxImportSource @emotion/react */
import {
  MainContainer,
  PageHeading,
  theme,
  ContinueButton,
  ButtonContainer,
  BackButton,
} from "Theme";
import Header from "components/Header";
import styled from "@emotion/styled";
import { useState } from "react";
const Container = styled.div`
  .flex-center,
  .flex-center-bottom {
    align-items: center;
    display: flex;
  }

  .flex-center {
    height: 380px;
    justify-content: center;
  }

  .flex-center-bottom {
    justify-content: flex-end;
  }

  .flex-item-center {
    line-height: 1px;
    padding: 0 3px;
  }
`;
function SampleCharts({ pageHeading, title, images, switchTo }) {
  let [chartIndex, setChartIndex] = useState(0);
  function Chart() {
    let chart = images[chartIndex];
    if (chart && chart.url) {
      return (
        <img
          css={{
            margin: "0 auto",
            // maxWidth: "647px",
            height: `${chart.height}`,
            width: `${chart.width}`,
          }}
          alt="rating chart"
          src={chart.url}
        />
      );
    }
  }
  function updateChartIndex(index) {
    if (index === -1) {
      switchTo("BenchmarkBase");
    }
    setChartIndex(index);
  }
  return (
    <>
      <Header />
      <Container>
        <PageHeading>{pageHeading}</PageHeading>
        <MainContainer css={{ height: "464px" }}>
          <h3 css={{ color: theme.colors.white, textAlign: "center" }}>
            {title}
          </h3>
          <div className="flex-center">
            <div className="flex-item-center">
              <section
                css={{
                  backgroundColor: theme.colors.white,
                  textAlign: "center",
                  padding: "30px",
                }}
              >
                <Chart></Chart>
              </section>
            </div>
          </div>
          <div className="flex-center-bottom">
            <div className="flex-item-center">
              <ButtonContainer>
                <BackButton onClick={() => updateChartIndex(chartIndex - 1)}>
                  Back
                </BackButton>
                <ContinueButton
                  disabled={chartIndex === images.length - 1}
                  onClick={() => updateChartIndex(chartIndex + 1)}
                >
                  Next
                </ContinueButton>
              </ButtonContainer>
            </div>
          </div>
        </MainContainer>
      </Container>
    </>
  );
}

export default SampleCharts;
