/** @jsxImportSource @emotion/react */

import { Checkbox } from "components/learning-library/Filter";

function Collapsible({
  isOpen,
  setIsOpen,
  disabled,
  isSelected,
  setIsSelected,
  headerText,
  children,
}) {
  return (
    <div className="card">
      <div className="card-header">
        <h2
          className="mb-0"
          css={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <Checkbox>
            <input
              type="checkbox"
              disabled={disabled}
              checked={isSelected}
              onChange={setIsSelected}
              css={{
                cursor: "pointer",
                marginRight: "4px",
              }}
            />
          </Checkbox>
          <button
            className="btn btn-link btn-block text-left"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            {headerText}
          </button>
        </h2>
      </div>

      {isOpen && (
        <div className="collapse show">
          <div className="card-body" css={{ overflowY: "scroll" }}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
}

export default Collapsible;
