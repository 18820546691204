/** @jsxImportSource @emotion/react */
import { createContext, useReducer, useContext } from "react";
import BenchmarkReducer from "./BenchmarkReducer";

const initialState = {
  answers: [
    // { question_index: 1, current: 0, desired: 0, importance: 0 },
  ],
  currentQuestionIndex: 0,
};

// context
const BenchmarkContext = createContext(initialState);

// Provider
export const BenchmarkProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BenchmarkReducer, initialState);

  //Actions
  function changeRating(question_index, rating, ratingName) {
    dispatch({
      type: "UPDATE_RATING",
      payload: {
        rating: rating,
        question_index: question_index,
        ratingName: ratingName,
      },
    });
  }

  function addAnswers(answers) {
    dispatch({
      type: "ADD_ANSWERS",
      payload: {
        answers: answers,
      },
    });
  }

  function getCurrentAnswer(question_index = state.currentQuestionIndex) {
    let answer = state.answers.find((a) => a.question_index === question_index);
    if (answer) return answer;

    return getHeadAnswer();
  }

  function getHeadAnswer() {
    if (state.answers && state.answers.length > 0) {
      return state.answers.reduce(function (prev, current) {
        return prev.question_index > current.question_index ? prev : current;
      });
    }
    return null;
  }

  const contextValue = {
    answers: state.answers,
    loaded: state.loaded,
    currentQuestionIndex: state.currentQuestionIndex,
    changeRating,
    addAnswers,
    dispatch,
    getCurrentAnswer,
    getHeadAnswer,
  };

  return (
    <BenchmarkContext.Provider value={contextValue}>
      {children}
    </BenchmarkContext.Provider>
  );
};

export function useBenchmark() {
  const context = useContext(BenchmarkContext);
  if (!context) {
    throw new Error(
      "useBenchmark must be used within a BenchmarkProvider. Wrap in <BenchmarkProvider> to fix this error."
    );
  }
  return context;
}
