/** @jsxImportSource @emotion/react */

import BenchmarkIntro from "components/benchmark/BenchmarkIntro";
import BenchmarkBase from "components/benchmark/BenchmarkBase";
import ThankYou from "../../components/benchmark/question/ThankYou";
import SampleCharts from "../../components/benchmark/misc/SampleCharts";
import React from "react";

function renderComponent(component, switchTo) {
  switch (component) {
    case "BenchmarkIntro":
      return <BenchmarkIntro switchTo={switchTo} />;
    case "BenchmarkBase":
      return <BenchmarkBase switchTo={switchTo} />;
    case "ThankYou":
      return <ThankYou switchTo={switchTo} />;
    case "SampleCharts":
      return (
        <SampleCharts
          switchTo={switchTo}
          pageHeading="Organisation Benchmarker"
          title=""
          images={[
            { url: "/img/charts/chart1.png", height: "204px", width: "828px" },
            { url: "/img/charts/chart2.png", height: "251px", width: "728px" },
          ]}
        />
      );
    default:
      return null;
  }
}

function Assessment() {
  const [component, setComponent] = React.useState("BenchmarkIntro");

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage:
          "linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url('/img/assessment_sm.png')",
          overflow: "hidden"
      }}
    >
      {renderComponent(component, setComponent)}
    </div>
  );
}

export default Assessment;
