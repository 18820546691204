/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation]);

export default function Slider({ slides, ...props }) {
  const theme = useTheme();

  return (
    <div
      {...props}
      css={{
        '.swiper-button-prev': {
          left: 0,
          width: 40,
          height: 40,
          transform: 'translateY(-30%)',
          backgroundColor: theme.colors.blue,
          ':after': {
            color: '#fff',
            fontSize: 14,
          },
        },
        '.swiper-button-next': {
          right: 0,
          width: 40,
          height: 40,
          transform: 'translateY(-30%)',
          backgroundColor: theme.colors.blue,
          ':after': {
            color: '#fff',
            fontSize: 14,
          },
        },
        '.swiper-button-disabled': {
          pointerEvents: 'all !important',
          cursor: 'pointer !important',
        },
        [theme.mediaQueries.md]: {
          '.swiper-button-prev': {
            left: 0,
            width: 60,
            height: 60,
            ':after': {
              fontSize: 24,
            },
          },
          '.swiper-button-next': {
            right: 0,
            width: 60,
            height: 60,
            ':after': {
              fontSize: 24,
            },
          },
        },
      }}>
      <Swiper
        slidesPerView={2.5}
        spaceBetween={20}
        navigation={true}
        breakpoints={{
          992: {
            slidesPerView: 3.5,
          },
        }}>
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
