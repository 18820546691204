/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import ReactTooltip from "react-tooltip";

import { Checkbox } from "components/learning-library/Filter";

function CompetencySection({
  questions,
  handleCategorySelect,
  generateQuestionId,
  setQuestionCheckboxState,
  handleQuestionSelect,
}) {
  const { colors } = useTheme();

  return (
    <div>
      {questions.map((category) => (
        <ul
          key={category.id}
          css={{
            color: colors.blue,
          }}
        >
          <Checkbox>
            <div css={{ display: "flex", alignItems: "baseline" }}>
              <input
                type="checkbox"
                checked={category.checked}
                onChange={() => {
                  category.checked = !category.checked;
                  handleCategorySelect(category);
                }}
                css={{
                  marginRight: "16px",
                  cursor: "pointer",
                }}
              />
              <h6 css={{ cursor: "pointer" }}>{category.label}</h6>
            </div>
          </Checkbox>
          {category.questionList.map((question) => (
            <li
              key={question.id}
              css={{
                display: "flex",
                fontSize: "14px",
                marginLeft: "16px",
                listStyle: "none",
              }}
            >
              <Checkbox>
                <div css={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    id={generateQuestionId(question)}
                    checked={setQuestionCheckboxState(question)}
                    onChange={() => {
                      question.checked = !question.checked;
                      handleQuestionSelect(category);
                    }}
                    css={{
                      marginRight: "16px",
                      cursor: "pointer",
                    }}
                    data-tip={
                      setQuestionCheckboxState(question) === undefined
                        ? "At least one of the selected users has this question selected and at least one has it unselected"
                        : null
                    }
                  />
                  <span
                    css={{
                      cursor: "pointer",
                      color: colors.blue,
                    }}
                  >
                    {question.label}
                  </span>
                  <ReactTooltip delayShow={100} multiline={true} />
                </div>
              </Checkbox>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
}

export default CompetencySection;
