import { addQuestionIdsToUsers, removeQuestionIdsFromUsers } from "../users";
import { QUESTION_TYPES_ENUM } from "../constants";

/**
 * Prepares expertise questions in the following format:
 * Unlike competency questions, expertise questions don't have category
 *
 * {
 *  id: string,
 *  label: string,
 *  checked: boolean
 * }
 *
 * @param {*} questions initial array with expertise questions
 * @param {*} checked flag for checking a single question
 * @param {*} users users array which needs to be updated with question ids
 * @returns object in the following format:
 * {
 *  questions: array with all expertise questions in the required format
 *  users: array with all users which have array updated with all checked/unchecked questions
 * }
 */
export function setExpertise(questions, checked, users = []) {
  let updatedUsers;

  const updatedListWithQuestions = questions.map((question) => {
    delete question.hint;
    question.checked = checked;

    if (question.checked) {
      updatedUsers = addQuestionIdsToUsers(
        users,
        question.id,
        QUESTION_TYPES_ENUM.skills
      );
    } else {
      updatedUsers = removeQuestionIdsFromUsers(
        users,
        question.id,
        QUESTION_TYPES_ENUM.skills
      );
    }
    return question;
  });

  return {
    questions: updatedListWithQuestions,
    users: updatedUsers,
  };
}

/**
 * Updates expertise questions after user interaction in the UI
 *
 * @param {*} questions array with expertise questions
 * @param {*} users users array which needs to be updated with question ids
 * @returns object in the following format:
 * {
 *  questions: array with all expertise questions updated
 *  users: array with all users which have array updated with checked/unchecked questions
 *  isAllExpertiseChecked: boolean flag which indicates if select all checkbox should be checked or unchecked
 * }
 */
export function updateExpertise(questions, users) {
  let updatedUsers;
  let isAllExpertiseChecked = true;

  const updatedListWithQuestions = questions.map((question) => {
    if (question.checked) {
      updatedUsers = addQuestionIdsToUsers(
        users,
        question.id,
        QUESTION_TYPES_ENUM.skills
      );
    } else {
      isAllExpertiseChecked = false;

      updatedUsers = removeQuestionIdsFromUsers(
        users,
        question.id,
        QUESTION_TYPES_ENUM.skills
      );
    }

    return question;
  });

  return {
    questions: updatedListWithQuestions,
    users: updatedUsers,
    isAllExpertiseChecked: isAllExpertiseChecked,
  };
}
