import "bootstrap/dist/css/bootstrap-reboot.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import { settings } from "services/BenchmarkService";

ReactDOM.render(
  <Auth0Provider
    domain={settings.auth0Domain}
    clientId={settings.auth0ClientId}
    redirectUri={window.location.origin}
    audience={settings.auth0audience}
    scope={settings.auth0scope}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
