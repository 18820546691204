/** @jsxImportSource @emotion/react */

function RowsPerPageDropdown({ rowsPerPageOptions, handleRowsPerPageSelect }) {
  function onChange(event) {
    handleRowsPerPageSelect(event.target.value);
  }

  return (
    <div>
      {rowsPerPageOptions && rowsPerPageOptions.length > 0 && (
        <label>
          <span
            css={{
              fontSize: "14px",
            }}
          >
            Rows per page:
          </span>
          <select
            css={{
              marginLeft: "8px",
            }}
            onChange={onChange}
          >
            {rowsPerPageOptions.map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        </label>
      )}
    </div>
  );
}

export default RowsPerPageDropdown;
