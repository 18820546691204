import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import useUserData from "../auth/useUserData";
import { Container } from "Theme";
import { Spinner } from "./Spinner";
import { settings, routes } from "../services/BenchmarkService";
const navRoutes = [
  {
    label: "Home",
    to: routes.dashboard,
  },
];

const NavContainer = styled.nav`
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.darkestblue};

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      position: static;
      background-color: transparent;
      padding: 0;
    }
  `}
`;

const NavList = styled.ul`
  margin: 0 auto;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  align-items: center;

  li {
    flex: 1;
  }
`;

const NavLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  color: ${({ theme }) => theme.colors.lightGray};
  white-space: nowrap;
  padding: 15px 20px;
  text-align: center;
  display: block;
  position: relative;
  font-size: 12px;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme, isActive }) =>
    isActive &&
    `
    color: ${theme.colors.white};
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: ${theme.colors.white};
    }
    `}

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      color: ${({ theme }) => theme.colors.white};
      padding: 0 20px;
      font-size: 14px;
      &:after {
        display: none;
      }
      &:hover {
        text-decoration: none;
        color: ${theme.colors.yellow};
      }
    }
  `}
`;

function MainNav() {
  const location = useLocation();
  const history = useHistory();
  const userData = useUserData(history.location.pathname);

  if (!userData) {
    return <Spinner></Spinner>;
  }
  let visibleNavItems = navRoutes.filter(
    (i) => settings.allowedRoutesForUsers.includes(i.to) || userData.isAdmin
  );

  return (
    <NavContainer>
      <Container>
        <NavList>
          {visibleNavItems.map((route) => (
            <li key={route.label}>
              <NavLink to={route.to} isActive={route.to === location.pathname}>
                {route.label}
              </NavLink>
            </li>
          ))}
        </NavList>
      </Container>
    </NavContainer>
  );
}

export default MainNav;
