/** @jsxImportSource @emotion/react */

import { Modal, ModalBody } from 'components/Modal';
import { PrimaryButton, theme } from 'Theme';
import React, { useState } from 'react';

import Header from 'components/Header';
import { Link } from 'react-router-dom';
import { modalQuestions } from '../fixtures/modalQuestions'
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

// Components



// const QuoteSection = styled.div`
//   border-top: 1px solid ${theme.colors.lightGray};
//   border-bottom: 1px solid ${theme.colors.lightGray};
//   display: flex;
//   flex-direction:column;
//   align-items:center;
// `;

const OpaqueContainer = styled.div`
  max-width: 720px;
  width: 100%;
  padding: 15px;
  margin: 5% auto;
  background-color:${theme.colors.blackblueop};
  opacity:0.85
`;

const ModalBodyContainer = styled.div`
  color:${theme.colors.white};
  background-color:${theme.colors.blackblue};
`;

export default function SingleCourseDetails() {
  const [modal, setModal] = useState(false);
  const [btnTxt, setBtnTxt] = useState('Tell me how I did');
  const [hasAnswered, setHasAnswered] = useState(false);

  const theme = useTheme();

  const handleAssessment = () => {
    setModal(true);
  }
  const handleSubmit = () => {
    setBtnTxt('Click here to re-take the course and reinforce learning')
    setHasAnswered(true);
    if (hasAnswered) {
      handleClose();
    }
  }
  const handleClose = () => {
    setModal(false);
    setBtnTxt('Tell me how I did');
    setHasAnswered(false);
  }

  return (
    <div css={{
      flex: 1,
      paddingBottom: 60,
      backgroundImage: 'url(/img/course_details.png)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      color: theme.colors.white,
      //opacity:0.85
    }}>
      <Header />
      <OpaqueContainer>
        <h3
          css={{
            marginBottom: 20,
            fontWeight: 400,
            fontSize: 20,
            textDecoration: 'underline'
          }}>
          Meet the Digital Shelf
        </h3>

        <p css={{ textDecoration: 'underline', color: theme.colors.yellow }}>Quick summary</p>
        <p>How to apply Brilliant Basics knowledge for converting shoppers at the Digital Shelf</p>
        <div style={{ height: '20px' }}></div>
        <p css={{ textDecoration: 'underline', color: theme.colors.yellow }}>Quick links</p>
        <p>What the key building blocks are</p>
        <p>Why it is so important</p>
        <p>What good looks like and how you measure success</p>
        <p>Who needs to own it and drive it</p>
        <p>Let's make it happen</p>
        <div style={{ height: '20px' }}></div>
        <p css={{ textDecoration: 'underline', color: theme.colors.yellow }}>Refresher</p>
        <p onClick={handleAssessment} style={{ cursor: 'pointer' }}>Watch the Meet the Digital Shelf Skill Pill</p>

        <div style={{ borderBottom: `1px solid ${theme.colors.lightGray}` }}></div>
        <div style={{ marginTop: 10 }}>
          <Link style={{ color: theme.colors.white }} to='/learning-library'>Return to the learning library</Link>
        </div>

      </OpaqueContainer>


      <Modal isOpen={modal}>
        <ModalBody>
          <ModalBodyContainer>
            {/*             <button style={{
              border:'none', 
              backgroundColor: theme.colors.blackblue,
              color: theme.colors.white,
              paddingBottom:10,
              paddingLeft:0
            }} onClick={handleClose}>Close</button> */}
            <p>You recently viewed the Meet the Digital Shelf Skill Pill. Let’s do a little quiz to see what you remember.</p>

            {!hasAnswered && modalQuestions.map(mq => (
              <div style={{
                backgroundColor: theme.colors.white,
                color: theme.colors.blackblue,
                marginBottom: 15,
                padding: 15
              }}>
                <p>{mq.question}</p>
                {mq.options.map(option => (
                  <div>
                    <input id={mq.questionId} type="radio" value={option} name={mq.questionName} style={{ marginRight: 10 }} />
                    <label>{option}</label>
                  </div>
                ))}
              </div>
            ))}

            {hasAnswered && modalQuestions.map(mq => (
              <div style={{
                backgroundColor: theme.colors.white,
                marginBottom: 15,
                padding: 15,
                color: theme.colors.blackblue
              }}>
                <p>{mq.question}</p>
                {mq.questionId === 1 &&
                  <p style={{ color: 'green' }}>You answered option 1 - that's correct. Title, tagging and description are the most important</p>}
                {mq.questionId === 2 &&
                  <p style={{ color: 'red' }}>You answered option 1 - that's incorrect. Everyone is responsible for the success of executing the digital shelf</p>}
              </div>

            ))}
            <PrimaryButton onClick={handleSubmit}>
              {btnTxt}
            </PrimaryButton>
          </ModalBodyContainer>

        </ModalBody>
      </Modal>
    </div>
  );
}