/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

import ProductivityNav from "./ProductivityNav";

const ProgressContainer = styled.div`
  display: none;

  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      display: inline-block;
      width: 282px;
    }
  `}
`;

function ProgressNav({ productivityList }) {
  return (
    <>
      <ProgressContainer>
        {productivityList.map((value, index) => {
          return (
            <ProductivityNav
              productivity={value}
              key={value.id}
              index={index + 1}
            ></ProductivityNav>
          );
        })}
      </ProgressContainer>
    </>
  );
}

export default ProgressNav;
