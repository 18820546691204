import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "components/Spinner";
function AuthWrapper({ children }) {
  const { isLoading, error } = useAuth0();

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  if (error) {
    return <div>Something went wrong. {error.message}</div>;
  }
  return <>{children}</>;
}
export default AuthWrapper;
