/**
 * Questions categories in the configure-users page
 */
export const QUESTION_TYPES = {
  benchmark: "benchmark",
  skills: "skills",
  productivity: "productivity",
};

/**
 * Questions categories enum in the configure-users page
 */
export const QUESTION_TYPES_ENUM = {
  benchmark: 0,
  productivity: 1,
  skills: 2,
};

/**
 * Questions total length
 */
export const QUESTIONS_TOTAL_LENGTH = {
  benchmark: 24,
  skills: 10,
  productivity: 10,
};

/**
 * Rows per page dropdown default options
 */
export const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];
