/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

import { Container } from "Theme";
import Header from "components/Header";
import ActionsHeader from "components/configure-users/ActionsHeader";
import PaginatedList from "components/configure-users/lists/PaginatedList";
import QuestionsModal from "components/configure-users/modals/questions/QuestionsModal";
import {
  setQuestionsWithCategory,
  updateQuestionsWithCategory,
  updateCategory,
} from "components/configure-users/utils/questions/questions-with-category";
import {
  setExpertise,
  updateExpertise,
} from "components/configure-users/utils/questions/questions-without-category";
import { updateUsers } from "components/configure-users/utils/users";
import {
  QUESTIONS_TOTAL_LENGTH,
  QUESTION_TYPES,
  QUESTION_TYPES_ENUM,
  ROWS_PER_PAGE_OPTIONS,
} from "components/configure-users/utils/constants";
import DeleteUserModal from "components/configure-users/modals/users/DeleteUserModal";
import CreateUsersModal from "components/configure-users/modals/users/CreateUsersModal";
import { apiFetch } from "services/apiFetch";
import { apiRoutes } from "services/BenchmarkService";

// import benchmark, skills and productivity questions from resources.json
import {
  competencyList,
  questionsList,
  productivityList,
} from "../resources.json";

function ConfigureUsers() {
  const { colors, containerSmallWidth } = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const [deleteError, setDeleteError] = useState();

  // Users state properties
  const [allUsers, setAllUsers] = useState([]);
  const [foundUsers, setFoundUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // selected users from the list
  const [itemsPerPage, setItemsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]); // pagination items per page (default 10)

  // Modal state properties
  const [isCreateUsersModalOpen, setIsCreateUsersModalOpen] = useState(false);
  const [createUsersModalError, setCreateUsersModalError] = useState(null);
  const [isQuestionsModalOpen, setIsQuestionsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  // Benchmark Questions section
  const [competencyQuestions, setCompetencyQuestions] = useState(
    setQuestionsWithCategory(
      competencyList,
      true,
      [],
      QUESTION_TYPES_ENUM.benchmark
    ).questions
  );
  const [competencyOpen, setCompetencyOpen] = useState(false);
  const [allCompetencySelected, setAllCompetencySelected] = useState(true);

  // Skills Questions section
  const [expertiseQuestions, setExpertiseQuestions] = useState(
    setExpertise(questionsList, true).questions
  );
  const [expertiseOpen, setExpertiseOpen] = useState(false);
  const [allExpertiseSelected, setAllExpertiseSelected] = useState(true);

  // Productivity Questions section
  const [productivityQuestions, setProductivityQuestions] = useState(
    setQuestionsWithCategory(
      productivityList,
      true,
      [],
      QUESTION_TYPES_ENUM.productivity
    ).questions
  );
  const [productivityOpen, setProductivityOpen] = useState(false);
  const [allProductivitySelected, setAllProductivitySelected] = useState(true);

  useEffect(() => {
    listAllUsers();
  }, []);

  function listAllUsers() {
    apiFetch({
      url: apiRoutes.listAllUsers,
      method: "GET",
    }).then((listAllUsersResponse) => {
      if (listAllUsersResponse.isSuccessful) {
        const orderedUsers = listAllUsersResponse.data.responses.sort(
          (a, b) => b.id - a.id
        );
        setFoundUsers(orderedUsers);
        setAllUsers(orderedUsers);
        setIsCreateUsersModalOpen(false);
        setIsDeleteModalOpen(false);
      }
    });
  }

  // set users based on single user selection
  function handleUpdateUser(updatedUser) {
    const updatedUsers = updateUsers(foundUsers, updatedUser);
    setFoundUsers(updatedUsers);
  }

  // set questions based on single question selection
  function handleQuestionSelect(questionType, updatedQuestion) {
    let result;
    switch (questionType) {
      case QUESTION_TYPES.benchmark:
        result = updateQuestionsWithCategory(
          competencyQuestions,
          updatedQuestion,
          foundUsers,
          QUESTION_TYPES_ENUM.benchmark
        );
        setCompetencyQuestions(result.questions);
        setAllCompetencySelected(result.isAllCategoriesChecked);
        break;
      case QUESTION_TYPES.skills:
        result = updateExpertise(expertiseQuestions, foundUsers);
        setExpertiseQuestions(result.questions);
        setAllExpertiseSelected(result.isAllExpertiseChecked);
        break;
      default:
        result = updateQuestionsWithCategory(
          productivityQuestions,
          updatedQuestion,
          foundUsers,
          QUESTION_TYPES_ENUM.productivity
        );
        setProductivityQuestions(result.questions);
        setAllProductivitySelected(result.isAllCategoriesChecked);
    }

    setFoundUsers(result.users);
  }

  // set questions based on question's category selection
  function handleCategorySelect(questionType, updatedQuestion) {
    let result;
    if (questionType === QUESTION_TYPES.benchmark) {
      result = updateCategory(
        competencyQuestions,
        updatedQuestion,
        foundUsers,
        QUESTION_TYPES_ENUM.benchmark
      );
      setCompetencyQuestions(result.questions);
      setAllCompetencySelected(result.isAllCategoriesChecked);
      setFoundUsers(result.users);
    } else {
      result = updateCategory(
        productivityQuestions,
        updatedQuestion,
        foundUsers,
        QUESTION_TYPES_ENUM.productivity
      );
      setProductivityQuestions(result.questions);
      setAllProductivitySelected(result.isAllCategoriesChecked);
      setFoundUsers(result.users);
    }
  }

  // set questions based on type of questions selection (select all by type)
  function handleSelectAllByType(questionType) {
    let result;

    switch (questionType) {
      case QUESTION_TYPES.benchmark:
        setAllCompetencySelected(!allCompetencySelected);
        result = setQuestionsWithCategory(
          competencyQuestions,
          !allCompetencySelected,
          foundUsers,
          QUESTION_TYPES_ENUM.benchmark
        );
        setCompetencyQuestions(result.questions);
        break;
      case QUESTION_TYPES.skills:
        setAllExpertiseSelected(!allExpertiseSelected);
        result = setExpertise(
          expertiseQuestions,
          !allExpertiseSelected,
          foundUsers
        );
        setExpertiseQuestions(result.questions);
        break;
      default:
        setAllProductivitySelected(!allProductivitySelected);
        result = setQuestionsWithCategory(
          productivityQuestions,
          !allProductivitySelected,
          foundUsers,
          QUESTION_TYPES_ENUM.productivity
        );
        setProductivityQuestions(result.questions);
    }

    setFoundUsers(result.users);
  }

  // create new users
  function handleCreateUsers(usersInput) {
    if (!usersInput) {
      setCreateUsersModalError("Please enter valid users");
      return;
    }

    setCreateUsersModalError(null);
    const newUsers = usersInput
      .trim()
      .split("\n")
      .map((user) => {
        return user.split(",");
      });

    let data = {
      users: [],
    };

    newUsers.forEach((user) => {
      data.users.push({
        email: user[0]?.trim(),
        firstname: user[1]?.trim(),
        lastname: user[2]?.trim(),
        password: user[3]?.trim(),
      });
    });

    apiFetch({
      url: apiRoutes.users,
      data: data,
      method: "POST",
    }).then((response) => {
      if (response.isSuccessful && response.data.errors.length === 0) {
        listAllUsers();
      } else {
        setCreateUsersModalError("Please enter valid users");
      }
    });
  }

  // delete user by id
  function handleDeleteUser(user) {
    apiFetch({
      url: `${apiRoutes.user}?userid=${user.id}`,
      method: "DELETE",
    }).then((response) => {
      if (response.isSuccessful) {
        setSearchValue("");
        listAllUsers();
        setIsDeleteModalOpen(!isDeleteModalOpen);
      } else {
        setDeleteError("Unexpected error when trying to delete this user!");
      }
    });
  }

  // opens modal with questions
  function openQuestionsModal() {
    let checkedUsers = foundUsers.filter((user) => user.checked);
    let checkedUsersIds = checkedUsers.map((user) => user.id);

    let url = `${apiRoutes.quizVisibilityUsers}?users=`;

    checkedUsersIds.forEach((id, index) => {
      if (index === checkedUsers.length - 1) {
        url += id;
      } else {
        url += `${id},`;
      }
    });

    apiFetch({
      url: url,
      method: "GET",
    }).then((response) => {
      if (response.isSuccessful) {
        checkedUsers = checkedUsers.map((user) => {
          const currentUser = response.data.response.find(
            (userData) => +userData.user === user.id
          );

          if (currentUser) {
            const benchmark = currentUser.quizzes.find(
              (quiz) => quiz.quizType === QUESTION_TYPES_ENUM.benchmark
            );

            user[QUESTION_TYPES_ENUM.benchmark] = benchmark?.questions || [];
            setAllCompetencySelected(
              benchmark?.questions?.length === QUESTIONS_TOTAL_LENGTH.benchmark
            );

            const skills = currentUser.quizzes.find(
              (quiz) => quiz.quizType === QUESTION_TYPES_ENUM.skills
            );

            user[QUESTION_TYPES_ENUM.skills] = skills?.questions || [];
            setAllExpertiseSelected(
              skills?.questions?.length === QUESTIONS_TOTAL_LENGTH.skills
            );

            const productivity = currentUser.quizzes.find(
              (quiz) => quiz.quizType === QUESTION_TYPES_ENUM.productivity
            );

            user[QUESTION_TYPES_ENUM.productivity] = [];
            user[QUESTION_TYPES_ENUM.productivity] =
              productivity?.questions || [];
            setAllProductivitySelected(
              productivity?.questions?.length ===
                QUESTIONS_TOTAL_LENGTH.productivity
            );
          }

          return user;
        });

        setSelectedUsers(checkedUsers);
        setIsQuestionsModalOpen(!isQuestionsModalOpen);
      }
    });
  }

  return (
    <div
      className="configure-questions"
      css={{
        flex: 1,
        backgroundImage: "url(/img/main.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Header />
      <Container
        css={{
          color: colors.white,
          backgroundColor: colors.blackblueop,
          overflow: "hidden",
          padding: "16px",
          marginTop: "48px",
          maxWidth: containerSmallWidth,
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ActionsHeader
            allUsers={allUsers}
            foundUsers={foundUsers}
            setFoundUsers={setFoundUsers}
            handleUsersModal={() => {
              setIsCreateUsersModalOpen(!isCreateUsersModalOpen);
            }}
            handleQuestionsModal={openQuestionsModal}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />

          {foundUsers && foundUsers.length > 0 && (
            <PaginatedList
              itemsPerPage={itemsPerPage}
              foundUsers={foundUsers}
              handleUpdateUser={handleUpdateUser}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              handleRowsPerPageSelect={(rowsPerPage) =>
                setItemsPerPage(+rowsPerPage)
              }
              deleteUser={(user) => {
                setUserToDelete(user);
                setDeleteError(null);
                setIsDeleteModalOpen(!isDeleteModalOpen);
              }}
            />
          )}
        </div>
      </Container>
      {selectedUsers && selectedUsers.length > 0 && (
        <QuestionsModal
          isOpen={isQuestionsModalOpen}
          setIsOpen={(isOpen) => {
            setIsQuestionsModalOpen(isOpen);
          }}
          competencyQuestions={competencyQuestions}
          competencyOpen={competencyOpen}
          setCompetencyOpen={setCompetencyOpen}
          allCompetencySelected={allCompetencySelected}
          expertiseQuestions={expertiseQuestions}
          expertiseOpen={expertiseOpen}
          setExpertiseOpen={setExpertiseOpen}
          allExpertiseSelected={allExpertiseSelected}
          productivityQuestions={productivityQuestions}
          productivityOpen={productivityOpen}
          setProductivityOpen={setProductivityOpen}
          allProductivitySelected={allProductivitySelected}
          handleQuestionSelect={handleQuestionSelect}
          handleCategorySelect={handleCategorySelect}
          handleSelectAllByType={handleSelectAllByType}
          users={selectedUsers}
        />
      )}
      {userToDelete && (
        <DeleteUserModal
          isOpen={isDeleteModalOpen}
          setIsOpen={(isOpen) => {
            setIsDeleteModalOpen(isOpen);
            setUserToDelete(null);
          }}
          user={userToDelete}
          deleteUser={handleDeleteUser}
          error={deleteError}
        />
      )}
      {isCreateUsersModalOpen && (
        <CreateUsersModal
          isOpen={isCreateUsersModalOpen}
          setIsOpen={(isOpen) => {
            setIsCreateUsersModalOpen(isOpen);
          }}
          handleCreateUsers={handleCreateUsers}
          createUsersModalError={createUsersModalError}
        />
      )}
      )
    </div>
  );
}

export default ConfigureUsers;
