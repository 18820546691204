/** @jsxImportSource @emotion/react */

// Components
import Header from 'components/Header';
import NewsList from 'components/NewsList';
import { Container, theme } from 'Theme';

const news = [
  {
    id: 1,
    image: '/img/latestnews/Little-Moons.jpg',
    title: 'Good Tik Tok Creative - Little Moons',
    excerpt:
      "Nice example of using Tik Tok to drive people into store to try a new product. Are all the elements of your marketing mix working well together to maximise traffic and trial? If you're not sure just drop us a line!",
    author: {
      avatar: 'https://via.placeholder.com/80',
      name: 'Michael Adams',
    },
    readingTime: '15 min',
    category: 'Lifestyle',
    link: 'https://youtu.be/OzZNG9pKciM',
  },
  {
    id: 2,
    image: '/img/latestnews/walmart-micro-fulfillment-center.jpg',
    title: "Walmart's latest strategy to get your stuff to you faster",
    excerpt:
      'Walmart bolting on automated fulfillment centres across its store estate which has a number of benefits for the retailer and the on and offline shopper (eg less people in the store picking orders).',
    author: {
      avatar: 'https://via.placeholder.com/80',
      name: 'Michael Adams',
    },
    readingTime: '15 min',
    category: 'Lifestyle',
    link:
      'https://edition.cnn.com/2021/01/27/business/walmart-online-grocery-warehouses',
  },
  {
    id: 3,
    image: '/img/latestnews/apple_data.png',
    title: 'A Day in the Life of Your Data',
    excerpt:
      'Interesting and accessible read from Apple explaining the benefits of their privacy settings, and what this means on a practical basis for access to you personal data throughout the day.',
    author: {
      avatar: 'https://via.placeholder.com/80',
      name: 'Michael Adams',
    },
    readingTime: '15 min',
    category: 'Lifestyle',
    link:
      'https://www.apple.com/privacy/docs/A_Day_in_the_Life_of_Your_Data.pdf',
  },
];

function News() {
  return (
    <div
      css={{
        flex: 1,
        backgroundImage: 'url(/img/latest_news.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        //backgroundAttachment: 'fixed',
      }}>
      <Header />
      <div css={{
        backgroundColor: theme.colors.blackblueop,
        marginTop: 70,
        marginBottom: 60
      }}>
        <Container>
          <NewsList items={news} />
        </Container>
      </div>

    </div>
  );
}

export default News;
