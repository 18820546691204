/** @jsxImportSource @emotion/react */

import { Container } from 'Theme';

import Header from 'components/Header';
import Slider from 'components/Slider';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

// Components

const ImageWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 75%;
  background-color: ${({ theme }) => theme.colors.silver};

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      padding-bottom: 66.66%;
    }
  `}
`;

const slidesData = [
  {
    id: 1,
    title: 'Joe Bloggs',
    image: '/img/experts/meet_the_experts1.png',
  },
  {
    id: 2,
    title: 'Jill Oneill',
    image: '/img/experts/meet_the_experts2.png',
  },
  {
    id: 3,
    title: 'Tom Davies',
    image: '/img/experts/meet_the_experts3.png',
  },
  {
    id: 4,
    title: 'Paul Waterson',
    image: '/img/experts/meet_the_experts4.png',
  },
];

const expertsSlidesData = [
  {
    id: 1,
    title: 'Marc Warburton',
    image: '/img/experts/expert_1.png',
  },
  {
    id: 2,
    title: 'Julia Lauritzen',
    image: '/img/experts/expert_2.png',
  },
  {
    id: 3,
    title: 'Sean Teehan',
    image: '/img/experts/expert_3.png',
  },
  {
    id: 4,
    title: 'David Bone',
    image: '/img/experts/expert_4.png',
  },
];

// const articles = [
//   {
//     id: 1,
//     title: 'Working with one of our mentors to help you grow',
//     text:
//       'Mentoring sessions can be booked with experts in the Daedal team if further support is required',
//     image: '/img/puzzle.jpg',
//     button: {
//       link: '#',
//       label: 'Find the perfect mentor for you',
//     },
//   },
//   /* {
//     id: 2,
//     title:
//       'Have you got a specific challenge that one of our on demand experts could help you with?',
//     text:
//       'Lorem ipsum dolor sit amet, consectetur adipscing elit. Fusce convallis pellentesque metus id Iacinia. Nunc dapibus pulvinar auctor.',
//     image: '/img/banner_image.jpg',
//     button: {
//       link: '#',
//       label: 'Tell us what you need',
//     },
//   }, */
// ];

function Experts() {
  const theme = useTheme();

  const slides = slidesData.map((slide) => (
    <div key={slide.id}>
      <ImageWrapper>
        <img src={slide.image} alt='' />
      </ImageWrapper>

      <p
        css={{
          fontSize: 13,
          padding: '0 15px',
          margin: 0,
          [theme.mediaQueries.md]: {
            fontSize: 16,
          },
        }}>
        {slide.title}
      </p>
    </div>
  ));

  const expertSlides = expertsSlidesData.map((slide) => (
    <div key={slide.id}>
      <ImageWrapper>
        <img src={slide.image} alt='' />
      </ImageWrapper>

      <p
        css={{
          fontSize: 13,
          padding: '0 15px',
          margin: 0,
          [theme.mediaQueries.md]: {
            fontSize: 16,
          },
        }}>
        {slide.title}
      </p>
    </div>
  ));


  return (
    <div
      css={{
        flex: 1,
        backgroundImage: 'url(/img/main.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
      <Header />

      <Container css={{ paddingTop: 20, paddingBottom: 50 }}>
        <div
          css={{
            marginBottom: 20,
            color: theme.colors.white,
          }}>

          <h4 css={{ fontSize: '22px' }}>Meet the experts in your organisation</h4>

          <div css={{
            padding: '15px 15px',
            backgroundColor: theme.colors.blackblueop,
            overflow: "hidden"
          }}>

            <p>
              These internal SMEs are available now to help mentor you within your target areas of learning
            </p>

            <Slider
              css={{
                margin: '0 -20px',
                [theme.mediaQueries.md]: {
                  margin: 0,
                },
              }}
              slides={slides}
            />
          </div>

        </div>


        <div
          css={{
            marginBottom: 20,
            color: theme.colors.white,
          }}>

          <h4 css={{ fontSize: '22px' }}>Working with one of our mentors to help you grow</h4>

          <div css={{
            padding: '15px 15px',
            backgroundColor: theme.colors.blackblueop
          }}>

            <p>
              Mentoring sessions can be booked with experts in the Daedal team if further support is required
            </p>

            <Slider
              css={{
                margin: '0 -20px',
                [theme.mediaQueries.md]: {
                  margin: 0,
                },
              }}
              slides={expertSlides}
            />
          </div>

        </div>

        {/* {articles.map((article) => (
          <div 
            css={{ 
              marginBottom: 20,
              color: theme.colors.white,
               }}>
            <h4 css={{fontSize: '22px'}}>{article.title}</h4>

            <div css={{
              padding: '15px 15px',
              backgroundColor: theme.colors.blackblueop
            }}>
              <p>{article.text}</p>

              <img
                css={{ maxWidth: '100%', marginBottom: 20 }}
                src={article.image}
                alt=''
              />

              {article.button.link && article.button.label && (
                <PrimaryButton css={{ width: '100%' }}>
                  {article.button.label}
                </PrimaryButton>
              )}
            </div>
          </div>
        ))} */}

      </Container>
    </div>
  );
}

export default Experts;
