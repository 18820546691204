/** @jsxImportSource @emotion/react */

import { Container } from "Theme";
import Header from "components/Header";
import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { routes } from "services/BenchmarkService";

// Components

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ListItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  padding: 20px 0;

  &:last-of-type {
    border-bottom: none;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      padding: 30px 0;
    }
  `}
`;

const ListItemBody = styled.div`
  display: flex;
  align-items: center;
`;

const ListItemAside = styled.a`
  flex: 0 0 100px;
  max-width: 100px;

  img {
    max-width: 100%;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
     flex: 0 0 150px;
     max-width: 150px;
    }
  `}
`;

const ListItemContent = styled.div`
  flex: 1;
  padding-left: 20px;

  p {
    margin-bottom: 0;
    font-size: 12px;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
     p {
       font-size: 14px;
     }
    }
  `}
`;

const items = [
  {
    id: 1,
    image: "/img/benchmarking/organization_benchmarker.png",
    title: "Organisation Benchmarker",
    excerpt:
      "How your organisation benchmarks against the eight core business competencies and twenty four elements. Track changes over time.",
  },
  {
    id: 2,
    image: "/img/benchmarking/dashboard.png",
    title: "Dashboard",
    excerpt: "At a glance understanding of Learning content uptake and impact.",
  },
  {
    id: 3,
    image: "/img/benchmarking/dBUG_overview.png",
    title: "d-BUG Overview",
    excerpt: "Platform introduction, rationale and vision.",
  },
];

export default function CorporateModules() {
  const theme = useTheme();

  return (
    <div
      css={{
        flex: 1,
        backgroundImage: "url(/img/main.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Header />

      <Container
        css={{
          backgroundColor: theme.colors.blackblueop,
          marginTop: 50,
        }}
      >
        <ListWrapper>
          {items.map((item) => (
            <ListItem key={item.id}>
              <Link to={routes.organizationalAssessment}>
                <ListItemBody>
                  {item.image && (
                    <ListItemAside href="#">
                      <img src={item.image} alt={item.title} />
                    </ListItemAside>
                  )}
                  <ListItemContent>
                    {item.title && (
                      <h3 css={{ color: theme.colors.yellow }}>{item.title}</h3>
                    )}

                    {item.excerpt && (
                      <p css={{ color: theme.colors.white }}>{item.excerpt}</p>
                    )}
                  </ListItemContent>
                </ListItemBody>
              </Link>
            </ListItem>
          ))}
        </ListWrapper>
      </Container>
    </div>
  );
}
