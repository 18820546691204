/** @jsxImportSource @emotion/react */
import {
  BackButton,
  ContinueButton,
  MainContainer,
  PageHeading,
  theme,
} from "Theme";
import styled from "@emotion/styled";
import {
  addAnswer,
  routes,
  settings,
} from "../../../services/BenchmarkService";
import { useAuth0 } from "@auth0/auth0-react";
import { QUESTION_TYPES_ENUM } from "components/configure-users/utils/constants";
import { Link } from "react-router-dom";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  .btn-holder {
    align-self: flex-end;
  }
`;

export const Container = styled.div`
  ${({ theme }) => `
${theme.mediaQueries.lg} {
  .success-checkmark {
    width: 80px;
    height: 110px;
    margin: 70px auto;

    .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #4caf50;

      &::before {
        top: 3px;
        left: -2px;
        width: 30px;
        transform-origin: 100% 50%;
        border-radius: 100px 0 0 100px;
      }

      &::after {
        top: 0;
        left: 30px;
        width: 60px;
        transform-origin: 0 50%;
        border-radius: 0 100px 100px 0;
        animation: rotate-circle 4.25s ease-in;
      }

      &::before,
      &::after {
        content: "";
        height: 100px;
        position: absolute;
        background: ${theme.colors.benchBaseBlue};
        transform: rotate(-45deg);
      }

      .icon-line {
        height: 5px;
        background-color: #4caf50;
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 10;

        &.line-tip {
          top: 46px;
          left: 14px;
          width: 25px;
          transform: rotate(45deg);
          animation: icon-line-tip 0.75s;
        }

        &.line-long {
          top: 38px;
          right: 8px;
          width: 47px;
          transform: rotate(-45deg);
          animation: icon-line-long 0.75s;
        }
      }

      .icon-circle {
        top: -4px;
        left: -4px;
        z-index: 10;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        box-sizing: content-box;
        border: 4px solid rgba(76, 175, 80, 0.5);
      }

      .icon-fix {
        top: 8px;
        width: 5px;
        left: 26px;
        z-index: 1;
        height: 85px;
        position: absolute;
        transform: rotate(-45deg);
        background-color: ${theme.colors.benchBaseBlue};
      }
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
}
`}
`;

function ThankYou({ switchTo }) {
  function resetQuiz() {
    let res = {};
    res.responses = [];
    addAnswer(res, QUESTION_TYPES_ENUM.benchmark);
    switchTo("BenchmarkBase");
  }

  const { logout } = useAuth0();
  function logoutAndClear() {
    sessionStorage.removeItem(settings.keyUserData);
    sessionStorage.removeItem(settings.keyAccessToken);
    sessionStorage.removeItem(settings.keyProfilePopulated);
    logout({ returnTo: window.location.origin });
  }

  return (
    <Container>
      <PageHeading>Success!</PageHeading>
      <MainContainer css={{ minHeight: "264px", color: theme.colors.white }}>
        <h3 css={{ textAlign: "center" }}>
          You have successfully completed the benchmark.
        </h3>
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </div>
        <ButtonContainer>
          <div className="btn-holder">
            <Link to={routes.dashboard}>
              <ContinueButton>Return to Home Screen</ContinueButton>
            </Link>
            <BackButton css={{ margin: "0 5px" }} onClick={resetQuiz}>
              Start Over
            </BackButton>
            <ContinueButton onClick={logoutAndClear}>Logout</ContinueButton>
          </div>
        </ButtonContainer>
      </MainContainer>
    </Container>
  );
}

export default ThankYou;
