/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import { Container, PrimaryButton } from "Theme";

export const Checkbox = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    margin-left: 20px;
  }
`;

export default function Filter({
  filters,
  selectedFilters,
  setSelectedFilters,
}) {
  const [open, setOpen] = useState(false);
  const [competencyFilters, setCompetencyFilters] = useState(
    selectedFilters.categories
  );
  const [typeFilters, setTypeFilters] = useState(selectedFilters.types);
  const theme = useTheme();

  function handleCompetencyCheck(e) {
    const { value, checked } = e.target;

    if (checked) {
      setCompetencyFilters([...competencyFilters, value]);
    } else {
      setCompetencyFilters(
        competencyFilters.filter((filter) => filter !== value)
      );
    }
  }

  function handleTypeCheck(e) {
    const { value, checked } = e.target;

    if (checked) {
      setTypeFilters([...typeFilters, value]);
    } else {
      setTypeFilters(typeFilters.filter((filter) => filter !== value));
    }
  }

  function handleFilter() {
    setOpen(false);
    setSelectedFilters({
      categories: competencyFilters,
      types: typeFilters,
    });
  }

  useEffect(() => {
    setCompetencyFilters(selectedFilters.categories);
    setTypeFilters(selectedFilters.types);
  }, [selectedFilters]);

  return (
    <div
      css={{
        maxWidth: "100%",
        [theme.mediaQueries.md]: {
          maxWidth: 400,
          marginLeft: "calc(50vw - 480px)",
        },
      }}
    >
      <Container
        css={{ position: "relative", zIndex: 10, margin: "20px auto" }}
      >
        {open && (
          <div
            css={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: theme.colors.white,
              opacity: 0.7,
            }}
          ></div>
        )}

        <PrimaryButton
          onClick={() => setOpen(!open)}
          css={{
            width: "100%",
            position: "relative",
            span: {
              width: "40px",
              height: "100%",
              color: theme.colors.white,
              backgroundColor: theme.colors.silver,
              position: "absolute",
              zIndex: 10,
              top: 0,
              right: 0,
              lineHeight: "40px",
              textAlign: "center",
              pointerEvents: "none",
            },
          }}
        >
          Filter
          <span>{open ? <FaChevronUp /> : <FaChevronDown />}</span>
        </PrimaryButton>

        <div
          css={{
            opacity: open ? 1 : 0,
            visibility: open ? "visible" : "hidden",
            position: "absolute",
            top: 40,
            left: 15,
            right: 15,
            backgroundColor: theme.colors.white,
            padding: "20px 0",
          }}
        >
          <div css={{ display: "flex", justifyContent: "center" }}>
            <div
              css={{
                padding: "0 8px",
                marginRight: 20,
              }}
            >
              <p css={{ textAlign: "center" }}>Competency</p>

              <Checkbox>
                <span>All</span>
                <input
                  type="checkbox"
                  name="all-competency"
                  checked={competencyFilters.length === 0}
                  onChange={() => setCompetencyFilters([])}
                />
              </Checkbox>

              {filters.competency.map((filter) => (
                <Checkbox key={filter}>
                  <span>{filter}</span>

                  <input
                    type="checkbox"
                    name={filter}
                    checked={competencyFilters.includes(filter)}
                    onChange={handleCompetencyCheck}
                    value={filter}
                  />
                </Checkbox>
              ))}
            </div>

            <div
              css={{
                padding: "0 8px",
              }}
            >
              <p css={{ textAlign: "center" }}>Type of learning</p>

              <Checkbox>
                <span>All</span>
                <input
                  type="checkbox"
                  name="all-type"
                  checked={typeFilters.length === 0}
                  onChange={() => setTypeFilters([])}
                />
              </Checkbox>

              {filters.typeOfLearning.map((filter) => (
                <Checkbox key={filter}>
                  <span>{filter}</span>
                  <input
                    type="checkbox"
                    name={filter}
                    checked={typeFilters.includes(filter)}
                    onChange={handleTypeCheck}
                    value={filter}
                  />
                </Checkbox>
              ))}
            </div>
          </div>

          <PrimaryButton
            css={{ width: "100%", marginTop: 20 }}
            onClick={handleFilter}
          >
            Go!
          </PrimaryButton>
        </div>
      </Container>
    </div>
  );
}
