/** @jsxImportSource @emotion/react */
import { useEffect } from "react";

import { MainContainer, PageHeading } from "Theme";
import ProgressNav from "./progress/ProgressNav";
import QuestionPanel from "./question/QuestionPanel";
import { Spinner } from "components/Spinner";
import { useProductivity } from "./state/ProductivityContext";

function OrganizationalProductivity({
  fetchedAnswers,
  productivityList,
  switchTo,
  currentQuestionIndex,
}) {
  const { answers, dispatch, loaded, addAnswers } = useProductivity();
  useEffect(() => {
    async function init() {
      if (!loaded) {
        addAnswers(fetchedAnswers);
      }
    }
    init();
  }, [addAnswers, answers, dispatch, fetchedAnswers, loaded]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_QUESTION_ID",
      payload: { currentQuestionIndex: currentQuestionIndex },
    });
  }, []);

  if (!loaded || !answers) return <Spinner></Spinner>;

  return (
    <>
      <PageHeading>Productivity Assessor</PageHeading>
      <MainContainer>
        <ProgressNav
          productivityList={productivityList}
          switchTo={switchTo}
        ></ProgressNav>
        <QuestionPanel
          productivityList={productivityList}
          switchTo={switchTo}
          currentQuestionIndex={currentQuestionIndex}
        ></QuestionPanel>
      </MainContainer>
    </>
  );
}

export default OrganizationalProductivity;
