/** @jsxImportSource @emotion/react */
import ReactTooltip from "react-tooltip";

import { PrimaryButton } from "Theme";

function ActionsHeader({
  allUsers,
  foundUsers,
  setFoundUsers,
  handleUsersModal,
  handleQuestionsModal,
  searchValue,
  setSearchValue,
}) {
  // filter users based on search field value
  const filterUsers = (event) => {
    const searchKeyword = event.target.value;

    if (searchKeyword) {
      const results = allUsers.filter((user) => {
        // case insensitive with toLowerCase()
        return user.email.toLowerCase().includes(searchKeyword.toLowerCase());
      });
      // show users based on search field value
      setFoundUsers(results);
    } else {
      // show all users when search field value is empty
      setFoundUsers(allUsers);
    }

    setSearchValue(searchKeyword);
  };

  // disable Select Questions button when there are no selected users
  function selectQuestionsDisabled() {
    return foundUsers.filter((user) => user.checked).length === 0;
  }

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        css={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-end",
          width: "80%",
        }}
      >
        <PrimaryButton
          disabled={false}
          onClick={handleUsersModal}
          css={{
            marginLeft: "8px",
          }}
        >
          Create Users
        </PrimaryButton>
        <span
          data-tip={
            selectQuestionsDisabled()
              ? "Please select at least one user to proceed with select questions"
              : null
          }
        >
          <PrimaryButton
            disabled={selectQuestionsDisabled()}
            onClick={handleQuestionsModal}
            css={{
              marginLeft: "8px",
            }}
          >
            Select Questions
          </PrimaryButton>
          <ReactTooltip delayShow={100} multiline={true} />
        </span>
      </div>
      <input
        type="search"
        value={searchValue}
        onChange={filterUsers}
        placeholder="Search by email address"
        css={{
          padding: "4px 12px",
          width: "80%",
        }}
      />
    </div>
  );
}

export default ActionsHeader;
