import React from "react";
import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";

export const theme = {
  mainContainerWidth: "1024px",
  // mainContainerWidthMobile: "667px",
  containerSmallWidth: "578px",
  containerWidth: "610px",
  colors: {
    blue: "#0469AB",

    lighterblue: "#A1C7DF",
    lightestblue: "#C0D9EA",
    blueop: "rgba(4, 92, 150, 0.4)",
    darkblue: "#03426B",
    darkerblue: "#023555",
    darkerblueop: "rgba(3, 66, 107, 0.9)",
    darkestblue: "#022740",
    blackblue: "#010D15",
    blackblueop: "rgba(1, 13, 21, 0.8)",
    orange: "#F6291E",
    lightorange: "#FF8E00",
    green: "#16AB04",
    silver: "#C1BEC1",
    darkGray: "#333",
    gray: "#999",

    yellow: "#FFBC00",
    lightGray: "#eee",
    white: "#fff",
    black: "#000",
    lightblue: "#62A1CA",
    red: "#ff0000",

    benchFadeWhite: "#dee1e3",

    benchGray: "#4a4a4a",
    benchMidGray: "#c3c5c7",
    benchDoneGray: "#9b9b9b",

    benchActiveBlue: "#1669ab",
    benchBlue: "rgba(22, 105, 171, 0.5)",
    benchBaseBlue: "rgba(4, 39, 64, 0.9)",
    benchMainBlue: "#093d66",
    benchTransparent: "#00000000",
    benchBluePill: "#15578b",
    benchLightGreen: "#b8e986",
    // benchUnk1: "#23465b",
    // benchUnk2: "#042740",
  },
  mediaQueries: {
    xxl: "@media (min-width: 1400px)",
    lg: "@media (min-width: 1024px)",
    md: "@media (min-width: 992px)",
    sm: "@media (min-width: 768px)",
  },
};

export const Container = styled.div`
  max-width: ${theme.containerWidth};
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
`;

export const Button = styled.button`
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  min-height: 40px;
  padding: 5px 10px;
  text-align: center;
`;

export const PrimaryButton = styled(Button)`
  background-color: ${theme.colors.blue};
  color: ${theme.colors.white};

  &:disabled {
    opacity: 0.5;
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: ${theme.colors.white};
  color: ${theme.colors.blue};

  &:disabled {
    opacity: 0.5;
  }
`;

const RoundButton = styled(Button)`
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const PrimaryRoundButton = styled(RoundButton)`
  width: 40px;
  height: 20px;
  background-color: ${theme.colors.blue};
  color: ${theme.colors.white};
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  height: 40px;
  padding: 0 10px;
  margin-bottom: 20px;
  border-radius: 2px;

  &::placeholder {
    color: ${theme.colors.silver};
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  background-color: ${theme.colors.white};

  select {
    width: 100%;
    border: none;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    appearance: none;
    &::-ms-expand {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 48%;
    right: 10px;
    width: 10px;
    height: 10px;
    border: 2px solid #000;
    border-color: ${theme.colors.darkGray} ${theme.colors.darkGray} transparent
      transparent;
    transform: translateY(-50%) rotate(135deg);
  }
`;

export const MainContainer = styled.div`
  background-color: ${theme.colors.benchTransparent};
  width: ${theme.mainContainerWidthMobile};
  margin: 0 auto;
  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      width: 100%;
      padding: 15px;
      margin: 21px auto;
      padding: 8px;
      max-width: ${theme.mainContainerWidth};
      background-color: ${theme.colors.benchBaseBlue};
    }
  `}
`;

export const ContainerSmall = styled.div`
  margin: 0 10px 80px 10px;
`;

export const Oval = styled.div`
  background-color: ${theme.colors.blue};
  color: ${theme.colors.benchFadeWhite};
  display: inline-block;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
  min-height: 0;
  text-align: center;
  line-height: 1.6em;
`;

export const SecondaryLink = styled.a`
  color: ${theme.colors.benchFadeWhite};
  margin: 5px 10px;
  font-family: Cabin;
  font-size: 15px;
  font-weight: normal;
  text-decoration: underline;
  display: block;
  &:hover {
    color: ${theme.colors.white};
  }
`;

export const QuestionCard = styled.div`
  background-color: ${theme.colors.benchMainBlue};
  color: ${theme.colors.white};
  padding: 17px;
  font-family: Cabin;
  font-size: 15px;
  font-weight: normal;
  width: 200px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin: 5px;

  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      width: 232px;
    }
  `}
`;

export const PageHeading = styled.h2`
  display: none;

  font-family: Cabin;
  font-weight: 600;
  text-align: center;
  color: ${theme.colors.white};
  font-size: 27px;
  margin-top: 27px;

  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      display: block;
    }
  `}
`;
export const ShowOnMobile = styled.div`
  display: block;

  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      display: none;
    }
  `}
`;

export const ShowOnDesktop = styled.div`
  display: none;

  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      display: block;
    }
  `}
`;

export const ContinueButton = styled(Button)`
  background-color: ${theme.colors.blue};
  color: ${theme.colors.white};
  width: 200px;
  margin: 10px 0 0 5px;

  &:disabled {
    border: 1px solid ${theme.colors.benchDoneGray};
    color: ${theme.colors.benchDoneGray};
    background-color: rgba(0, 0, 0, 0.3);
  }

  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      width: 250px;
    }
  `}
`;

export const BackButton = styled(Button)`
  background-color: ${theme.colors.benchMidGray};
  color: ${theme.colors.black};
  width: 140px;
  margin: 0 5px 0 10px;

  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      width: 165px;
      margin: 20px 10px 0 10px;
    }
  `}
`;

export const ButtonContainer = styled.div`
  float: right;

  .button {
    margin-bottom: 0;
  }
  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      margin: 0px 0 0 0;
    }
  `}
`;
export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
