/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";

import ProductivityIntro from "components/productivity/ProductivityIntro";
import ProductivityBase from "components/productivity/ProductivityBase";
import ThankYou from "components/productivity/question/ThankYou";
import SampleCharts from "components/benchmark/misc/SampleCharts";

function renderComponent(component, switchTo) {
  switch (component) {
    case "ProductivityIntro":
      return <ProductivityIntro switchTo={switchTo} />;
    case "ProductivityBase":
      return <ProductivityBase switchTo={switchTo} />;
    case "ThankYou":
      return <ThankYou switchTo={switchTo} />;
    case "SampleCharts":
      return (
        <SampleCharts
          switchTo={switchTo}
          pageHeading="Organisation Benchmarker"
          title=""
          images={[
            { url: "/img/charts/chart1.png", height: "204px", width: "828px" },
            { url: "/img/charts/chart2.png", height: "251px", width: "728px" },
          ]}
        />
      );
    default:
      return null;
  }
}

function Assessment({ location }) {
  const [component, setComponent] = React.useState(
    location?.state?.questions ? "ProductivityBase" : "ProductivityIntro"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage:
          "linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url('/img/assessment_sm.png')",
        overflow: "hidden",
      }}
    >
      {renderComponent(component, setComponent)}
    </div>
  );
}

export default Assessment;
