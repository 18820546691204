/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { useTheme } from "@emotion/react";
import { theme } from "Theme";
import { PrimaryButton, ContainerSmall, PageHeading } from "Theme";
import Header from "components/Header";
SwiperCore.use([Navigation]);

const Content = styled.div`
  max-width: ${theme.containerSmallWidth};
  background-color: ${theme.colors.benchBaseBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 30px 15px;

  width: 100%;
  margin: 10px auto;

  h1 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.white};
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.colors.yellow};
    margin-top: 45px;
  }
  button {
    width: 75%;
    max-width: 420px;
  }

  .slider-container {
    width: 100%;
    margin: 0 auto 20px;
  }

  .swiper-slide {
    text-align: center;
  }

  .slide-image {
    width: 100px;
    height: 100px;
    margin: 0 auto 26px;
    position: relative;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.darkestblue};
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      object-position: center center;
      opacity: 1;
    }
  }

  .slide-title {
    font-weight: 400;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.yellow};
  }
  .slide-text-wrapper {
    padding: 0 10px;
  }
  .slide-text {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    width: 70%;
    margin: auto;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      h2 {
        font-size: 27px;
        margin-bottom: 40px;
      }

      .slider-container {
        margin-bottom: 60px;
      }
    }
  `}
`;

const slidesData = [
  {
    image: "/img/intro/whatisit.png",
    title: "What is it?",
    text: "This module will enable your business to understand its Digital Commerce strengths and opportunities at an organizational level. This granularity is crucial to laying down the right strategic roadmap for your business.",
  },
  {
    image: "/img/intro/howdoesitwork.png",
    title: "How does it work?",
    text: "Assess your business's Digital Commerce competencies against each of twenty four elements. Score each element one for low and five for high, for both current competency level and your view on how important that competency is to the success of your business.",
  },
  {
    image: "/img/intro/whathappensnext_sm.png",
    title: "What happens next?",
    text: "d-BUG will amalgamate the scores from all the respondents in your company and either make intra company comparisons or compare your business to a CPG benchmark.",
  },
];

function BenchmarkIntro({ switchTo }) {
  return (
    <>
      <Header />
      <PageHeading>Organisation Benchmarker</PageHeading>
      <ContainerSmall>
        <Content>
          <div className="slider-container">
            <Swiper spaceBetween={0} slidesPerView={1} navigation>
              {slidesData.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="slide-image">
                    <img src={slide.image} alt={slide.title} />
                  </div>
                  <h2 className="slide-title">{slide.title}</h2>
                  <div className="slide-text-wrapper">
                    <p className="slide-text">{slide.text}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <PrimaryButton onClick={() => switchTo("BenchmarkBase")}>
            Complete Survey
          </PrimaryButton>
        </Content>
      </ContainerSmall>
    </>
  );
}

export default BenchmarkIntro;
