/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

const MHead = styled.div`
  ${({ theme }) => `
  background-color: ${theme.colors.blackblueop};
  color: ${theme.colors.white};
  padding: 10px;
  margin-top: 20px;
  ${theme.mediaQueries.lg} {
    padding: 20px;
  }
  `}
`;

const MBody = styled.div`
  ${({ theme }) => `
  background-color: ${theme.colors.blackblueop};
  padding: 10px;

  ${theme.mediaQueries.lg} {
    padding: 20px;
  }
  `}
`;

export function Modal({ isOpen, maxWidth = 400, width = "auto", children }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      css={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(3, 66, 107,0.6)",
        zIndex: 10,
      }}
    >
      <div
        css={{
          position: "absolute",
          top: 30,
          bottom: 30,
          left: 10,
          right: 10,
          padding: "0 20px",
          overflow: "auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          css={{
            maxWidth: maxWidth,
            width: width,
            margin: "0 auto",
            boxShadow: "2px 2px 10px 10px rgba(0,0,0,0.2)",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export function ModalHead({ children }) {
  return <MHead>{children}</MHead>;
}

export function ModalBody({ children }) {
  return <MBody>{children}</MBody>;
}
