/** @jsxImportSource @emotion/react */

import { Oval, theme } from "Theme";
import styled from "@emotion/styled";
import { FaCheck, FaQuestionCircle } from "react-icons/fa";
import ProductivityElements from "./ProductivityElements";
import { useProductivity } from "../state/ProductivityContext";

export const ProductivityLabel = styled.a`
  color: ${theme.colors.benchFadeWhite};
  margin: 15px 5px 15px 10px;
  border-bottom: 1px dashed;
  font-family: Cabin;
  font-size: 17px;
  font-weight: normal;
  cursor: pointer;
`;

export const ProductivityLabelDone = styled(ProductivityLabel)`
  color: ${theme.colors.benchDoneGray};
`;

export const ProductivityLabelActive = styled(ProductivityLabel)`
  color: ${theme.colors.yellow};
  font-family: LucidaGrande;
  font-size: 17px;
  font-weight: bold;
`;

export const ProductivityLabelEdit = styled(ProductivityLabelActive)``;

export const ProductivityElement = styled.div`
  color: ${theme.colors.benchFadeWhite};
  margin: 5px 5px 5px 30px;

  font-family: Cabin;
  font-size: 17px;
  font-weight: normal;
`;

const ClickWrapper = ({ children, list }) => {
  const { answers, dispatch, getHeadAnswer } = useProductivity();

  function goBack() {
    dispatch({
      type: "UPDATE_QUESTION_ID",
      payload: { currentQuestionIndex: list[0]?.id },
    });
  }

  return <span onClick={goBack}>{children}</span>;
};

function getProductivityLabel(label, labelState, helpText, list) {
  let productivityLabel;
  if (labelState === "done") {
    productivityLabel = <ProductivityLabelDone>{label}</ProductivityLabelDone>;
  } else if (labelState === "active") {
    productivityLabel = (
      <ProductivityLabelActive>{label}</ProductivityLabelActive>
    );
  } else if (labelState === "edit") {
    productivityLabel = <ProductivityLabelEdit>{label}</ProductivityLabelEdit>;
  } else {
    productivityLabel = <ProductivityLabel>{label}</ProductivityLabel>;
  }
  return <ClickWrapper list={list}>{productivityLabel}</ClickWrapper>;
}
const Content = styled.div`
  .oval-done {
    background-color: ${theme.colors.benchGray};
  }
`;

function getQuestionCircle(show) {
  return show ? (
    <FaQuestionCircle
      size={17}
      css={{
        color: theme.colors.lightblue,
        marginBottom: "3px",
      }}
    />
  ) : (
    <></>
  );
}

function ProductivityNav({ productivity, index }) {
  const { getCurrentAnswer, getHeadAnswer } = useProductivity();
  let currentAnswer = getCurrentAnswer();
  let headAnswer = getHeadAnswer();

  function getProductivityState() {
    let labelState = "";
    if (currentAnswer) {
      let hasAnswerMatch = productivity.questionList.find(
        (q) => q.id === currentAnswer.question_index
      );
      if (hasAnswerMatch) {
        labelState = currentAnswer === headAnswer ? "active" : "edit";
      } else {
        let questionIndexes = productivity.questionList.map((q) => q.id);
        let isDone = Math.max(...questionIndexes) < headAnswer.question_index;
        if (isDone) {
          labelState = "done";
        }
      }
    }

    return labelState;
  }

  return (
    <Content>
      <div className="m-10">
        <div>
          <Oval
            className={getProductivityState() === "done" ? "oval-done" : ""}
          >
            {index}
          </Oval>
          {getProductivityState() === "done" && (
            <>
              <span
                css={{
                  width: "10px",
                  height: "10px",
                  position: "relative",
                }}
              >
                <FaCheck
                  size={15}
                  css={{
                    position: "absolute",
                    top: "2px",
                    left: "-8px",
                    zIndex: "0",
                    color: theme.colors.benchDoneGray,
                  }}
                />
              </span>
            </>
          )}
          {getProductivityLabel(
            productivity.label,
            getProductivityState(),
            productivity.helpText,
            productivity.questionList
          )}
          {getQuestionCircle(false)}
        </div>

        {(getProductivityState() === "active" ||
          getProductivityState() === "edit") && (
          <ProductivityElements
            questionList={productivity.questionList}
          ></ProductivityElements>
        )}
      </div>
    </Content>
  );
}

export default ProductivityNav;
