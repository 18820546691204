/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";
import { useHistory } from "react-router-dom";
import "react-svg-radar-chart/build/css/index.css";
import {
  routes,
  settings,
  specialWhiteList,
} from "../services/BenchmarkService";
import { SecondaryButton } from "Theme";

// Common components
import Header from "components/Header";
import BenchmarkingResults from "../components/BenchmarkingResults";
import { useAuth0 } from "@auth0/auth0-react";

function TakeAssessment() {
  const theme = useTheme();
  const history = useHistory();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        css={{
          maxWidth: 450,
          padding: 30,
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <h2 css={{ marginBottom: 20, color: theme.colors.white }}>
          You should first take the assessment
        </h2>

        <SecondaryButton onClick={() => history.push(routes.assessment)}>
          Go to assessment
        </SecondaryButton>
      </div>
    </div>
  );
}

function Benchmarking({ location }) {
  const results = window.localStorage.getItem(settings.keyAssessment);
  const {
    user: { email },
  } = useAuth0();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundImage: "url(/img/runner_start.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <Header mainNav={false} />

      {results ? (
        <BenchmarkingResults
          results={results}
          showLearningPlan={!specialWhiteList.some((val) => val === email)}
          showTitle={location?.state?.submitSurvey || false}
          showCompletionPercentage={false}
        />
      ) : (
        <TakeAssessment />
      )}
    </div>
  );
}

export default Benchmarking;
