/** @jsxImportSource @emotion/react */
import { useState } from "react";
import ReactTooltip from "react-tooltip";
import ModalVideo from 'react-modal-video'
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { BsArrowRightShort, BsQuestionCircleFill } from "react-icons/bs";

import 'react-modal-video/scss/modal-video.scss';

const ListWrapper = styled.ul`
  padding: 20px 0 0;
  margin: 0;
  list-style-type: none;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const ListItemAside = styled.div`
  flex: 0 0 50px;
  max-width: 50px;

  img {
    max-width: 75%;
    border-radius: 50%;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
     flex: 0 0 80px;
     max-width: 80px;
    }
  `}
`;

const ListItemContent = styled.div`
  flex: 1;
  padding-left: 15px;

  h4 {
    font-size: 14px;
    font-weight: 400;
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray};
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
     h4 {
       font-size: 16px;
     }

     p {
       font-size: 14px;
     }
    }
  `}
`;

const ListActions = styled.div`
  flex: 0 0 20%;
  max-width: 20%;

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
     flex: 0 0 100px;
     max-width: 100px;
    }
  `}
`;

const ListButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px 7px;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;

  svg {
    position: relative;
    left: 0;
    transition: left 0.2s;
  }

  :hover {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }

  :hover svg {
    left: 5px;
  }
`;

function List({ items, ...props }) {
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <ListWrapper {...props}>
      {items.map((item) => (
        <ListItem key={item.title}>
          {item.image && (
            <ListItemAside>
              <img src={item.image} alt={item.title} />
            </ListItemAside>
          )}

          <ListItemContent>
            {item.title && (
              <h4
                css={{
                  color: theme.colors.white,
                  ".tooltip": {
                    textAlign: "center",
                    maxWidth: 250,
                    padding: 8,
                    backgroundColor: theme.colors.lightblue,
                    color: theme.colors.white,
                    [theme.mediaQueries.md]: {
                      maxWidth: 350,
                      padding: 10,
                    },
                  },
                }}
              >
                {item.linkTo ? (
                  <Link to={item.linkTo}>{item.title}</Link>
                ) : (
                  <>{item.title}</>
                )}

                {item.hint && (
                  <span
                    data-tip={item.hint}
                    css={{
                      cursor: "pointer",
                      color: theme.colors.lightblue,
                      display: "inline-block",
                      marginLeft: 10,
                    }}
                  >
                    <BsQuestionCircleFill />

                    <ReactTooltip
                      wrapper="span"
                      className="tooltip"
                      delayShow={300}
                      multiline={true}
                    />
                  </span>
                )}
              </h4>
            )}

            {item.subtitle && (
              <p>
                {item.button ? (
                  item.subtitle
                ) : (
                  <>
                    <Link
                      css={{
                        color: theme.colors.gray,
                        ":hover": {
                          color: theme.colors.gray,
                        },
                      }}
                      to={item.subtitleLinkTo ? item.subtitleLinkTo : "#"}
                      onClick={item.channel && handleOpen}
                    >
                      {item.subtitle}
                    </Link>
                    {item.channel && (
                      <ModalVideo
                        channel={item.channel}
                        url={item.channel === "custom" ? item.url : null}
                        isOpen={isOpen}
                        videoId={item.channel !== "custom" ? item.videoId : null}
                        onClose={handleClose}
                      />
                    )}
                  </>
                )}
              </p>
            )}
          </ListItemContent>

          {item.button && (
            <ListActions>
              <ListButton to={item.button.to}>
                <span
                  css={{
                    display: "none",
                    fontSize: 12,
                    [theme.mediaQueries.md]: {
                      display: "inline",
                    },
                  }}
                >
                  {item.button.label}
                </span>

                <BsArrowRightShort css={{ fontSize: 30 }} />
              </ListButton>
            </ListActions>
          )}
        </ListItem>
      ))}
    </ListWrapper>
  );
}

export default List;
