/** @jsxImportSource @emotion/react */

import { theme } from "Theme";
import styled from "@emotion/styled";
import BenchmarkingResults from "components/BenchmarkingResults";
import Intro from "./Intro";
import Productivity from "./Productivity";
import React from "react";
import { settings } from "../../services/BenchmarkService";
import { Spinner } from "components/Spinner";
import { QUESTION_TYPES_ENUM } from "components/configure-users/utils/constants";

export const Card = styled.div`
  background-color: ${theme.colors.benchBaseBlue};
  color: ${theme.colors.white};
  padding: 0 20px;
  margin: 15px;
  min-width: 330px;
  ${({ theme }) => `
  ${theme.mediaQueries.lg} {
    width: 100%;
  }
`}
`;

const Content = styled.div`
  display: flex;
  color: ${theme.colors.white};

  .main {
    display: flex;
    flex-direction: column;
  }

  .left-main {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    max-width: 1600px;
  }

  .right-main {
    max-width: 420px;
  }

  .left-bench {
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .news-image {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 10px;
  }

  .news-content {
    padding: 0 10px;
  }

  .news-wrapper {
    display: flex;
    flex-direction: column;
  }

  a {
    color: ${theme.colors.white};
    text-decoration: underline;
    &:hover {
      color: ${theme.colors.benchActiveBlue};
    }
  }

  .learning-log {
    display: block;
    margin-bottom: 70px;
  }

  .benchmark-results,
  .benchmark-intro,
  .productivity-intro {
    width: 100vw;
    display: inline-block;
  }

  .benchmark-results svg,
  .productivity-intro svg {
    width: 100%;
  }

  ${theme.mediaQueries.lg} {
      .news-wrapper, .main, .left-bench {
        flex-direction: row;
      }
      .benchmark-results, .benchmark-intro, .productivity-intro {
        width: 520px;
        height: 600px;
      }
    }
  }

`;

function DashboardComponent({ user }) {
  // eslint-disable-next-line
  const [component, setComponent] = React.useState("Intro");

  const results = window.localStorage.getItem(settings.keyAssessment);

  if (!user) {
    return <Spinner></Spinner>;
  }

  function sectionVisibility(quizType) {
    const quiz = user.quizzes.filter((quiz) => quiz.quizType === quizType);

    return (
      quiz &&
      quiz.length > 0 &&
      quiz[0].questions &&
      quiz[0].questions.length > 0
    );
  }

  return (
    <Content>
      <div className="main">
        <div className="left-main">
          <div className="left-bench">
            {sectionVisibility(QUESTION_TYPES_ENUM.benchmark) && (
              <div className="benchmark-intro">
                <Intro
                  switchTo={setComponent}
                  showPercentageTitle={true}
                ></Intro>
              </div>
            )}
            {sectionVisibility(QUESTION_TYPES_ENUM.productivity) && (
              <div className="productivity-intro">
                <Productivity
                  switchTo={setComponent}
                  showPercentageTitle={true}
                ></Productivity>
              </div>
            )}
            {sectionVisibility(QUESTION_TYPES_ENUM.skills) && (
              <div className="benchmark-results">
                <BenchmarkingResults
                  results={results}
                  showLearningPlan={false}
                  showTitle={false}
                  showCompletionPercentage={true}
                  width={520}
                  dashboard
                ></BenchmarkingResults>
              </div>
            )}
          </div>
        </div>
      </div>
    </Content>
  );
}

export default DashboardComponent;
