/** @jsxImportSource @emotion/react */
import React from "react";

const mobileStyling = {
  display: "flex",
  flexFlow: "wrap",
  justifyContent: "center",
  alignItems: "center"
}

function PercentageTitle({ theme, title, percentage, image }) {
  return (
    <>
      <div css={{ ...mobileStyling, color: theme.colors.white, fontSize: "22px" }}>
        <img
          css={{ borderRadius: "50%", width: "50px", margin: "0 10px" }}
          src={image}
          alt={title}
        />
        <span>{title}</span>
        <span css={{ color: theme.colors.benchLightGreen }}>
          {" "}
          {percentage ? <span>{percentage + "%"} </span> : ""}
        </span>
      </div>
    </>
  );
}
export default PercentageTitle;
