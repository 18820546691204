/** @jsxImportSource @emotion/react */
import { BsTriangleFill } from "react-icons/bs";

import { Modal, ModalHead, ModalBody } from "components/Modal";
import { PrimaryRoundButton, PrimaryButton, SecondaryButton } from "Theme";
import { useTheme } from "@emotion/react";

function DeleteUserModal({ isOpen, setIsOpen, user, deleteUser, error }) {
  const { colors } = useTheme();

  return (
    <Modal isOpen={isOpen} maxWidth={350}>
      <ModalHead>
        <div css={{ display: "flex", alignItems: "center" }}>
          <PrimaryRoundButton onClick={() => setIsOpen(!isOpen)}>
            <span>
              <BsTriangleFill
                css={{
                  transform: "rotate(-90deg) translateY(-2px) translateX(2px)",
                }}
              />
            </span>
          </PrimaryRoundButton>

          <p
            css={{
              marginBottom: 0,
              marginLeft: 20,
              fontSize: 18,
            }}
          >
            Back to users
          </p>
        </div>
      </ModalHead>

      <ModalBody>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            padding: 0,
            margin: 0,
            color: colors.white,
          }}
        >
          <p
            css={{
              textAlign: "center",
            }}
          >
            Are you sure you want to delete user {user.firstname}{" "}
            {user.lastname} ({user.email})?
          </p>
          {error && (
            <p
              css={{
                textAlign: "center",
                color: colors.red,
              }}
            >
              {error}
            </p>
          )}
          <div>
            <SecondaryButton
              onClick={() => setIsOpen(!isOpen)}
              css={{
                marginLeft: "8px",
              }}
            >
              Cancel
            </SecondaryButton>

            <PrimaryButton
              onClick={() => deleteUser(user)}
              css={{
                marginLeft: "8px",
              }}
            >
              Delete
            </PrimaryButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default DeleteUserModal;
