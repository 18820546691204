/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { FiBookmark } from 'react-icons/fi';

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ListItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  padding: 20px 0;

  &:last-of-type {
    border-bottom: none;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      padding: 30px 0;
    }
  `}
`;

const ListItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
     margin-bottom: 30px;
    }
  `}
`;

const ListItemAuthorImage = styled.div`
  max-width: 40px;

  img {
    max-width: 100%;
    border-radius: 50%;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
     max-width: 60px;
    }
  `}
`;

const ListItemHeaderActions = styled.div`
  button {
    outline: none;
    border: 0;
    background: transparent;
  }

  svg {
    font-size: 16px;
    fill: transparent;
    transition: fill 0.2s;
  }

  button:hover svg {
    fill: black;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      svg {
        font-size: 20px;
      }
  `}
`;

const ListItemBody = styled.div`
  display: flex;
  align-items: center;
`;

const ListItemAuthor = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 12px;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
     p {
       font-size: 14px;
     }
    }
  `}
`;

const ListItemAside = styled.a`
  flex: 0 0 100px;
  max-width: 100px;

  img {
    max-width: 100%;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
     flex: 0 0 150px;
     max-width: 150px;
    }
  `}
`;

const ListItemContent = styled.div`
  flex: 1;
  padding: 0 30px 0 20px;

  h4 {
    font-size: 14px;
    font-weight: 400;

    a {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
     h4 {
       font-size: 16px;
     }

     p {
       font-size: 14px;
     }
    }
  `}
`;

function NewsList({ items, ...props }) {
  return (
    <ListWrapper {...props}>
      {items.map((item) => (
        <ListItem key={item.id}>
          {/* <ListItemHeader>
            <ListItemAuthor>
              <ListItemAuthorImage>
                <img src={item.author.avatar} alt='' />
              </ListItemAuthorImage>

              <div css={{ paddingLeft: 10 }}>
                <p>{item.author.name}</p>

                <p>
                  <span>{item.readingTime}</span>
                  {' · '}
                  <Link to='#'>{item.category}</Link>
                </p>
              </div>
            </ListItemAuthor>

            <ListItemHeaderActions>
              <button type='button'>
                <FiBookmark />
              </button>
            </ListItemHeaderActions>
          </ListItemHeader> */}

          <ListItemBody>
            {item.image && (
              <ListItemAside href='#'>
                <img src={item.image} alt={item.title} />
              </ListItemAside>
            )}
            <ListItemContent>
              {item.title && (
                <h4>
                  <a
                    css={{ color: '#000', textDecoration: 'none' }}
                    href={item.link}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {item.title}
                  </a>
                </h4>
              )}

              {item.excerpt && <p>{item.excerpt}</p>}
            </ListItemContent>
          </ListItemBody>
        </ListItem>
      ))}
    </ListWrapper>
  );
}

export default NewsList;
