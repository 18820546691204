/** @jsxImportSource @emotion/react */

import { MainContainer, PageHeading } from "Theme";
import ProgressNav from "./progress/ProgressNav";
import QuestionPanel from "./question/QuestionPanel";
import { useBenchmark } from "./state/BenchmarkContext";
import { useEffect } from "react";
import { Spinner } from "components/Spinner";

function OrganizationalBenchmark({
  fetchedAnswers,
  competencyList,
  switchTo,
  currentQuestionIndex,
}) {
  const { answers, dispatch, loaded, addAnswers } = useBenchmark();
  useEffect(() => {
    async function init() {
      if (!loaded) {
        addAnswers(fetchedAnswers);
      }
    }
    init();
  }, [addAnswers, answers, dispatch, fetchedAnswers, loaded]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_QUESTION_ID",
      payload: { currentQuestionIndex: currentQuestionIndex },
    });
  }, []);

  if (!loaded || !answers) return <Spinner></Spinner>;

  return (
    <>
      <PageHeading>Organisation Benchmarker</PageHeading>
      <MainContainer>
        <ProgressNav
          competencyList={competencyList}
          switchTo={switchTo}
        ></ProgressNav>
        <QuestionPanel
          competencyList={competencyList}
          switchTo={switchTo}
          currentQuestionIndex={currentQuestionIndex}
        ></QuestionPanel>
      </MainContainer>
    </>
  );
}

export default OrganizationalBenchmark;
