/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";

import Header from "components/Header";
import Intro from "components/assessment/Intro";
import Questions from "components/assessment/Questions";
import { apiFetch } from "services/apiFetch";
import { apiRoutes } from "services/BenchmarkService";
import { QUESTION_TYPES_ENUM } from "components/configure-users/utils/constants";

function renderComponent(component, switchTo, skillsQuizIds) {
  switch (component) {
    case "Intro":
      return <Intro switchTo={switchTo} />;
    case "Questions":
      return <Questions switchTo={switchTo} skillsQuizIds={skillsQuizIds} />;
    default:
      return null;
  }
}

function Assessment() {
  const [component, setComponent] = React.useState("Questions");
  const [skillsQuizIds, setSkillsQuizIds] = useState();

  useEffect(() => {
    apiFetch({
      url: apiRoutes.userProfile,
      method: "GET",
    }).then((userProfileResponse) => {
      if (
        userProfileResponse.isSuccessful &&
        userProfileResponse.data.response &&
        userProfileResponse.data.response.quizzes.find
      ) {
        const skillsQuiz = userProfileResponse.data.response.quizzes.find(
          (quiz) => quiz.quizType === QUESTION_TYPES_ENUM.skills
        );

        if (skillsQuiz && skillsQuiz.questions) {
          setSkillsQuizIds(skillsQuiz.questions);
        }
      }
    });
  }, []);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundImage: "url(/img/assessment_sm.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Header />

      {skillsQuizIds && renderComponent(component, setComponent, skillsQuizIds)}
    </div>
  );
}

export default Assessment;
