/** @jsxImportSource @emotion/react */
import { theme } from "Theme";
import styled from "@emotion/styled";

const Container = styled.span`
  .tooltip {
    display: inline-block;
    position: relative;
    text-align: left;
  }

  .tooltip h3 {
    margin: 12px 0;
  }

  .tooltip .top {
    min-width: 200px;
    max-width: 400px;
    top: -10px;
    left: 50%;
    transform: translate(-30%, -100%);
    padding: 5px 10px;
    color: #ffffff;
    background-color: ${theme.colors.blue};
    font-weight: normal;
    font-size: 14px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    display: none;
  }

  .tooltip:hover .top {
    display: block;
  }

  .tooltip .top i {
    position: absolute;
    top: 100%;
    left: 30%;
    margin-left: -15px;
    width: 30px;
    height: 15px;
    overflow: hidden;
  }

  .tooltip .top i::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${theme.colors.blue};
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  }
`;
function Tooltip({ labelText, tooltipTitle, tooltipText }) {
  return (
    <>
      <Container>
        <div className="tooltip">
          {labelText}
          <div className="top">
            {tooltipTitle && <h3>{tooltipTitle}</h3>}
            {tooltipText}
            <i></i>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Tooltip;
