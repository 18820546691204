/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";

import Header from "components/Header";
import DashboardComponent from "components/dashboard/Dashboard";
import { apiFetch } from "services/apiFetch";
import { apiRoutes } from "services/BenchmarkService";
// Components

export default function Dashboard() {
  const [user, setUser] = useState();

  useEffect(() => {
    apiFetch({
      url: apiRoutes.userProfile,
      method: "GET",
    }).then((userProfileResponse) => {
      if (userProfileResponse.isSuccessful) {
        setUser(userProfileResponse.data.response);
      }
    });
  }, []);

  return (
    <div
      css={{
        flex: 1,
        backgroundImage:
          "linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url(/img/blocks-image.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Header />
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DashboardComponent user={user} />
      </div>
    </div>
  );
}
