/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from "react";
import { FaCompass, FaSignOutAlt, FaWrench, FaDatabase } from "react-icons/fa";

import { Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "@emotion/react";
import { Spinner } from "./Spinner";
import { settings, routes, apiRoutes } from "services/BenchmarkService";
import useUserData from "../auth/useUserData";
import { apiFetch } from "services/apiFetch";
import { CSVLink } from "react-csv";
import { QUESTION_TYPES_ENUM } from "./configure-users/utils/constants";

let navItems = [
  {
    label: "Organisation Benchmarker",
    icon: FaCompass,
    to: routes.organizationalAssessment,
  },
  {
    label: "Productivity Assessor",
    icon: FaCompass,
    to: routes.productivityAssessment,
  },
  {
    label: "Skills Profiler",
    icon: FaCompass,
    to: routes.assessment,
  },
  {
    label: "Download Data",
    icon: FaDatabase,
    to: routes.download,
  },
  {
    label: "Configure Users",
    icon: FaWrench,
    to: routes.configureUsers,
  },
  {
    label: "Logout",
    icon: FaSignOutAlt,
    to: routes.logout,
  },
];

const Profile = ({ avatar, name, email }) => {
  const theme = useTheme();
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        padding: 15,
        backgroundColor: theme.colors.darkestblue,
        [theme.mediaQueries.md]: {
          padding: "25px 15px",
        },
      }}
    >
      <div
        css={{
          borderRadius: "50%",
          width: 60,
          height: 60,
          marginRight: 20,
          backgroundImage: "url(/img/dBUG_logo_sm.png)",
          [theme.mediaQueries.md]: {
            height: 80,
            width: 80,
          },
        }}
      ></div>

      <p css={{ marginBottom: 0, color: theme.colors.white }}>{name}</p>
    </div>
  );
};

function SecondaryNav({ isActive }) {
  const history = useHistory();
  const userData = useUserData(history.location.pathname);
  const [downloadedData, setDownloadedData] = useState([]);
  const theme = useTheme();
  const { location } = useHistory();
  const { logout } = useAuth0();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const csvInstance = useRef();
  const [visibleNavItems, setVisibleNavItems] = useState([]);

  useEffect(() => {
    let userProfileData = sessionStorage.getItem(settings.keyUser);

    if (userProfileData != "undefined") {
      const userProfile = JSON.parse(userProfileData);
      const benchmarkQuiz = userProfile?.quizzes?.find(
        (quiz) => quiz.quizType === QUESTION_TYPES_ENUM.benchmark
      );
      const productivityQuiz = userProfile?.quizzes?.find(
        (quiz) => quiz.quizType === QUESTION_TYPES_ENUM.productivity
      );

      const skillsQuiz = userProfile?.quizzes?.find(
        (quiz) => quiz.quizType === QUESTION_TYPES_ENUM.skills
      );

      if (!benchmarkQuiz || benchmarkQuiz.questions.length === 0) {
        navItems = navItems.filter(
          (item) => item.label !== "Organisation Benchmarker"
        );
      }

      if (!productivityQuiz || productivityQuiz.questions.length === 0) {
        navItems = navItems.filter(
          (item) => item.label !== "Productivity Assessor"
        );
      }

      if (!skillsQuiz || skillsQuiz.questions.length === 0) {
        navItems = navItems.filter((item) => item.label !== "Skills Profiler");
      }
    }

    let visibleItems = navItems.filter(
      (i) => settings.allowedRoutesForUsers.includes(i.to) || userData.isAdmin
    );

    setVisibleNavItems(visibleItems);
  }, [userData.isAdmin, userData?.userData?.email]);

  if (isLoading || !userData) {
    return <Spinner></Spinner>;
  }

  function logoutAndClear() {
    sessionStorage.removeItem(settings.keyUserData);
    sessionStorage.removeItem(settings.keyUser);
    sessionStorage.removeItem(settings.keyAccessToken);
    sessionStorage.removeItem(settings.keyProfilePopulated);
    logout({ returnTo: window.location.origin });
  }

  const downloadData = (event, done) => {
    if (downloadedData.length) return;
    event.preventDefault();
    let userProfilePostResult = apiFetch({
      url: apiRoutes.downloadData,
      method: "GET",
    }).then(({ data }) => {
      setDownloadedData(
        data.responses.map((val) => ({
          ...val,
          privacy_policy_accepted: val ? "Yes" : "No",
        }))
      );
      if (!data?.responses.length) {
        alert("No data!");
        return;
      }
      done();
      csvInstance.current.link.click();
    });
  };

  const functions = {
    ["/logout"]: logoutAndClear,
  };
  const headers = [
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Company", key: "company" },
    { label: "Email", key: "email" },
    { label: "External ID", key: "external_id" },
    { label: "Market", key: "market" },
    { label: "Seniority", key: "seniority" },
    { label: "User Id", key: "userid" },
    { label: "Func", key: "func" },
    { label: "ID", key: "id" },
    { label: "Privacy Policy Accepted", key: "privacy_policy_accepted" },
    { label: "Quiz Type", key: "quiz_type" },
    { label: "Question Index", key: "question_index" },
    { label: "Importance", key: "importance" },
    { label: "Current", key: "current" },
    { label: "Desired", key: "desired" },
    { label: "Date Submitted", key: "date_submitted" },
  ];

  const linkStyle = (item) => ({
    display: "flex",
    alignItems: "center",
    padding: "20px 15px",
    color:
      location.pathname === item.to ? theme.colors.yellow : theme.colors.white,
    backgroundColor:
      location.pathname === item.to ? theme.colors.darkerblue : "transparent",
    ":hover": {
      color: theme.colors.yellow,
      backgroundColor: theme.colors.darkerblue,
      textDecoration: "none",
    },
  });

  return (
    isAuthenticated && (
      <nav
        css={{
          position: "absolute",
          zIndex: 10,
          top: "100%",
          left: 0,
          width: "80vw",
          maxWidth: 400,
          transform: isActive ? "translateX(0)" : "translateX(-100%)",
          transition: "transform .5s",
          backgroundColor: theme.colors.darkerblueop,
          minHeight: "calc(100vh - 70px)",
        }}
      >
        <Profile name={user.name} avatar={user?.picture} email={user.email} />
        <ul css={{ padding: 0, listStyleType: 0 }}>
          {visibleNavItems &&
            visibleNavItems.map((item) => (
              <li key={item.label}>
                {item.to === "/download" ? (
                  <CSVLink
                    headers={headers}
                    data={downloadedData}
                    asyncOnClick={true}
                    onClick={(event, done) => downloadData(event, done)}
                    ref={csvInstance}
                    filename={"data.csv"}
                    css={linkStyle(item)}
                  >
                    <item.icon size={20} />

                    <span css={{ marginLeft: 10 }}>{item.label}</span>
                  </CSVLink>
                ) : (
                  <Link
                    to={item.to}
                    onClick={(e) => {
                      if (typeof functions[item.to] === "function")
                        functions[item.to](e);
                    }}
                    css={linkStyle(item)}
                  >
                    <item.icon size={20} />
                    <span css={{ marginLeft: 10 }}>{item.label}</span>
                  </Link>
                )}
              </li>
            ))}
        </ul>
      </nav>
    )
  );
}

export default SecondaryNav;
