import { addQuestionIdsToUsers, removeQuestionIdsFromUsers } from "../users";

/**
 * Prepares benchmark and productivity questions in the following format:
 *
 * Question's Category : {
 *  id: string,
 *  label: string,
 *  checked: boolean,
 *  questionsList: array with all questions which belongs to the category
 * }
 *
 * @param {*} questions initial array with questions
 * @param {*} checked flag for checking entire question category or a single question
 * @param {*} users users array which needs to be updated with question ids
 * @param key array key which may be one of the following:
 * 0 - for benchmark questions
 * 1 - for skills questions
 * 2 - for productivity questions
 *
 * @returns object in the following format:
 * {
 *  questions: array with all questions in the required format
 *  users: array with all users which have array updated with all checked/unchecked questions
 * }
 */
export function setQuestionsWithCategory(questions, checked, users = [], key) {
  let updatedUsers;

  const updatedListWithQuestions = questions.map((question) => {
    const updatedQuestion = {
      id: question.id,
      label: question.label,
      checked: checked,
      questionList: question.questionList.map((subquestion) => {
        if (checked && users.length > 0) {
          updatedUsers = addQuestionIdsToUsers(users, subquestion.id, key);
        } else if (!checked && users.length > 0) {
          updatedUsers = removeQuestionIdsFromUsers(users, subquestion.id, key);
        }

        return {
          id: subquestion.id,
          label: subquestion.label,
          checked: checked,
        };
      }),
    };

    return updatedQuestion;
  });

  return {
    questions: updatedListWithQuestions,
    users: updatedUsers,
  };
}

/**
 * Updates benchmark and productivity questions after user interaction in the UI
 *
 * @param {*} questions array with questions
 * @param {*} updatedQuestionCategory question category which is updated after user interaction in the UI
 * @param {*} users users array which needs to be updated with question ids
 * @param key array key which may be one of the following:
 * 0 - for benchmark questions
 * 1 - for skills questions
 * 2 - for productivity questions
 *
 * @returns object in the following format:
 * {
 *  questions: array with all questions updated
 *  users: array with all users which have array updated with checked/unchecked questions
 *  isAllCategoriesChecked: boolean flag which indicates if select all checkbox should be checked or unchecked
 * }
 */
export function updateQuestionsWithCategory(
  questions,
  updatedQuestionCategory,
  users,
  key
) {
  let updatedUsers;
  let isAllCategoriesChecked = true;

  const updatedListWithQuestions = questions.map((questionCategory) => {
    if (questionCategory.id === updatedQuestionCategory.id) {
      let isChecked = true;
      updatedQuestionCategory.questionList.forEach((question) => {
        if (question.checked === true) {
          updatedUsers = addQuestionIdsToUsers(users, question.id, key);
        } else if (question.checked === false) {
          updatedUsers = removeQuestionIdsFromUsers(users, question.id, key);
          isChecked = false;
        } else if (question.checked === undefined) {
          isChecked = false;
        }
      });
      // if all questions in a category are checked then also check the category
      updatedQuestionCategory.checked = isChecked;

      // if updated category is unchecked
      // then select all categories should also be unchecked
      if (!questionCategory.checked) {
        isAllCategoriesChecked = false;
      }
      return updatedQuestionCategory;
    } else {
      // if category is unchecked
      // then select all categories should also be unchecked
      if (!questionCategory.checked) {
        isAllCategoriesChecked = false;
      }

      return questionCategory;
    }
  });

  return {
    questions: updatedListWithQuestions,
    users: updatedUsers,
    isAllCategoriesChecked: isAllCategoriesChecked,
  };
}

/**
 * Updates question's category after user interaction in the UI
 *
 * @param {*} questions array with questions
 * @param {*} updatedCategory category which needs to be updated after user interaction in the UI
 * @param {*} users users array which needs to be updated with question ids
 * @param key array key which may be one of the following:
 * 0 - for benchmark questions
 * 1 - for skills questions
 * 2 - for productivity questions
 *
 * @returns object in the following format:
 * {
 *  questions: array with all questions updated
 *  users: array with all users which have array updated with checked/unchecked questions
 *  isAllCategoriesChecked: boolean flag which indicates if select all checkbox should be checked or unchecked
 * }
 */
export function updateCategory(questions, updatedCategory, users, key) {
  let isAllCategoriesChecked = true;
  let updatedUsers;

  const updatedListWithQuestions = questions.map((category) => {
    // if there is a category which is unchecked
    // then select all checkbox should also be unchecked
    if (!category.checked) {
      isAllCategoriesChecked = false;
    }
    if (category.id === updatedCategory.id) {
      updatedCategory.questionList = updatedCategory.questionList.map(
        (question) => {
          question.checked = updatedCategory.checked;

          if (question.checked) {
            updatedUsers = addQuestionIdsToUsers(users, question.id, key);
          } else {
            updatedUsers = removeQuestionIdsFromUsers(users, question.id, key);
          }

          return question;
        }
      );

      return updatedCategory;
    }

    return category;
  });

  return {
    questions: updatedListWithQuestions,
    users: updatedUsers,
    isAllCategoriesChecked: isAllCategoriesChecked,
  };
}
