/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import ReactTooltip from "react-tooltip";

import { Checkbox } from "components/learning-library/Filter";

function ExpertiseSection({
  questions,
  generateQuestionId,
  setQuestionCheckboxState,
  handleQuestionSelect,
}) {
  const { colors } = useTheme();
  return (
    <div>
      <ul
        css={{
          color: colors.blue,
        }}
      >
        {questions.map((question) => (
          <li
            key={question.id}
            css={{
              display: "flex",
              fontSize: "14px",
              marginLeft: "16px",
              listStyle: "none",
            }}
          >
            <Checkbox>
              <div css={{ display: "flex", alignItems: "baseline" }}>
                <input
                  type="checkbox"
                  id={generateQuestionId(question)}
                  checked={setQuestionCheckboxState(question)}
                  onChange={() => {
                    question.checked = !question.checked;
                    handleQuestionSelect();
                  }}
                  css={{
                    marginRight: "16px",
                    cursor: "pointer",
                  }}
                  data-tip={
                    setQuestionCheckboxState(question) === undefined
                      ? "At least one of the selected users has this question selected and at least one has it unselected"
                      : null
                  }
                />
                <span
                  css={{
                    cursor: "pointer",
                  }}
                >
                  {question.label}
                </span>
                <ReactTooltip
                  className="tooltip"
                  delayShow={100}
                  multiline={true}
                />
              </div>
            </Checkbox>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ExpertiseSection;
