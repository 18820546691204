/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import RadarChart from "react-svg-radar-chart";
import "react-svg-radar-chart/build/css/index.css";
import { routes, apiRoutes, settings } from "../services/BenchmarkService";
import { Container, PrimaryButton, BackButton } from "Theme";
import PercentageTitle from "./dashboard/PercentageTitle";
import Resources from "../resources.json";
import { apiFetch } from "../services/apiFetch";
import { Container as ViewContainer } from "./benchmark/question/ThankYou";
import { useAuth0 } from "@auth0/auth0-react";
// Common components
import Tabs from "./Tabs";
import List from "./List";

const StyledLink = styled(Link)`
  display: inline-block;
  min-height: 40px;
  line-height: 40px;
  padding: 5px 10px;
  text-align: right;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }
`;

const eLearnings = [
  {
    image: "/img/benchmarking/meet_digital_shelf.png",
    title: "Meet The Digital Shelf",
    subtitle: "Launch the video now",
    hint: "This video explains the online Point of Purchase fundamentals.",
    channel: "custom",
    url: "https://dbugvideos.s3.eu-west-1.amazonaws.com/dbug_explainer_video_v4.mp4",
  },
  {
    image: "/img/benchmarking/retail_media.png",
    title: "Using Retail Media",
    subtitle: "Launch the video now",
    hint: "This video explains the best way to think about retail media planning.",
  },
];

const trainingCourses = [
  {
    image: "/img/benchmarking/strategy_builder.png",
    title: "PLANR Strategy Builder",
    subtitle: "eLearning module",
    hint: "This click and complete module covers key elements to consider when building a Digital Commerce strategy",
    button: {
      label: "Booking enquiry",
      to: "#",
    },
  },
  {
    image: "/img/benchmarking/raise_the_floor.png",
    title: "d-IMPACT Raise The Floor",
    subtitle: "Instructor Led Learning",
    hint: "This workshop for large groups covers the basic principles of Digital Commerce that apply to the whole organisation",
    button: {
      label: "Booking enquiry",
      to: "#",
    },
  },
  {
    image: "/img/benchmarking/cost_and_payback.png",
    title: "d-IMPACT Cost and Payback",
    subtitle: "Webinar",
    hint: "This facilitated webinar covers key principles to consider when evaluating the cost and payback of retailer online investment.",
    button: {
      label: "Booking enquiry",
      to: "#",
    },
  },
];

const mentors = [
  {
    image: "/img/benchmarking/jeff_bezos.png",
    title: "Jeff Bezos",
    subtitle: "Expert Shopper",
    button: {
      label: "Send contact request",
      to: "#",
    },
  },
  {
    image: "/img/benchmarking/tim_cook.png",
    title: "Tim Cook",
    subtitle: "Hardware Expert",
    button: {
      label: "Send contact request",
      to: "#",
    },
  },
];

function BenchmarkingResults({
  results,
  showTitle,
  showLearningPlan,
  showCompletionPercentage,
  width,
  dashboard,
}) {
  const theme = useTheme();
  let emptyGraphData = {};
  emptyGraphData["Category Understanding"] = 0;
  emptyGraphData["Comfortable With Channel Tools"] = 0;
  emptyGraphData["Customer Excellence"] = 0;
  emptyGraphData["Data Analysis And Application"] = 0;
  emptyGraphData["Develops A Great Activation Plan"] = 0;
  emptyGraphData["Drive For Results"] = 0;
  emptyGraphData["Know The Landscape"] = 0;
  emptyGraphData["Planning And Investment"] = 0;
  emptyGraphData["Testing And Sharing"] = 0;
  emptyGraphData["The Digital Shelf"] = 0;
  const [loaded, setLoaded] = React.useState(false);
  const [graphData, setGraphData] = React.useState([
    {
      data: emptyGraphData,
      meta: { color: theme.colors.blue },
    },
    {
      data: emptyGraphData,
      meta: { color: theme.colors.lightorange },
    },
  ]);
  let benchmarks = Resources.benchmarkResults;
  // get current user profile
  let userProfile;
  let fallbackData = results;
  if (!results) {
    fallbackData = JSON.stringify(emptyGraphData);
  }
  let res = JSON.parse(fallbackData);
  const captions = Object.keys(res).reduce(
    (acc, key) => ({
      ...acc,
      [key]: key,
    }),
    {}
  );

  if (!loaded) {
    apiFetch({
      url: apiRoutes.userProfile,
      method: "GET",
    }).then(function (userProfileResponse) {
      if (!userProfileResponse.error) {
        userProfile = userProfileResponse.data.response;
        let profileSeniority = userProfile.seniority;
        let profileFunction = userProfile.func;

        let profileTarget = benchmarks.find(
          (b) => b.identifier === `${profileSeniority}_${profileFunction}`
        );

        if (profileTarget) {
          const dataToCompareAgainst = Object.entries(
            profileTarget.results
          ).reduce(
            (acc, item) => ({
              ...acc,
              [item[0]]: item[1] / 5,
            }),
            {}
          );

          const ourData = Object.entries(res).reduce(
            (acc, item) => ({
              ...acc,
              [item[0]]: item[1] / 5,
            }),
            {}
          );
          setGraphData([
            {
              data: dataToCompareAgainst,
              meta: { color: theme.colors.blue },
            },
            {
              data: ourData,
              meta: { color: theme.colors.lightorange },
            },
          ]);
          setLoaded(true);
        }
      }
    });
  }

  return (
    <Container>
      <div
        css={{
          padding: "20px 0",
        }}
      >
        {showTitle && <BenchmarkingTitle theme={theme}></BenchmarkingTitle>}
        <BenchmarkChartCard
          theme={theme}
          captions={captions}
          data={graphData}
          width={width}
          dashboard={dashboard}
          showTitle={showTitle}
        ></BenchmarkChartCard>
      </div>
    </Container>
  );
}

function BenchmarkChartCard({
  theme,
  captions,
  data,
  width,
  dashboard,
  showTitle,
}) {
  width = Math.min(width, window.innerWidth);
  const { logout } = useAuth0();

  function logoutAndClear() {
    sessionStorage.removeItem(settings.keyUserData);
    sessionStorage.removeItem(settings.keyAccessToken);
    sessionStorage.removeItem(settings.keyProfilePopulated);
    logout({ returnTo: window.location.origin });
  }
  return (
    <div
      css={{
        flex: 1,
        textAlign: "center",
        marginBottom: 40,
        paddingTop: 20,
        paddingBottom: 30,
        height: dashboard ? 560 : "",
        backgroundColor: theme.colors.benchBaseBlue,
        svg: {
          width: "100%",
          height: !dashboard ? "100%" : "",
        },
        ".scale": {
          stroke: theme.colors.white,
          strokeWidth: "0.5px",
          fill: theme.colors.white,
          fillOpacity: 0.3,
        },
        ".caption": {
          fill: theme.colors.white,
          fontSize: !dashboard ? "1.2rem" : "1.01rem",
          textShadow: "none",
        },
      }}
    >
      <PercentageTitle
        theme={theme}
        title="Skills Profiler"
        percentage={null}
        image="/img/dBUG_logo.png"
      ></PercentageTitle>

      <div
        css={{
          flex: 1,
          textAlign: "right",
        }}
      >
        <div>
          <ul
            css={{
              listStyleType: "none",
              padding: "0 20px",
              margin: 0,
              display: "inline-block",
              textAlign: "left",
            }}
          >
            <li css={{ color: "#fff" }}>
              <span
                css={{
                  display: "inline-block",
                  width: 10,
                  height: 10,
                  marginRight: 10,
                  backgroundColor: theme.colors.lightorange,
                }}
              ></span>
              Your Profile
            </li>

            <li css={{ color: "#fff" }}>
              <span
                css={{
                  display: "inline-block",
                  width: 10,
                  height: 10,
                  marginRight: 10,
                  backgroundColor: theme.colors.blue,
                }}
              ></span>
              Target Profile
            </li>
          </ul>
        </div>
      </div>
      <RadarChart
        captions={captions}
        data={data}
        size={width < 768 ? 370 : 600}
        options={{
          axes: false,
          dots: false,
          scales: 5,
          captionMargin: width < 768 ? 100 : 120,
        }}
      />
      {showTitle && (
        <ViewContainer>
          <h3 css={{ textAlign: "center", color: theme.colors.white }}>
            You have successfully completed Skills Profiler.
          </h3>
          <div
            className="success-checkmark"
            css={{ margin: "0 auto !important" }}
          >
            <div className="check-icon">
              <span className="icon-line line-tip"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
        </ViewContainer>
      )}
      {showTitle && (
        <div
          css={{
            display: "flex",
            alignContent: "center",
            alignItems: "baseline",
            justifyContent: "end",
            flexDirection: "row",
            paddingRight: 8,
          }}
        >
          <Link
            to={{
              pathname: routes.dashboard,
            }}
          >
            <PrimaryButton>Return To Home Screen</PrimaryButton>
          </Link>
          <Link
            to={{
              pathname: routes.assessment,
            }}
          >
            <BackButton>Start Over</BackButton>
          </Link>

          <PrimaryButton onClick={logoutAndClear}>Logout</PrimaryButton>
        </div>
      )}
      {!showTitle && (
        <Link
          to={{
            pathname: routes.assessment,
            state: { questions: true },
          }}
        >
          <PrimaryButton
            css={{
              width: "75%",
              maxWidth: "420px",
            }}
          >
            Complete Survey
          </PrimaryButton>
        </Link>
      )}
    </div>
  );
}
function BenchmarkingTitle({ theme }) {
  return (
    <div css={{ flex: 1, marginBottom: "20px" }}>
      <h3
        css={{
          textAlign: "center",
          fontWeight: 400,
          color: theme.colors.white,
          fontSize: 24,
          textDecoration: "underline",
        }}
      >
        How you compare with your function and seniority level target
      </h3>
    </div>
  );
}

export default BenchmarkingResults;
