/** @jsxImportSource @emotion/react */

import { Container, Input, PrimaryButton, SelectWrapper } from "Theme";

import Header from "components/Header";
import {
  routes,
  settings,
  apiRoutes,
  specialWhiteList,
} from "../services/BenchmarkService";
import { apiFetch } from "../services/apiFetch";
import { adminRouteService } from "../services/adminRouteService";
import { Spinner } from "components/Spinner";
import Resources from "../resources.json";
import { Auth0Context, withAuth0 } from "@auth0/auth0-react";
import React from "react";
import { theme } from "Theme";

const functionOptions = [
  "Marketing",
  "Sales",
  "Category / Shopper",
  "Digital",
  "All Other",
];

const seniorityOptions = [
  "VP or above",
  "Director",
  "Head Of",
  "Senior Manager",
  "Junior Manager",
];

class Profile extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.checkEmail = specialWhiteList.some(
      (val) => val === this.props.auth0.user.email
    );
    this.userProfile =
      Object.keys(Resources.userProfile2).length !== 0
        ? Resources.userProfile2
        : {
            ...Resources.emptyUserProfile,
            market: this.checkEmail
              ? ""
              : Resources.emptyUserProfile.market || "",
          };

    this.state = { userProfile: this.userProfile, isLoading: true };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  isProfilePopulated(userProfile) {
    return (
      userProfile &&
      userProfile.firstname &&
      userProfile.lastname &&
      userProfile.market &&
      userProfile.company &&
      userProfile.function &&
      userProfile.seniority &&
      userProfile.email
    );
  }

  handleInputChange(event, inputProp) {
    const userProfile = {
      ...this.state.userProfile,
    };
    userProfile[inputProp] = event.target.value;
    this.setState(() => ({ userProfile }));
  }
  async handleSubmit(event) {
    event.preventDefault();
    // eslint-disable-next-line
    let result = await apiFetch({
      url: apiRoutes.userProfile,
      data: this.state.userProfile,
      method: "POST",
    });

    if (result.isSuccessful) {
      sessionStorage.setItem(settings.keyProfilePopulated, true);
      adminRouteService(routes.dashboard, routes.dashboard, this.props.history);
    }
  }

  componentDidMount() {
    let that = this;
    apiFetch({
      url: apiRoutes.userProfile,
      method: "GET",
    }).then(function (userProfileResponse) {
      if (
        userProfileResponse.isSuccessful &&
        userProfileResponse.data.response
      ) {
        let userProfile = userProfileResponse.data.response;

        userProfile.function = userProfile.func;
        that.setState(() => ({
          ...userProfile,
          market: that.checkEmail ? "" : userProfile.market || "",
        }));
        that.setState(() => ({ isLoading: false }));

        if (that.isProfilePopulated(userProfile)) {
          that.props.history.push(routes.dashboard);
        }
      }
    });
  }

  render() {
    const { user } = this.context;
    if (this.state.isLoading) {
      return <Spinner></Spinner>;
    } else if (!this.state.userProfile.email) {
      const userProfile = {
        ...this.state.userProfile,
        email: user.email,
      };
      this.setState(() => ({ userProfile }));
    }

    if (!this.state.userProfile) {
      return null;
    }

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          backgroundImage: "url(/img/main.jpg)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Header />
        <header
          css={{
            padding: "10px 0",
            textAlign: "center",
          }}
        >
          <h1
            css={{
              fontSize: 28,
              color: theme.colors.white,
              marginTop: "4vh",
              marginBottom: "3vh",
              fontWeight: 400,
            }}
          >
            Please complete your d-BUG profile
          </h1>
        </header>

        <div
          css={{
            backgroundColor: theme.colors.blackblueop,
            paddingTop: "15px",
            paddingBottom: "40px",
          }}
        >
          <Container>
            <form
              onSubmit={this.handleSubmit}
              css={{
                width: "100%",
                maxWidth: 400,
                padding: "10px 10px",
                margin: "0 auto",
                button: {
                  width: "100%",
                },
              }}
            >
              <Input
                value={this.state.userProfile.firstname}
                onChange={(e) => this.handleInputChange(e, "firstname")}
                type="text"
                name="first-name"
                placeholder="First Name"
                required
              />
              <Input
                value={this.state.userProfile.lastname}
                onChange={(e) => this.handleInputChange(e, "lastname")}
                type="text"
                name="last-name"
                placeholder="Last name"
                required
              />
              <Input
                value={this.state.userProfile.company}
                onChange={(e) => this.handleInputChange(e, "company")}
                type="text"
                name="company"
                placeholder="Company"
                required
              />
              <Input
                value={this.state.userProfile.market}
                onChange={(e) => this.handleInputChange(e, "market")}
                type="text"
                name="market"
                placeholder="Market"
                required
              />
              <SelectWrapper>
                <select
                  name="function"
                  value={this.state.userProfile.function || ""}
                  onChange={(e) => this.handleInputChange(e, "function")}
                  required
                >
                  <option value="" disabled>
                    Function
                  </option>

                  {functionOptions.map((opt) => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              </SelectWrapper>

              <SelectWrapper>
                <select
                  name="seniority"
                  value={this.state.userProfile.seniority || ""}
                  onChange={(e) => this.handleInputChange(e, "seniority")}
                  required
                >
                  <option value="" disabled>
                    Seniority
                  </option>

                  {seniorityOptions.map((opt) => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              </SelectWrapper>

              <PrimaryButton type="submit">Save profile</PrimaryButton>
            </form>
          </Container>
        </div>
      </div>
    );
  }
}

export default withAuth0(Profile);
