/** @jsxImportSource @emotion/react */
import {
  ButtonContainer,
  BackButton,
  ContinueButton,
  theme,
  SecondaryLink,
} from "Theme";
import RatingButtons from "./RatingButtons";
import QuestionStatement from "./QuestionStatement";
import settings from "../settings";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import UnderstandScoring from "../misc/UnderstandScoring";
import BaseModal from "../BaseModal";
import { useProductivity } from "../state/ProductivityContext";
import { QUESTION_TYPES_ENUM } from "components/configure-users/utils/constants";
import { addAnswer } from "services/BenchmarkService";

let currentIndex = 0;

const Container = styled.div`
  width: 100%;

  ${({ theme }) => `
  ${theme.mediaQueries.lg} {
      display: inline-block;
      width: 725px;
      vertical-align: top;
    }
  `}
`;

const QuestionContainer = styled.div`
  margin: 0 9px;
  display: inline-block;
  vertical-align: top;
  ${({ theme }) => `
  ${theme.mediaQueries.lg} {
    margin-left: 0;
    }
  `}
`;

const QuestionTopInfo = styled.p`
  display: none;
  color: ${theme.colors.white};

  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      display: block;
      text-align: center;
    }
  `}
`;

const QuestionBottomInfo = styled.div`
  display: inline-block;
  color: ${theme.colors.white};
  width: 244px;
  font-size: 13px;
  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      display: none;
    }
  `}
`;

const ButtonRow = styled.div`
  margin: 7px 13px 0 14px;
  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      margin: 0 14px 0 14px;
    }
  `}
`;

function QuestionPanel({ productivityList, switchTo, currentQuestionIndex }) {
  let [scoringModalOpen, setScoringModalOpen] = useState(false);
  const [productivityListIds, setProductivityListIds] = useState([]);
  const { answers, dispatch, getCurrentAnswer } = useProductivity();

  let emptyAnswer = {
    question_index: currentQuestionIndex,
    current: 0,
    desired: 0,
    importance: 0,
  };

  let ca = getCurrentAnswer();
  let currentAnswer = ca || {
    ...emptyAnswer,
  };

  useEffect(() => {
    async function init() {
      if (!ca) {
        updateRating(0, "current");
      }
    }
    init();
  }, [ca, currentQuestionIndex, updateRating]);

  useEffect(() => {
    setListIds();
    currentIndex = 0;
  }, []);

  function setListIds() {
    let listWithIds = [];

    productivityList.forEach((category) => {
      category.questionList.forEach((question) => {
        listWithIds.push(question.id);
      });
    });

    setProductivityListIds(listWithIds);
  }

  function saveAnswer(answer) {
    currentIndex++;
    let question_index = productivityListIds[currentIndex];

    dispatch({
      type: "ADD_OR_UPDATE_ANSWER",
      payload: { answer: getExistingOrNewAnswer(question_index) },
    });
    let obj = {
      type: "UPDATE_QUESTION_ID",
      payload: { currentQuestionIndex: question_index },
    };
    dispatch(obj);

    if (
      currentAnswer.question_index ===
      productivityListIds[productivityListIds.length - 1]
    ) {
      let res = {};
      res.responses = answers;
      addAnswer(res, QUESTION_TYPES_ENUM.productivity);
      switchTo("ThankYou");
    }
  }

  function getExistingOrNewAnswer(question_index) {
    let answer = answers.find((a) => a.question_index === question_index);
    if (!answer) {
      answer = { ...emptyAnswer, question_index: question_index };
    }

    return answer;
  }

  function updateRating(rating, ratingName) {
    dispatch({
      type: "ADD_OR_UPDATE_ANSWER",
      payload: { answer: { ...currentAnswer, [ratingName]: rating } },
    });
  }

  function goBack() {
    currentIndex--;
    dispatch({
      type: "UPDATE_QUESTION_ID",
      payload: { currentQuestionIndex: productivityListIds[currentIndex] },
    });
  }

  function hasNoRating(answer) {
    return answer.current === 0 || answer.importance === 0;
  }

  function getQuestionDetails() {
    let questionDetails = productivityList
      .map((c) =>
        c.questionList.find((q) => q.id === currentAnswer.question_index)
      )
      .find((x) => x !== undefined);

    return questionDetails;
  }

  function toggleScoringModal(e, isOpen) {
    e.preventDefault();
    setScoringModalOpen(isOpen);
  }

  return (
    <Container>
      <QuestionTopInfo>
        Select the relevance of the statements to your business situation.
      </QuestionTopInfo>
      <QuestionContainer>
        <QuestionStatement
          text={getQuestionDetails()?.lowRatingText}
        ></QuestionStatement>
        <div css={{ display: "inline-block", marginBottom: "60px" }}>
          <RatingButtons
            rating={currentAnswer.current}
            headerText="Current"
            onRatingChange={updateRating}
            ratingName="current"
          ></RatingButtons>
          {/* <RatingButtons
            rating={currentAnswer.desired}
            headerText="Desired"
            onRatingChange={updateRating}
            ratingName="desired"
          ></RatingButtons> */}
          <RatingButtons
            rating={currentAnswer.importance}
            headerText="Confidence Level"
            onRatingChange={updateRating}
            ratingName="importance"
          ></RatingButtons>
          <SecondaryLink
            css={{ textAlign: "center" }}
            href="#"
            onClick={(e) => toggleScoringModal(e, true)}
          >
            Understand scoring
          </SecondaryLink>
        </div>
        <QuestionStatement
          text={getQuestionDetails()?.highRatingText}
        ></QuestionStatement>
      </QuestionContainer>
      <ButtonRow>
        <QuestionBottomInfo>
          Select the relevance of the statements to your business situation.
        </QuestionBottomInfo>
        <ButtonContainer>
          {currentAnswer.question_index > settings.startQuestionIndex && (
            <BackButton onClick={goBack}>Back</BackButton>
          )}
          <ContinueButton
            disabled={hasNoRating(currentAnswer)}
            onClick={() => saveAnswer(currentAnswer)}
          >
            {currentAnswer.question_index ===
            productivityListIds[productivityListIds.length - 1]
              ? "Submit Survey"
              : "Continue"}
          </ContinueButton>
        </ButtonContainer>
      </ButtonRow>
      <BaseModal
        isOpen={scoringModalOpen}
        handleClose={(e) => toggleScoringModal(e, false)}
        backButtonText="Back to survey"
      >
        <UnderstandScoring></UnderstandScoring>
      </BaseModal>
    </Container>
  );
}

export default QuestionPanel;
