/** @jsxImportSource @emotion/react */

import { Button, Container } from 'Theme';

import { FaChevronRight } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@emotion/react';

export default function Competency({ data }) {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Container>
      <h2
        css={{
          margin: '20px 0',
          textDecoration: 'underline',
          color: theme.colors.white,
        }}>
        By competency
      </h2>

      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          [theme.mediaQueries.md]: {
            justifyContent: 'flex-start',
          },
        }}>
        {data.map((competency) => (
          <div
            css={{
              width: '48%',
              marginBottom: 15,
              
              [theme.mediaQueries.md]: {
                width: '32%',
                marginRight: '1.33%',
              },
            }}
            key={competency.title}>
            <Button
              css={{
                width: '100%',
                position: 'relative',
                padding: '10px 10px 40px',
                backgroundColor: theme.colors.blackblueop,
                textAlign: 'left',
                height: '100%',
                verticalAlign: 'top',
                minHeight: 75,
              }}
              onClick={()=>{
                history.push(competency.link)
              }}
              >
              <h3
                css={{
                  fontSize: 16,
                  marginBottom: 0,
                  color: theme.colors.white,
                }}>
                {competency.title}
              </h3>

              <span
                css={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  backgroundColor: theme.colors.blue,
                  color: theme.colors.white,
                  width: 30,
                  height: 30,
                  lineHeight: '30px',
                  textAlign: 'center',
                }}>
                <FaChevronRight />
              </span>
            </Button>
          </div>
        ))}
      </div>
    </Container>
  );
}
