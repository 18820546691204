/** @jsxImportSource @emotion/react */
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Theme from "./Theme";

// Pages
import Assessment from "pages/Assessment";
import Benchmarking from "pages/Benchmarking";
import Profile from "pages/Profile";
import MyLearning from "pages/MyLearning";
import LearningLibrary from "pages/LearningLibrary";
import News from "pages/News";
import Experts from "pages/Experts";
import Dashboard from "pages/Dashboard";
import CorporateModules from "pages/CorporateModules";
import SingleCourseDetails from "pages/SingleCourseDetails";
import OrganizationalAssessment from "pages/benchmark/Assessment";
import ProductivityAssessment from "pages/productivity/Assessment";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ConfigureUsers from "pages/ConfigureUsers";
import AuthWrapper from "AuthWrapper";
import styled from "@emotion/styled";
import ProtectedRoute from "auth/ProtectedRoute";
import Unauthorized from "pages/Unauthorized";
import { routes } from "services/BenchmarkService";

const Container = styled.div`
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (orientation: landscape) {
    min-height: max(100vh, calc(100vh + 200px));
  }
  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      
    }
  `}
`;

function App() {
  return (
    <AuthWrapper>
      <Theme>
        <Router>
          <Container>
            <main css={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Switch>
                <Route path={routes.unauthorized}>
                  <Unauthorized />
                </Route>
                <ProtectedRoute
                  path={routes.assessment}
                  component={Assessment}
                ></ProtectedRoute>
                <ProtectedRoute
                  path={routes.benchmarking}
                  component={Benchmarking}
                ></ProtectedRoute>
                <Route path={routes.profile} component={Profile}></Route>
                <ProtectedRoute
                  path={routes.myLearning}
                  component={MyLearning}
                ></ProtectedRoute>
                <ProtectedRoute
                  path={routes.learningLibrary}
                  component={LearningLibrary}
                ></ProtectedRoute>
                <ProtectedRoute
                  path={routes.latestNews}
                  component={News}
                ></ProtectedRoute>
                <ProtectedRoute
                  path={routes.experts}
                  component={Experts}
                ></ProtectedRoute>
                <ProtectedRoute
                  path={routes.corporateModules}
                  component={CorporateModules}
                ></ProtectedRoute>
                <ProtectedRoute
                  path={routes.configureUsers}
                  component={ConfigureUsers}
                ></ProtectedRoute>
                <ProtectedRoute
                  path={routes.singleCourseDetails}
                  component={SingleCourseDetails}
                ></ProtectedRoute>
                <ProtectedRoute
                  path={routes.organizationalAssessment}
                  component={OrganizationalAssessment}
                ></ProtectedRoute>
                <ProtectedRoute
                  path={routes.productivityAssessment}
                  component={ProductivityAssessment}
                ></ProtectedRoute>
                <Route
                  path={routes.privacyPolicy}
                  component={PrivacyPolicy}
                ></Route>

                <ProtectedRoute
                  path={routes.dashboard}
                  exact
                  component={Dashboard}
                ></ProtectedRoute>
              </Switch>
            </main>
          </Container>
        </Router>
      </Theme>
    </AuthWrapper>
  );
}

export default App;
