/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import CompetencySection from "./CompetencySection";
import ExpertiseSection from "./ExpertiseSection";
import {
  QUESTION_TYPES,
  QUESTION_TYPES_ENUM,
} from "components/configure-users/utils/constants";

function QuestionsSection({
  questions,
  handleQuestionSelect,
  handleCategorySelect,
  users,
  section,
}) {
  const { colors } = useTheme();

  /**
   *
   * @param {*} selectedUsers list with all selected users
   * @param {*} question question to calculate it's state
   * @param {*} categoryKey question's category
   * @returns question's state (boolean) ;
   * there are 3 states as follows:
   * 1. checked (true) - when all selected users have the corresponding question checked
   * 2. unchecked (false) - when all selected users don't have the corresponding question checked
   * 3. intermediate (undefined) - when some of the selected users have the corresponding question checked, while all other users don't have it checked
   *
   */
  function calculateCheckboxState(selectedUsers, question, categoryKey) {
    let trueStateCount = 0;
    let falseStateCount = 0;

    // if there are no selected users then return unchecked state (false)
    if (!selectedUsers || selectedUsers.length === 0) {
      question.checked = false;
      return false;
    }

    selectedUsers.forEach((user) => {
      if (user[categoryKey].includes(question.id)) {
        trueStateCount++;
      } else {
        falseStateCount++;
      }

      if (trueStateCount && falseStateCount) {
        setTimeout(() => {
          document.getElementById(
            generateQuestionId(question)
          ).indeterminate = true;
        });
        // if there is at least one user with checked checkbox and at least one with unchecked
        // then return intermediate state (undefined)
        question.checked = undefined;
        return undefined;
      }
    });

    const intermediate = document.getElementById(generateQuestionId(question));

    if (intermediate) {
      document.getElementById(
        generateQuestionId(question)
      ).indeterminate = false;
    }
    // if there is at least one user with checked checkbox no users with unchecked
    // then return checked state (true)
    if (trueStateCount && !falseStateCount) {
      question.checked = true;
      return true;
    }

    // if there is no users with checked checkbox at least one with unchecked
    // then return unchecked state (false)
    if (!trueStateCount && falseStateCount) {
      question.checked = false;
      return false;
    }
  }

  /**
   *
   * @param {*} question question to calculate it's state
   * @returns question's state(boolean) thanks to calculateCheckboxState helper function ;
   * there are 3 states as follows:
   * 1. checked (true) - when all selected users have the corresponding question checked
   * 2. unchecked (false) - when all selected users don't have the corresponding question checked
   * 3. intermediate (undefined) - when some of the selected users have the corresponding question checked, while all other users don't have it checked
   *
   */
  function setQuestionCheckboxState(question) {
    let key;

    switch (section) {
      case QUESTION_TYPES.benchmark:
        key = QUESTION_TYPES_ENUM.benchmark;
        break;
      case QUESTION_TYPES.skills:
        key = QUESTION_TYPES_ENUM.skills;
        break;
      default:
        key = QUESTION_TYPES_ENUM.productivity;
    }
    return calculateCheckboxState(users, question, key);
  }

  /**
   *
   * @param {*} question to generate checkbox's id
   * @returns unique id which is created based on question's id and label
   */
  function generateQuestionId(question) {
    return `${question.id} - ${question.label}`;
  }

  return (
    <>
      {questions && questions.length > 0 ? (
        <>
          {(section === QUESTION_TYPES.benchmark ||
            section === QUESTION_TYPES.productivity) && (
            <CompetencySection
              questions={questions}
              handleCategorySelect={handleCategorySelect}
              generateQuestionId={generateQuestionId}
              setQuestionCheckboxState={setQuestionCheckboxState}
              handleQuestionSelect={handleQuestionSelect}
            />
          )}
          {section === QUESTION_TYPES.skills && (
            <ExpertiseSection
              questions={questions}
              generateQuestionId={generateQuestionId}
              setQuestionCheckboxState={setQuestionCheckboxState}
              handleQuestionSelect={handleQuestionSelect}
            />
          )}
        </>
      ) : (
        <h6 css={{ color: colors.blue, textAlign: "center" }}>
          No questions found!
        </h6>
      )}
    </>
  );
}

export default QuestionsSection;
