/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

// Components
import MainNav from "components/MainNav";
import SecondaryNav from "components/SecondaryNav";

const BurgerButton = styled.button`
  width: 20px;
  height: 12px;
  display: block;
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  padding: 0;

  .inner,
  .inner::before,
  .inner::after {
    position: absolute;
    width: 20px;
    height: 2px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  .inner::before,
  .inner::after {
    content: "";
    display: block;
  }

  .inner {
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 0.22s;
    transition-property: transform;
    top: 50%;
    display: block;
    margin-top: -1px;
  }

  .inner::before {
    transition: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
    top: -5px;
  }

  .inner::after {
    transition: bottom 0.1s ease-in 0.25s,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    bottom: -5px;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      width: 40px;
      height: 24px;

      .inner,
      .inner::before,
      .inner::after {
        width: 40px;
        height: 4px;
        border-radius: 4px;
      }

      .inner {
        margin-top: -2px;
      }

      .inner::before {
        top: -10px;
      }

      .inner::after {
        bottom: -10px;
      }
    }
  `}

  ${({ isActive }) =>
    isActive &&
    `
    .inner {
      transition-delay: .12s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: rotate(225deg);
    }
    .inner::before {
      top: 0;
      transition: top .1s ease-out,opacity .1s ease-out .12s;
      opacity: 0;
    }
    .inner::after {
      bottom: 0;
      transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
      transform: rotate(-90deg);
    }
  `}
`;

function Header({ label, mainNav = true }) {
  const [menu, setMenu] = React.useState(false);
  const theme = useTheme();

  return (
    <header
      css={{
        position: "relative",
        backgroundColor: theme.colors.darkestblue,
        padding: "15px",
        ".header-label": {
          marginLeft: "20px",
          fontSize: "20px",
          color: theme.colors.white,
          marginBottom: 0,
          [theme.mediaQueries.md]: {
            marginLeft: "40px",
          },
        },
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SecondaryNav isActive={menu} />

        <div
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <BurgerButton isActive={menu} onClick={() => setMenu(!menu)}>
            <span className="inner"></span>
          </BurgerButton>
        </div>

        <div css={{ display: "flex", alignItems: "center" }}>
          {mainNav && <MainNav />}
        </div>
      </div>
    </header>
  );
}

export default Header;
