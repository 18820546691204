/** @jsxImportSource @emotion/react */

import Header from "components/Header";
import BenchmarkHeader from "./BenchmarkHeader";
import { ShowOnDesktop, ShowOnMobile } from "Theme";

function ContextHeader({ competencyList, competencyId, answer }) {
  let competency = competencyList.find((c) => c.id === competencyId);
  let question = null;

  if (competency) {
    question = competency.questionList.find((q) => q.id === answer);
  }
  return (
    <>
      <ShowOnMobile>
        <BenchmarkHeader
          headerTitle={"Organisation Benchmarker"}
          competencyTitle={competency.label}
          competencySubTitle={question.label}
          competencyNum={competency.id}
        />
      </ShowOnMobile>
      <ShowOnDesktop>
        <Header mainNav={true} />
      </ShowOnDesktop>
    </>
  );
}

export default ContextHeader;
