/** @jsxImportSource @emotion/react */

import { theme } from "Theme";
import { FaCheck } from "react-icons/fa";
import styled from "@emotion/styled";
import { useBenchmark } from "../state/BenchmarkContext";
function getCompetencyLabel(label, labelState) {
  let competencyLabel;
  if (labelState === "done") {
    competencyLabel = (
      <div className="competence-label competence-label-done">{label}</div>
    );
  } else if (labelState === "active") {
    competencyLabel = (
      <div className="competence-label competence-label-active">{label}</div>
    );
  } else if (labelState === "edit") {
    competencyLabel = (
      <div className="competence-label competence-label-edit">{label}</div>
    );
  } else {
    competencyLabel = <div className="competence-label">{label}</div>;
  }
  return competencyLabel;
}

const Content = styled.div`
  .competence-done {
    color: ${theme.colors.benchDoneGray};
    margin: "5px 5px 5px 30px";
  }
  .competence-active,
  .competence-edit {
    color: ${theme.colors.white};
  }

  .competence-label {
    color: ${theme.colors.benchFadeWhite};
    margin-left: 20px;
    font-family: Cabin;
    font-size: 17px;
    font-weight: normal;
    display: inline-block;
  }

  .competence-label-done {
    margin-left: 0;
    color: ${theme.colors.benchDoneGray};
  }

  .competence-label-active,
  .competence-label-edit {
    color: ${theme.colors.white};
    font-weight: bold;
  }

  .competence-label-edit {
    margin-left: 0;
  }

  .competence-element {
    color: ${theme.colors.benchFadeWhite};
    margin: 5px 5px 5px 25px;

    font-family: Cabin;
    font-size: 17px;
    font-weight: normal;
    display: inline-block;
  }
`;

function getCompetencyClass(completionState) {
  let baseClass = "competence-element";
  if (completionState === "done") {
    baseClass += " competence-done";
  } else if (completionState === "active") {
    baseClass += " competence-active";
  } else if (completionState === "edit") {
    baseClass += " competence-edit";
  }
  return baseClass;
}

function getCheck(completionState) {
  let check = "";
  if (completionState === "done") {
    check = (
      <FaCheck
        size={15}
        css={{ marginRight: "5px", color: theme.colors.benchDoneGray }}
      />
    );
  } else if (completionState === "edit") {
    check = (
      <FaCheck
        size={15}
        css={{ marginRight: "5px", color: theme.colors.white }}
      />
    );
  }

  return check;
}

function CompetencyElements({ questionList }) {
  const { getCurrentAnswer, getHeadAnswer } = useBenchmark();
  let currentAnswer = getCurrentAnswer();
  let headAnswer = getHeadAnswer();

  function getState(id) {
    if (headAnswer.question_index === id && currentAnswer.question_index === id)
      return "active";
    if (currentAnswer.question_index === id) return "edit";
    if (headAnswer.question_index < id) return "";
    if (headAnswer.question_index > id) return "done";
  }
  return (
    <>
      <Content>
        {questionList.map((value) => {
          return (
            <div
              key={value.id}
              className={getCompetencyClass(getState(value.id))}
            >
              {getCheck(getState(value.id))}
              {getCompetencyLabel(value.label, getState(value.id))}
            </div>
          );
        })}
      </Content>
    </>
  );
}

export default CompetencyElements;
