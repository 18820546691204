// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case "UPDATE_RATING":
      return {
        ...state,
        answers: state.answers.map((answer) =>
          answer.question_index === action.payload.question_index
            ? { ...answer, [action.payload.ratingName]: action.payload.rating }
            : answer
        ),
      };
    case "ADD_ANSWERS": {
      let payloadAnswers = action.payload.answers;
      return {
        ...state,
        answers: payloadAnswers,
        loaded: true,
      };
    }
    case "ADD_OR_UPDATE_ANSWER": {
      let answer = action.payload.answer;
      let existingAnswer = state.answers.find(
        (a) => a.question_index === answer.question_index
      );
      if (existingAnswer) {
        return {
          ...state,
          answers: state.answers.map((a) =>
            a.question_index === answer.question_index ? { ...answer } : a
          ),
        };
      }

      return {
        ...state,
        answers: [...state.answers, answer],
      };
    }
    case "ADD_ANSWER":
      return {
        ...state,
        answers: [...state.answers, action.payload.answer],
      };
    case "UPDATE_ANSWER": {
      let answer = state.answers.filter(
        (a) => a.question_index === action.payload.answer.question_index
      );
      return {
        ...state,
        answers: answer ? [...state.answers, answer] : state.answers,
      };
    }
    case "UPDATE_QUESTION_ID": {
      return {
        ...state,
        currentQuestionIndex: action.payload.currentQuestionIndex,
      };
    }
    default:
      throw new Error("Unknown reducer action type " + action.type);
  }
};
