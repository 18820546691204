import { useState, useEffect } from "react";
import {
  settings,
  routes,
  specialWhiteList,
  apiRoutes,
} from "../services/BenchmarkService";
import { useAuth0 } from "@auth0/auth0-react";
import { questionsList } from "../../src/resources.json";

async function loadSkillAssessment(accessToken) {
  const skillsAssessorResponse = await fetch(
    `${settings.baseUrl}${apiRoutes.skillsAssessor}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const skillsAssessor = await skillsAssessorResponse.json();

  if (skillsAssessor) {
    const skills = skillsAssessor.responses.filter((e) => e.question_id <= 10);

    let latestDate = new Date(
      Math.max(...skills.map((e) => new Date(e.date_submitted)))
    );

    const latestSkills = skills.filter((e) =>
      e.date_submitted.includes(latestDate.toISOString().split(".")[0])
    );

    let assessment = {};
    latestSkills.forEach((skill) => {
      const question = questionsList.find(
        (question) => question.id === skill.question_id
      );

      if (question) {
        assessment[question.label] = skill.score;
      }
    });

    if (!Object.keys(assessment).length) {
      assessment = {
        "Know The Landscape": 0,
        "Category Understanding": 0,
        "Drive For Results": 0,
        "Comfortable With Channel Tools": 0,
        "Develops A Great Activation Plan": 0,
        "Customer Excellence": 0,
        "Planning And Investment": 0,
        "The Digital Shelf": 0,
        "Data Analysis And Application": 0,
        "Testing And Sharing": 0,
      };
    }

    const resultsStringified = JSON.stringify(assessment);
    await window.localStorage.setItem(
      settings.keyAssessment,
      resultsStringified
    );
  }
}

export default function useUserData(url) {
  const { isLoading, error, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: settings.auth0audience,
          scope: settings.auth0scope,
        });

        loadSkillAssessment(accessToken);

        sessionStorage.setItem(settings.keyAccessToken, accessToken);
        const userDataResponse = await fetch(
          `https://${settings.auth0Domain}/api/v2/users/${user.sub}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        let response = await userDataResponse.json();

        const userApiResponse = await fetch(
          `${settings.baseUrl}${apiRoutes.userProfile}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        let userApi = await userApiResponse.json();
        sessionStorage.setItem(settings.keyUserData, JSON.stringify(response));
        sessionStorage.setItem(
          settings.keyUser,
          JSON.stringify(userApi.response)
        );
        setLoaded(true);
      } catch (e) {
        console.log("Error", e);
      }
    };
    let isUserLoaded = sessionStorage.getItem(settings.keyUserData);
    if (isAuthenticated && !isUserLoaded) {
      getUserData();
    }
  }, [getAccessTokenSilently, isAuthenticated, setLoaded]);

  return getUserDateAndRoute(isLoading, isAuthenticated, url);
}

function getUserDateAndRoute(isLoading, isAuthenticated, url) {
  let data = {};
  let routeAllowed = false;
  let navigateTo = "";
  let defaultRoute = routes.privacyPolicy;
  let isAdmin = false;
  let user = null;

  if (
    sessionStorage.getItem(settings.keyUser) &&
    sessionStorage.getItem(settings.keyUser) != "undefined"
  ) {
    user = JSON.parse(sessionStorage.getItem(settings.keyUser));
  }
  if (!isLoading) {
    if (isAuthenticated) {
      if (specialWhiteList.some((val) => val === user?.email)) {
        settings.allowedRoutesForUsers.push("/assessment", "/benchmarking");
      }

      if (user && user.privacy_policy_accepted === true) {
        isAdmin = user.administrator;
        routeAllowed = true;
        navigateTo = routes.dashboard;
      } else {
        navigateTo = defaultRoute;
      }
    }
    if (!isAuthenticated) {
      routeAllowed = false;
      navigateTo = "auth";
    }
  }

  data.userData = user;
  data.routeAllowed = routeAllowed;
  data.navigateTo = navigateTo;
  data.isAdmin = isAdmin;

  return data;
}
