import { settings } from "./BenchmarkService";

export function adminRouteService(adminRoute, defaultRoute, history) {
  let userData = JSON.parse(sessionStorage.getItem(settings.keyUserData));
  if (userData && settings.superAdminList.includes(userData.email)) {
    if (adminRoute) {
      history.push(adminRoute);
    }
  } else {
    if (defaultRoute) {
      history.push(defaultRoute);
    }
  }
}
