/** @jsxImportSource @emotion/react */

import { theme, QuestionCard } from "Theme";
import styled from "@emotion/styled";

export const ScoreButton = styled.button`
  width: 40px;
  height: 37px;
  margin: 0 0 0 5px;
  padding: 7px 0;
  text-align: center;
  font-family: Cabin;
  font-size: 19px;
  font-weight: normal;
  line-height: 1.4em;
  display: inline-block;
  vertical-align: top;
  color: ${theme.colors.white};
  background-color: ${theme.colors.benchActiveBlue};
  border: 0;
`;

const Content = styled.div`
  margin-bottom: 0px;

  .selected-rating {
    background-color: ${theme.colors.yellow};
  }
  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      margin-bottom: 17px;
    }
  `}
`;

const LabelCard = styled(QuestionCard)`
  width: 220px;
  padding: 0;
  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      padding: 5px;
      width: 220px;
    }
  `}
`;

function RatingButtons({ rating, headerText, onRatingChange, ratingName }) {
  const scoreButtons = [1, 2, 3, 4, 5];
  return (
    <Content>
      <LabelCard>{headerText}</LabelCard>
      <div>
        {scoreButtons.map((value, index) => {
          return (
            <ScoreButton
              key={index}
              className={rating === value && "selected-rating"}
              onClick={() => onRatingChange(value, ratingName)}
            >
              {value}
            </ScoreButton>
          );
        })}
      </div>
    </Content>
  );
}

export default RatingButtons;
