/** @jsxImportSource @emotion/react */
import { Container, ContinueButton } from "Theme";
import { useEffect } from "react";
import Header from "components/Header";
import React from "react";
import { theme } from "Theme";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { routes, apiRoutes } from "../services/BenchmarkService";
import { adminRouteService } from "../services/adminRouteService";
import { apiFetch } from "../services/apiFetch";
import Resources from "../resources.json";
import { Spinner } from "components/Spinner";
import { settings } from "../services/BenchmarkService";

const ConfirmationContainer = styled.div`
  display: flex;

  margin-bottom: 15px;
  .confirm-column {
    min-width: 60px;
    display: flex;
    flex-flow: wrap;
  }
    input[type="checkbox"] {
      min-width: 18px;
      min-height: 18px;
      margin: 0 5px;
    }
    label {
      margin-left: 10px;
      margin-bottom: 0px;
    }
  }

  .checkbox-container {
    display: flex;
    align-content: center;
    align-items: center;
    width: 60px;
    justify-content: right;
  }

  .info-column {
    margin-left: 15px;
  }

  .confirm-column {
    height: 70px;
    align-items: self-start;
  }
`;

function PrivacyPolicy() {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [userProfile, setUserProfile] = React.useState(
    Resources.emptyUserProfile
  );

  const [accepted, setAccepted] = React.useState(null);
  const [nameSignature, setNameSignature] = React.useState("");
  // function setCheckedItems(index, isChecked) {
  //   let arr = checkedItems.slice();
  //   arr[index] = isChecked;
  //   setChecked(arr);
  // }

  useEffect(() => {
    async function init() {
      let userProfileGetResult = await apiFetch({
        url: apiRoutes.userProfile,
        method: "GET",
      });
      if (userProfileGetResult.isSuccessful) {
        let userProfile =
          userProfileGetResult.data.response || Resources.emptyUserProfile;

        //if (userProfile && userProfile.privacy_policy_accepted)
        if (userProfile && userProfile.privacy_policy_accepted) {
          adminRouteService("", routes.profile, history);
        }
        setUserProfile(userProfile);
        setIsLoading(false);
      } else {
        console.error("Error while getting user-profile", userProfileGetResult);
      }
    }
    init();
  }, []);

  const handleChange = ({ target: { value } }, type) =>
    setAccepted(type === "yes" ? true : false);

  function acceptPolicy() {
    userProfile.privacy_policy_accepted = true;
    userProfile.function = userProfile.func;

    let userProfilePostResult = apiFetch({
      url: apiRoutes.userProfile,
      data: userProfile,
      method: "POST",
    }).then(function (resp) {
      if (resp.isSuccessful) {
        sessionStorage.setItem(settings.keyUser, JSON.stringify(userProfile));
        history.push(routes.profile);
      }
    });
  }

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  return (
    <div
      css={{
        flex: 1,
        backgroundImage: "url(/img/my_learning_sm.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Header />
      <Container
        css={{
          marginTop: "50px",
          marginBottom: "50px",

          color: theme.colors.white,
          backgroundColor: theme.colors.blackblueop,
        }}
      >
        <h2
          css={{
            paddingTop: "30px",
          }}
        >
          Informed Consent for Data Collection via d-BUG platform
        </h2>
        {/* <h3>Please tick the appropriate boxes</h3> */}

        <h4>1. Taking part in the study</h4>
        <ConfirmationContainer>
          <div className="info-column">
            I have read and understood the study information provided or it has
            been read to me. I have been able to ask questions about the study
            and my questions have been answered to my satisfaction.
            <br />
            <br />
            I consent voluntarily to be a participant in this study and
            understand that I can refuse to answer questions and I can withdraw
            from the study at any time, without having to give a reason.
            <br />
            <br />I understand that taking part in the study involves either
            completing self-survey questionnaires or participating in a
            facilitated interview on the d-BUG platform.
          </div>
        </ConfirmationContainer>

        <h4>2. Use of the information in the study</h4>

        <ConfirmationContainer>
          <div className="info-column">
            I understand that information I provide will be used to benchmark my
            business’s Digital Commerce organisational competencies
            (Organisation Benchmarker module) or to create an anonymised
            benchmark of my individual Digital Commerce competencies versus an
            industry profile based on my function and seniority (Skill Profiler
            module) or to understand whether a formal learning intervention has
            affected my productivity at work (Productivity Assessor module).
            <br />
            <br />I understand that personal information collected about me that
            can identify me, such as my name or company I work for, will not be
            shared beyond the study team.
          </div>
        </ConfirmationContainer>

        <h4>3. Future use and reuse of the information</h4>

        <ConfirmationContainer>
          <div className="info-column">
            I give permission for the questionnaire answer data that I provide
            to be stored in the survey database so it can be used for future
            assessments.
          </div>
        </ConfirmationContainer>

        <h4>4. Study contact details for further information</h4>

        <ConfirmationContainer>
          <div className="info-column">
            I understand that I can contact the following person for more
            information about this study:
            <br />
            Marc Warburton;&nbsp;&nbsp;
            <a href="mailto: marc@daedal.uk">marc@daedal.uk</a>
          </div>
        </ConfirmationContainer>

        <h4>5. Consent for data collection</h4>
        <ConfirmationContainer>
          <div className="info-column">
            I give my informed consent to the above points
          </div>
          <div className="confirm-column">
            <div className="checkbox-container">
              <label htmlFor="confirmed">Yes</label>
              <input
                id="confirmed"
                type="checkbox"
                checked={accepted}
                onChange={(ev) => handleChange(ev, "yes")}
              />
            </div>
            <div className="checkbox-container">
              <label htmlFor="denied">No</label>
              <input
                id="denied"
                type="checkbox"
                checked={accepted === null ? false : !accepted}
                onChange={(ev) => handleChange(ev, "no")}
              />
            </div>
          </div>
        </ConfirmationContainer>

        <div css={{ margin: "auto", width: "50%" }}>
          <div css={{ marginBottom: "60px", display: "inline-block" }}>
            <ContinueButton disabled={!accepted} onClick={() => acceptPolicy()}>
              {"Confirm"}
            </ContinueButton>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
