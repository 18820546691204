/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import Resources from "../../resources.json";
import OrganizationalBenchmark from "./OrganizationalBenchmark";
import { BenchmarkProvider } from "./state/BenchmarkContext";
import useFetchWithEffect from "../../services/useFetchWithEffect";
import { Spinner } from "components/Spinner";
import ContextHeader from "./header/ContextHeader";
import styled from "@emotion/styled";
import { apiRoutes } from "../../services/BenchmarkService";
import { QUESTION_TYPES_ENUM } from "components/configure-users/utils/constants";
import { apiFetch } from "services/apiFetch";

const BenchmarkOrientation = styled.div`
  @media screen and (max-width: 767px) and (orientation: portrait) {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

function BenchmarkBase({ switchTo }) {
  const { data, loading, error } = useFetchWithEffect(
    `${apiRoutes.userResponse}?quizType=${QUESTION_TYPES_ENUM.benchmark}`
  );
  const [benchmarkQuestions, setBenchmarkQuestions] = useState();
  const [benchmarkResponses, setBenchmarkResponses] = useState();

  useEffect(() => {
    apiFetch({
      url: apiRoutes.userProfile,
      method: "GET",
    }).then((userProfileResponse) => {
      if (userProfileResponse.isSuccessful) {
        const benchmark = userProfileResponse.data.response.quizzes.find(
          (quiz) => quiz.quizType === QUESTION_TYPES_ENUM.benchmark
        );

        if (benchmark) {
          let questions = JSON.parse(
            JSON.stringify(Resources.competencyList)
          ).map((category) => {
            const categoryQuestions = [];

            category.questionList.forEach((question) => {
              if (benchmark.questions.includes(question.id)) {
                categoryQuestions.push(question);
              }
            });

            category.questionList = categoryQuestions;
            return category;
          });

          questions = questions.filter(
            (category) => category.questionList.length > 0
          );
          setBenchmarkQuestions(questions);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (data && data.responses && benchmarkQuestions) {
      const responses = [];

      data.responses.forEach((response) => {
        benchmarkQuestions.forEach((category) => {
          category.questionList.forEach((question) => {
            if (question.id === response.question_index) {
              responses.push(response);
            }
          });
        });
      });
      setBenchmarkResponses(responses);
    }
  }, [benchmarkQuestions, data]);

  if (error) throw error;
  let stillLoading =
    loading ||
    !benchmarkQuestions ||
    !benchmarkResponses ||
    !data ||
    !data.responses;
  if (stillLoading) return <Spinner></Spinner>;

  return (
    <BenchmarkProvider>
      <BenchmarkOrientation>
        <ContextHeader
          competencyList={benchmarkQuestions}
          competencyId={benchmarkQuestions[0].id}
          answer={benchmarkQuestions[0].questionList[0].id}
        />
        <OrganizationalBenchmark
          fetchedAnswers={benchmarkResponses}
          competencyList={benchmarkQuestions}
          switchTo={switchTo}
          currentQuestionIndex={benchmarkQuestions[0].questionList[0].id}
        ></OrganizationalBenchmark>
      </BenchmarkOrientation>
    </BenchmarkProvider>
  );
}

export default BenchmarkBase;
