/** @jsxImportSource @emotion/react */

import { Container } from 'Theme';
import Header from 'components/Header';
import List from 'components/List';
import Tabs from 'components/Tabs';
import { useTheme } from '@emotion/react';

// Components





const tabs = [
  {
    label: 'eLearning',
  },
  {
    label: 'Training courses',
  },
  {
    label: 'Mentors',
  },
];

const eLearnings = [
  {
    image: '/img/benchmarking/meet_digital_shelf.png',
    title: 'The Perfect Digital Shelf',
    subtitle: 'Online best practice',
  },
  {
    image: '/img/benchmarking/cost_and_payback.png',
    title: 'Improving ROI of Shopper Campaigns',
    subtitle: 'Online best practice',
  },
];

const trainingCourses = [
  {
    image: '/img/benchmarking/strategy_builder.png',
    title: 'Digital Shelf eLearning',
    subtitle: 'Online classroom',
    // button: {
    //   label: 'Book now',
    //   to: '#',
    // },
  },
  {
    image: '/img/benchmarking/raise_the_floor.png',
    title: 'd-IMPACT Raise the Floor',
    subtitle: 'Online workshop',
    // button: {
    //   label: 'Book now',
    //   to: '#',
    // },
  },
  {
    image: '/img/benchmarking/retail_media.png',
    title: 'Working with retailers',
    subtitle: 'Webinar',
    // button: {
    //   label: 'Book now',
    //   to: '#',
    // },
  },
];

const mentors = [
  {
    image: '/img/benchmarking/Marc.png',
    title: 'Marc Warburton',
    subtitle: 'Daedal founder',
    // button: {
    //   label: 'Book now',
    //   to: '#',
    // },
  },
  {
    image: '/img/benchmarking/jeff_bezos.png',
    title: 'Jeff Bezos',
    subtitle: 'Expert Shopper',
    // button: {
    //   label: 'Book now',
    //   to: '#',
    // },
  },
];

function MyLearning() {
  const theme = useTheme();

  return (
    <div 
      css={{ 
        flex: 1, 
        backgroundImage: 'url(/img/my_learning_sm.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        }}>
      <Header />
        <Container css={{
          //marginTop: 70,
          backgroundColor: theme.colors.blackblueop
          }}>
          <Tabs navItems={tabs}>
            <List items={[...eLearnings]} />

            <List items={[...trainingCourses]} />

            <List items={[...mentors]} />
          </Tabs>
        </Container>
        
    </div>
  );
}

export default MyLearning;
