/** @jsxImportSource @emotion/react */

import { SecondaryLink } from "Theme";
import CompetencyNav from "./CompetencyNav";
import styled from "@emotion/styled";
import BaseModal from "../BaseModal";
import { useState } from "react";
import UnderstandScoring from "../misc/UnderstandScoring";
import ProtectedComponent from "auth/ProtectedComponent";
const ProgressContainer = styled.div`
  display: none;

  ${({ theme }) => `
    ${theme.mediaQueries.lg} {
      display: inline-block;
      width: 282px;
    }
  `}
`;

function ProgressNav({ competencyList, switchTo }) {
  let [scoringModalOpen, setScoringModalOpen] = useState(false);

  function toggleScoringModal(e, isOpen) {
    e.preventDefault();
    setScoringModalOpen(isOpen);
  }

  function navigateToCharts(e) {
    e.preventDefault();
    switchTo("SampleCharts");
  }
  return (
    <>
      <ProgressContainer>
        {competencyList.map((value, index) => {
          return (
            <CompetencyNav
              competency={value}
              key={value.id}
              index={index + 1}
            ></CompetencyNav>
          );
        })}
        <div>
          <ProtectedComponent>
            <SecondaryLink href="#" onClick={(e) => navigateToCharts(e)}>
              Sample output
            </SecondaryLink>
          </ProtectedComponent>
        </div>
      </ProgressContainer>
      <BaseModal
        isOpen={scoringModalOpen}
        handleClose={(e) => toggleScoringModal(e, false)}
        backButtonText="Back to survey"
      >
        <UnderstandScoring></UnderstandScoring>
      </BaseModal>
    </>
  );
}

export default ProgressNav;
