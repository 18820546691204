/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { routes, settings, apiRoutes } from "../../services/BenchmarkService";
import {
  BsTriangleFill,
  BsFillStarFill,
  BsStar,
  BsQuestionCircleFill,
} from "react-icons/bs";
import { apiFetch } from "../../services/apiFetch";

import {
  Container,
  Button,
  PrimaryButton,
  PrimaryRoundButton,
  theme,
} from "Theme";

// Components
import { Modal, ModalHead, ModalBody } from "components/Modal";
import { questionsList } from "../../../src/resources.json";

function Question({ label, hint, setResults }) {
  const theme = useTheme();
  const [rating, setRating] = React.useState(0);

  const setPoints = (points) => {
    setResults((results) => ({
      ...results,
      [label]: points,
    }));
    setRating(points);
  };

  return (
    <div
      css={{
        marginBottom: 30,
        color: theme.colors.white,
        ":last-child": {
          marginBottom: 0,
        },
        p: {
          marginBottom: 0,
        },
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          css={{
            ".tooltip": {
              textAlign: "center",
              maxWidth: 250,
              padding: 8,
              color: theme.colors.white,
              backgroundColor: theme.colors.lightblue,
              [theme.mediaQueries.md]: {
                maxWidth: 350,
                padding: 10,
              },
            },
          }}
        >
          {label}{" "}
          <div
            data-tip={hint}
            css={{
              cursor: "pointer",
              display: "inline",
              color: theme.colors.lightblue,
            }}
          >
            <BsQuestionCircleFill />

            <ReactTooltip
              className="tooltip"
              delayShow={300}
              multiline={true}
            />
          </div>
        </div>

        <div
          css={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginLeft: 15,
          }}
        >
          {[...Array(5).keys()].map((i) => (
            <Button
              key={i}
              css={{
                padding: "0 3px",
                minHeight: 0,
                display: "block",
                color: theme.colors.yellow,
              }}
              onClick={() => setPoints(i + 1)}
            >
              {i + 1 <= rating ? <BsFillStarFill /> : <BsStar />}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}

function QuestionsGroup({ questions, setResults }) {
  return (
    <div
      css={{
        display: "flex",
        marginBottom: 30,
        ":last-child": {
          marginBottom: 0,
        },
      }}
    >
      <div css={{ flex: 1, marginLeft: 15 }}>
        {questions.map((question) => (
          <Question
            key={question.label}
            setResults={setResults}
            {...question}
          />
        ))}
      </div>
    </div>
  );
}

function Questions({ switchTo, skillsQuizIds }) {
  const [modal, setModal] = React.useState(false);
  const [results, setResults] = React.useState({});
  const [questions, setQuestions] = useState([]);

  const history = useHistory();

  useEffect(() => {
    setQuestionsList();
  }, []);

  const assess = () => {
    const resultsStringified = JSON.stringify(results);
    window.localStorage.setItem(settings.keyAssessment, resultsStringified);
    postQuestions();
  };

  function setQuestionsList() {
    const list = [];

    if (skillsQuizIds) {
      skillsQuizIds.forEach((skillQuizId) => {
        const foundQuestion = questionsList.find(
          (skillQuestion) => skillQuestion.id === skillQuizId
        );

        if (foundQuestion) {
          list.push(foundQuestion);
        }
      });
    }

    setQuestions(list);
  }

  function postQuestions() {
    let data = {
      responses: [],
    };
    Object.keys(results).map((r) =>
      questions.map(function (q) {
        if (q.label === r) {
          let resp = {
            question_id: q.id,
            score: results[r],
          };
          data.responses.push(resp);
        }
        return q.id;
      })
    );

    apiFetch({
      url: apiRoutes.skillsAssessor,
      data: data,
      method: "POST",
    }).then((postResponse) =>
      postResponse.isSuccessful
        ? history.push(routes.benchmarking, { submitSurvey: true })
        : console.error(
            "Error occurred while posting skill assessor data",
            postResponse.error
          )
    );
  }

  return (
    <div css={{ padding: "20px 0 17% 0", maxWidth: 600, margin: "0 auto" }}>
      <Container>
        <div css={{ display: "flex", alignItems: "center", marginBottom: 40 }}>
          <Link to={routes.dashboard}>
            <PrimaryRoundButton>
              <span>
                <BsTriangleFill
                  css={{
                    transform:
                      "rotate(-90deg) translateY(-2px) translateX(2px)",
                  }}
                />
              </span>
            </PrimaryRoundButton>
          </Link>

          <p
            css={{
              marginBottom: 0,
              marginLeft: 20,
              fontSize: 18,
              color: theme.colors.white,
            }}
          >
            Go back to home
          </p>
        </div>

        <div
          css={{
            backgroundColor: theme.colors.blackblueop,
          }}
        >
          <p
            css={{
              marginBottom: 20,
              color: theme.colors.white,
              fontSize: 24,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            What is your level of expertise / knowledge?
            <br />
            <Button
              onClick={() => setModal(true)}
              css={{
                padding: 0,
                textDecoration: "underline",
                fontSize: 16,
                color: theme.colors.white,
              }}
            >
              Click here to understand ratings
            </Button>
          </p>
        </div>

        <div
          css={{
            marginBottom: 40,
            padding: 10,
            //boxShadow: '1px -1px 2px 2px rgba(255,255,255,0.1)',
            backgroundColor: theme.colors.blackblueop,
          }}
        >
          <QuestionsGroup questions={questions} setResults={setResults} />
        </div>

        <PrimaryButton
          disabled={Object.keys(results).length < questions.length}
          onClick={assess}
          css={{ width: "100%" }}
        >
          Submit Survey
        </PrimaryButton>
      </Container>

      <Modal isOpen={modal}>
        <ModalHead>
          <div css={{ display: "flex", alignItems: "center" }}>
            <PrimaryRoundButton onClick={() => setModal(false)}>
              <span>
                <BsTriangleFill
                  css={{
                    transform:
                      "rotate(-90deg) translateY(-2px) translateX(2px)",
                  }}
                />
              </span>
            </PrimaryRoundButton>

            <p
              css={{
                marginBottom: 0,
                marginLeft: 20,
                fontSize: 18,
              }}
            >
              Back to assessment
            </p>
          </div>
        </ModalHead>

        <ModalBody>
          <ul
            css={{
              padding: 0,
              margin: 0,
              listStyleType: "none",
              color: theme.colors.white,
              li: { marginBottom: 15 },
            }}
          >
            <li>1 star: Not demonstrated or not relevant.</li>

            <li>
              2 stars: Understood at a basic level and applied to an
              inconsistent degree, resulting in variable competence.
            </li>

            <li>
              3 stars: Well understood and applied to a reasonably consistent
              and competent level, resulting in medium competence.
            </li>

            <li>
              4 stars: Thoroughly understood and applied to a highly consistent
              level, resulting in high competence.
            </li>

            <li>
              5 stars: Holistically understood including all nuances, and
              applied to a role model standard resulting in benchmark
              competence.
            </li>
          </ul>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Questions;
