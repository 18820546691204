import { useEffect, useState } from "react";
import { apiFetch } from "./apiFetch";

export default function useFetchWithEffect(url, method, requestData, baseUrl) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    async function init() {
      let result = await apiFetch({ baseUrl, url, method, data: requestData });
      setData(result.data);
      setError(result.error);
      setLoading(result.loading);
    }
    init();
  }, [baseUrl, requestData, method, url]);

  return { data, error, loading };
}
