import React from "react";
import { Route, useHistory } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Spinner } from "components/Spinner";
import useUserData from "../auth/useUserData";
const ProtectedRoute = ({ component, ...args }) => (
  <Route component={useAuthorize(component)} {...args} />
);

function useAuthorize(component) {
  const history = useHistory();
  const data = useUserData(history.location.pathname);

  if (!data.routeAllowed) {
    if (data.navigateTo !== "") {
      if (data.navigateTo === "auth") {
        return withAuthenticationRequired(component, {
          onRedirecting: () => <Spinner />,
        });
      } else {
        history.push(data.navigateTo);
      }
    }
  } else {
    return component;
  }
}

export default ProtectedRoute;
