/** @jsxImportSource @emotion/react */
import ReactTooltip from "react-tooltip";
import { FaTrash } from "react-icons/fa";

import { Checkbox } from "components/learning-library/Filter";

function ListUsers({ currentFoundUsers, handleUpdateUser, deleteUser }) {
  return (
    <div
      css={{
        margin: "8px auto",
        width: "95%",
      }}
    >
      {currentFoundUsers && currentFoundUsers.length > 0 ? (
        currentFoundUsers.map((user) => (
          <li
            key={user.id}
            css={{
              listStyle: "none",
              margin: "4px 0",
              padding: "4px 0",
              display: "flex",
            }}
          >
            <div
              css={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "self-start",
                minWidth: "500px",
                width: "500px",
              }}
            >
              <Checkbox>
                <input
                  type="checkbox"
                  name="all-competency"
                  checked={user.checked}
                  onChange={() => {
                    user.checked = !user.checked;
                    handleUpdateUser(user);
                  }}
                  css={{
                    marginRight: "16px",
                    cursor: "pointer",
                  }}
                />
                <span
                  css={{
                    minWidth: "152px",
                    maxWidth: "152px",
                    cursor: "pointer",
                  }}
                >
                  {user.firstname} {user.lastname}
                </span>
                <span
                  css={{
                    margin: "0 4px",
                    cursor: "pointer",
                  }}
                >
                  {user.email}
                </span>
              </Checkbox>
              <span
                data-tip={"Delete User"}
                css={{
                  cursor: "pointer",
                }}
                onClick={() => deleteUser(user)}
              >
                <FaTrash size={12} />
                <ReactTooltip delayShow={100} multiline={true} />
              </span>
            </div>
          </li>
        ))
      ) : (
        <h1>No results found!</h1>
      )}
    </div>
  );
}

export default ListUsers;
