export const modalQuestions=[
    {
        question: "Question 1 - What are the most important elements of the brilliant basics?",
        options: [
            'Titles, tagging and pack shots',
            'Video',
            'Multimedia content'
        ],
        questionName:'q1',
        correctAnswerIdx:0,
        questionId: 1
    },
    {
        question: "Question 2 - Who is responsible for making brilliant basics a success?",
        options: [
            'Marketing',
            'Category',
            'Sales',
            'Everyone'
        ],
        questionName:'q2',
        correctAnswerIdx:3,
        questionId: 2
    }
];
