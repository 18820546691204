/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { BsTriangleFill } from "react-icons/bs";

import { useTheme } from "@emotion/react";

import { Modal, ModalHead, ModalBody } from "components/Modal";
import { PrimaryRoundButton, PrimaryButton, SecondaryButton } from "Theme";

function CreateUsersModal({
  isOpen,
  setIsOpen,
  handleCreateUsers,
  createUsersModalError,
}) {
  const { colors } = useTheme();
  const [inputValue, setInputValue] = useState(null);

  return (
    <Modal isOpen={isOpen}>
      <ModalHead>
        <div css={{ display: "flex", alignItems: "center" }}>
          <PrimaryRoundButton onClick={() => setIsOpen(!isOpen)}>
            <span>
              <BsTriangleFill
                css={{
                  transform: "rotate(-90deg) translateY(-2px) translateX(2px)",
                }}
              />
            </span>
          </PrimaryRoundButton>

          <p
            css={{
              marginBottom: 0,
              marginLeft: 20,
              fontSize: 18,
            }}
          >
            Back to users
          </p>
        </div>
      </ModalHead>

      <ModalBody>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            padding: 0,
            margin: 0,
            color: colors.white,
          }}
        >
          <div
            css={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginBottom: "16px",
            }}
          >
            <p
              css={{
                margin: 0,
              }}
            >
              <label>Create Users</label>
            </p>
            <textarea
              id="create-user"
              name="create-user"
              rows="500"
              cols="50"
              placeholder="Please enter new users"
              value={inputValue?.toString()}
              onChange={(event) => {
                setInputValue(event.target.value);
              }}
              css={{
                height: "200px",
                maxHeight: "500px",
                padding: "8px 16px",
                resize: "none",
              }}
            ></textarea>
            {createUsersModalError && (
              <p
                css={{
                  margin: "4px 0",
                  fontSize: "12px",
                  color: colors.red,
                }}
              >
                {createUsersModalError}
              </p>
            )}
            <p
              css={{
                margin: "4px 0",
                fontSize: "12px",
              }}
            >
              * Please enter new users in the following format:
            </p>
            <p
              css={{
                whiteSpace: "pre-wrap",
                fontSize: "12px",
              }}
            >
              email, firstname, lastname, password
            </p>
          </div>
          <div>
            <SecondaryButton
              onClick={() => setIsOpen(!isOpen)}
              css={{
                marginLeft: "8px",
              }}
            >
              Cancel
            </SecondaryButton>

            <PrimaryButton
              disabled={!inputValue}
              onClick={() => handleCreateUsers(inputValue)}
              css={{
                marginLeft: "8px",
              }}
            >
              Create
            </PrimaryButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default CreateUsersModal;
